import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";
import PropTypes from "prop-types";
import { queryRestoreLawArea } from "../../views/law_area/utils";


LawAreaTable.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.object).isRequired,
  page_size: PropTypes.number.isRequired,
  page_index: PropTypes.number.isRequired,
  type : PropTypes.string,
};


function LawAreaTable(props) {
  const navigate = useNavigate();
  const { type } = props;
  const { areas } = props;
  const { page_size,page_index } = props;
  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {areas.map(({ id, UUid, area_name}, index) => {
            const goToLawArea = ()=>{navigate(`/law_areas/${UUid}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={id}>
                <td>{id }</td>
                <td>{area_name}</td>
                <td>
                  {type === "Restore"  ?
                    <Button fullWidth onClick={()=>{queryRestoreLawArea(id); navigate(-1);}}>Restaurar Area</Button> :
                    <Button fullWidth onClick={goToLawArea}>Ver</Button>
                  }
                </td>
              </tr>
            ) ;})}
        </tbody>
      </Table>
    </div>
  );
}

export default LawAreaTable;