import React, { useState, useEffect } from "react"
import fetchData from "../../utils";
import { NotificationTable } from "../../components/notification/notificationTable";
import { LoadingView } from "../LoadingView";


export function Notifications(props) {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setNotificationAmount, setNotificationNavList } = props;

  useEffect(() => {
    fetchData("/notification/get-notifications/")
    .then((json) => {
      setNotificationList(json["data"]["notifications"]);
      setIsLoading(false);
    })
  }, []);

  return (
    <div className="user-show">
      <h1>Notificaciones</h1>
      {isLoading ? 
        <LoadingView /> 
        : 
        <NotificationTable notificationList={notificationList} setNotificationAmount={setNotificationAmount}
        setNotificationNavList={setNotificationNavList}/>
      }
      
    </div>
  )
};