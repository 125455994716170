import React, { useState, useEffect } from "react"
import { LoadingView } from "../LoadingView";
import { useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import fetchData from "../../utils"

export function Validate() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const isLogged = localStorage.getItem("token") !== null?? false;
  const { token } = useParams();


  useEffect(() => {
    const validate = async () => {
      fetchData(`/user/validate_email/${token}`, "GET")
      .then(() => {
        setStatus(true);
        setLoading(false);
      })
      .catch((error) => {
        setStatus(false);
        setLoading(false);
        setMessage(error.message);
      });
      
    };
    validate();
  }, [token]);

  if (!loading) {
    return (
      <div className="user-show">
        <h1>Validación de correo</h1>
        {status ? (
        <>
          <h2>Tu correo fue validado correctamente!</h2>
          <p>Inicia sesión para usar la aplicación</p>
          {!isLogged && (
          <Button variant="primary" onClick={() => {window.location.href = "/login"}}>
            Iniciar sesión
          </Button>
          )}
        </>
        ) : (
        <>
          <h2>Correo no validado</h2>
          <p>Detalle: {message}</p>
        </>)}
      </div>
    )
  }
  return (
    <div>
      <LoadingView/>
    </div>
  )
};