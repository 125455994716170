import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";
import { queryRestoreService } from "../../views/service/utils";

function ServiceTable(props) {
  const navigate = useNavigate();

  const { services } = props;
  const { type } = props;
  const { page_size,page_index } = props;
    

  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th className="th-lg-percent">Industria/Rubro</th>
            <th className="th-lg-percent">Área</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {services.map(({ id, UUid, service_name, area ,industry }, index) => {
            const goToService = ()=>{navigate(`/services/${UUid}`);};
            const show3Industries  = industry?.filter((_, industry_index)=> industry_index < 3 ? true: false);
            const show2LawAreas  = area?.filter((_, industry_index)=> industry_index < 2 ? true: false);
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={index+1}>
                <td>{index+1}</td>
                <td>{service_name}</td>
                <td>
                  {show3Industries?.map(({name})=>{return name;}).join(", ")}
                  {industry? industry.length>3? "...": ".": ""}
                </td>
                <td>
                  {show2LawAreas?.map(({area_name})=>{return area_name;}).join(", ")}
                  {area? area.length>2? "...": ".": ""}
                </td>
                <td>
                  {type == "Restore"?
                    <Button fullWidth onClick={()=>{queryRestoreService(id); navigate(-1);}}>Restaurar Servicio</Button> :
                    <Button fullWidth onClick={goToService}>Ver</Button>
                  }
                </td>
              </tr>
            ) ;})}
        </tbody>
      </Table>
    </div>
  );
}

export default ServiceTable;