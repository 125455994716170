import fetchData from "../../utils";

export async function queryAllLawAreas(){
  const url = "/project/queryAllLawAreas";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);

}

export async function queryAllIndustries(){
  const url = "/project/queryAllIndustries";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}


export async function queryAllLegacyServices(){
  const url = "/project/queryAllLegacyServices";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryCreateService(serviceName, serviceDescription, currentLawAreas, currentIndustries){
  const url = "/project/queryCreateService";
  const method= "POST";
  const body = {
    "serviceName": serviceName?? "",
    "serviceDescription": serviceDescription?? "",
    "currentLawAreas": currentLawAreas?? [],
    "currentIndustries": currentIndustries?? [],
  };
  return await fetchData(url,method,body);
}

export async function queryUpdateService(serviceID, serviceName, serviceDescription, currentLawAreas, currentIndustries){
  const url = "/project/queryUpdateService";
  const method= "POST";
  const body = {
    "serviceID" : serviceID,
    "serviceName": serviceName?? "",
    "serviceDescription": serviceDescription??"",
    "currentLawAreas": currentLawAreas?? [],
    "currentIndustries": currentIndustries?? [],
  };
  return await fetchData(url,method,body);
}


export async function getServiceByID(id){
  const url = `/project/services/${id}`;
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function getIndustriesByServiceID(id){
  const url = `/project/getIndustriesByServiceID/${id}`;
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function getLawAreasByServiceID(id){
  const url = `/project/getLawAreasByServiceID/${id}`;
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryAllPendingServices(){
  const url = "/project/queryAllPendingServices";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}


export async function getPendingServiceByID(id){
  const url = `/project/getPendingServiceByID/${id}`;
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryAllServices() {
  const url = "/project/queryAllServices";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryAllErasedServices() {
  const url = "/project/queryAllErasedServices";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function getPreviousJobExperienceByPendingServiceID(pendingServiceID) {
  const url = `/project/queryGetPreviousJobExperienceByPendingServiceID/${pendingServiceID}`;
  const method ="GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryAcceptService(currentServiceSelected,pendingServiceID) {
  const url = "/project/queryAcceptService";
  const method ="POST";
  const body = {
    "currentServiceSelected":currentServiceSelected,
    "pendingServiceID":pendingServiceID,
  };
  return await fetchData(url,method,body);
}

export async function queryAllServicesWithAreaAndIndustry() {
  const url = "/project/queryAllServicesWithAreaAndIndustry";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryDeleteService(id){
  const url = "/project/queryDeleteService";
  const method ="POST";
  const body = {"serviceID" : id};
  return await fetchData(url,method,body);
}

export async function queryRestoreService(id){
  const url = "/project/queryRestoreService";
  const method ="POST";
  const body = {"serviceID" : id};
  return await fetchData(url,method,body);
}

export async function queryFilterServicesByAreaAndIndustry(lawAreaIDs, industryIDs, logicMode){
  const url = "/project/queryFilterServicesByAreaAndIndustry";
  const method ="POST";
  const body = {
    "lawAreaIDs" : lawAreaIDs,
    "industryIDs": industryIDs,
    "logicMode": logicMode,
  };
  return await fetchData(url,method,body);
}