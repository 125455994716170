import StarRating from "../starRating";
import Box from "@mui/material/Box";
import { CloseButton } from "../Buttons";
import { GenericalModal } from "../../views/kam/utilsComponent";
import { Grid } from "@mui/material";


const labels = {
  0.5: "Malo",
  1: "Malo",
  1.5: "Deficiente",
  2: "Deficiente",
  2.5: "Deficiente",
  3: "Aceptable",
  3.5: "Aceptable",
  4: "Aceptable",
  4.5: "Bueno",
  5: "Excelente",
};

const LABELSRATINGJOB = {
  speed: "rapidez",
  legal_quality: "su calidad jurídica en el servicio",
  client_communication: "su comunicación con el cliente",
  kam_communication: "su comunicación con el KAM"};

export default function JobInfoModal(props) {
  const { show, jobInfo, setShow, isKam } = props;
  return (
    <GenericalModal
      show={show}
      title={jobInfo.project_name}
      body={
        <>
          <div className="modal-spacing">
            <h5>
              Sobre el proyecto:
            </h5>
            <div><b>Cliente: </b>{jobInfo.client_name}</div>
            <div><b>Descripción del proyecto: </b> {jobInfo.project_description}</div>
          </div>
          <div className="modal-spacing">
            <h5>
              Sobre el trabajo:
            </h5>
            <div><b>Servicio prestado: </b>{jobInfo.service}</div>
            <div><b>Descripcion del servicio: </b>{jobInfo.description}</div>
            <div><b>Valor del servicio (UF): </b>{jobInfo.cost ?? 0}</div>
            <div><b>Fecha estimada de inicio del trabajo: </b>{jobInfo.start_date?.split("-").reverse().join("-") ?? "No definido"}</div>
            <div><b>Fecha estimada del entregable: </b>{jobInfo.end_date?.split("-").reverse().join("-") ?? "No definido"}</div>
            {isKam && jobInfo.evaluation_id && (
              <div>
                <hr/>
                <p>
                  Evaluación general del abogado
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <StarRating stars={jobInfo.rating}/>
                  </Grid>
                  <Grid item xs={7} sx={{alignItems: "center"}}>
                    <Box sx={{ ml: 2 }}>Estrella{jobInfo.rating === 1 ? "" : "s"}: {jobInfo.rating} {labels[jobInfo.rating]}</Box>
                  </Grid>
                </Grid>
                {jobInfo.evaluation && (
                  Object.entries(LABELSRATINGJOB).map(([key, label], index) => 
                  (
                    <div key={index}>
                      <hr/>
                      <p>
                        Evaluación del abogado en {label}:
                      </p>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <StarRating stars={jobInfo.evaluation[key]}/>
                        </Grid>
                        <Grid item xs={7} sx={{alignItems: "center"}}>
                          <Box sx={{ ml: 2 }}>Estrella{jobInfo.evaluation[key] === 1 ? "" : "s"}: {jobInfo.evaluation[key]} {labels[jobInfo.evaluation[key]]}</Box>
                        </Grid>
                      </Grid>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </>}
      footer={
        <CloseButton onClick={() => setShow(false)}>Cerrar</CloseButton>
      }
    />
  );
}