import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../styles/userTable.scss";
import "../../styles/userTable.scss";
import { Button } from "@mui/material";
import { SuccessButton, RejectButton, CloseButton } from "../../components/Buttons";
import { GenericalModal } from "../kam/utilsComponent";
import { Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";


export function LawyerTable(props) {
  const { onShowMoreInfo } = props;
  const filteredJobs = props.filteredJobs.filter(Boolean);
  const [amount, setAmount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  useEffect(() => {
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
    
  }, [amount, pageSize, pageIndex]);
  useEffect(() => {
    setAmount(filteredJobs.length);
  }, [filteredJobs.length]);
  return (
    <div className='user-table'>
      <Grid container>
          <Grid item  xs={10} md={4}>
            <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
          </Grid>
          <Grid item  xs={8} md={4}>
            Página
              <CustomPagination
                pageIndex={pageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={setPageIndex}
              />  
          </Grid>
        </Grid>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre del proyecto</th>
            <th>Servicio Solicitado</th>
            <th>Descripción del trabajo</th>
            <th>Valor del trabajo (UF)</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.map(({ project_name, originalIndex, status, color, service, description, cost, acceptance_status }, index) => {
          return pageIndex * pageSize <= index && index < (pageIndex + 1) * pageSize && (
            <tr key={originalIndex} bgcolor={color}>
              <td>{index + 1}</td>
              <td>{project_name} </td>
              <td>{service} </td>
              <td>{description.length <= 50 ? description : description.substring(0, 50) + "..."} </td>
              <td>{cost} </td>
              <td>{acceptance_status ?? status}</td>
              <td>
                <Button
                  variant="contained"
                  onClick={onShowMoreInfo}
                  value={originalIndex}>
                  Ver mas
                </Button>
              </td>
            </tr>
          )})
          }
        </tbody>
      </Table>
      <Grid container>
          <Grid item  xs={10} md={4}>
            Página
              <CustomPagination
                pageIndex={pageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={setPageIndex}
              />  
          </Grid>
          <Grid item  xs={8} md={4}>
          </Grid>
        </Grid>
      <p>
        Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
      </p>
    </div>
  );
}

export function JobInfoModal(props) {
  const { show, jobInfo, rejectInfo, handleAcceptProposal, handleConfirmRejection, handleCloseJobModal } = props;
  return (
    <GenericalModal
      show={show}
      title={jobInfo.project_name}
      body={
        <>
          <div className="modal-spacing">
            <h5>
              Sobre el proyecto:
            </h5>
            <div><b>Cliente: </b>{jobInfo.client_name}</div>
            <div><b>Descripción del proyecto: </b> {jobInfo.project_description}</div>
          </div>
          <div className="modal-spacing">
            <h5>
              Sobre el trabajo:
            </h5>
            <div><b>Servicio prestado: </b>{jobInfo.service}</div>
            <div><b>Descripcion del servicio: </b>{jobInfo.description}</div>
            <div><b>Valor del servicio (UF): </b>{jobInfo.cost ?? 0}</div>
            <div><b>Horas laborales totales: </b>{jobInfo.workload ?? "No definido"}</div>
            <div><b>Fecha estimada de inicio del trabajo: </b>{jobInfo.start_date?.split("-").reverse().join("-") ?? "No definido"}</div>
            <div><b>Fecha estimada del entregable: </b>{jobInfo.end_date?.split("-").reverse().join("-") ?? "No definido"}</div>
          </div>
          <div className="modal-spacing">
            {
              jobInfo.accepted === false ? (
                <>
                  <h5>Motivos del rechazo:</h5>
                  {
                    rejectInfo.length > 0 ? (
                      <ul>
                        {rejectInfo.map((reason, index) =>
                          <li key={index}>
                            {reason}
                          </li>
                        )}
                      </ul>) : <>No especificado</>
                  }
                </>
              ) : <></>
            }
          </div>
        </>}
      footer={<>
        {(jobInfo?.accepted === null && jobInfo.isThisLawyerSelected === null && jobInfo.isThisOfferExpired === false)&& (<>
          <SuccessButton value={jobInfo.id} onClick={handleAcceptProposal} >Aceptar propuesta</SuccessButton>
          <RejectButton value={jobInfo.id} onClick={handleConfirmRejection}>Rechazar propuesta</RejectButton>
        </>)}
        <CloseButton onClick={handleCloseJobModal}>Cerrar</CloseButton>
      </>}
    />
  );
}