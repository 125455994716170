import { useState, useEffect } from "react";
import IndustryTable from "../../components/industry/IndustryTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { GoBackButton, SuccessButton } from "../../components/Buttons";
import {  Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination.jsx";
import { Form } from "react-bootstrap";
import { queryAllIndustries } from "../service/utils";

export function IndustryList() {
  const [industries, setIndustries] = useState([]);
  const [industriesFillter, setIndustriesFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    queryAllIndustries()
      .then((json) => {
        setIndustries(json["data"]);
        setIndustriesFillter(json["data"]);
        setAmount(json["data"].length);
        setIsLoading(false);
      });
  }, []);

  const goToCreateLawArea = () =>{ 
    let path = "/industries/create"; 
    navigate(path);
  };

  const goToRestoreLawArea = () =>{
    let path = "/industries/restore"; 
    navigate(path);
  };
  
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    var lawAreasFiltter = industries.filter(({ name, id }) => {
      return name.toLowerCase().includes(search.toLowerCase()) ||
      id.toString().includes(search.toLowerCase());
    });
    setIndustriesFillter(lawAreasFiltter);
    setAmount(lawAreasFiltter.length);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);
  
  return (
    <div className="user-show-extended">

      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>        
        <Grid item xs={12} md={3}>
          <SuccessButton fullWidth onClick={goToCreateLawArea}> Crear Industria</SuccessButton>
          <SuccessButton fullWidth onClick={goToRestoreLawArea}> Restaurar Industria</SuccessButton>
        </Grid>
 
      </Grid>
      <h1>Industrias</h1>

      {isLoading? (<LoadingView/>): 
        <>
          <IndustryTable industries={industriesFillter} page_size={pageSize} page_index={pageIndex} type={"Index"}/>
          Mostrando {pageIndex * pageSize+ 1} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
  );
}
