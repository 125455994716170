import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";

function UserTable(props) {
  const { users, userType } = props;
  const { page_size,page_index } = props;

  const navigate = useNavigate();
  return (
    <div className='user-table'>
      <Table striped bordered hover>
        <thead  align='center'>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Nombre de usuario</th>
            <th>Correo</th>
            <th>{userType === "Persona Jurídica" ? "Empresa" : userType === "Persona Natural" ? "Representado" : "Empresa/Representado"}</th>
            <th>Ver perfil del Contacto</th>
          </tr>
        </thead>
        <tbody>
          {users.map(({ user, company, id, type, natural_person}, index) => {
            const goToUser = ()=>{navigate(`/users/${user.UUid}`);};
            const goToCompany = ()=>{
              if (company.UUid){
                navigate(`/company/${company.UUid}`);
              }};
            const goToNaturalPerson = ()=>{
              if( natural_person.UUid){
                navigate(`/natural_person/${natural_person.UUid}`);
              }};
            const labelBotton = type.name === "Persona Jurídica"? company.name: natural_person.first_name + " " + natural_person.last_name
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={id}>
                <td>{id}</td>
                <td>{user.auth.first_name}</td>
                <td>{user.auth.last_name}</td>
                <td>{user.auth.username}</td>
                <td>{user.auth.email === "" ? "Sin información" : user.auth.email}</td>
                <td>
                  <Button fullWidth onClick={type.name === "Persona Jurídica"? goToCompany: goToNaturalPerson }>
                    {labelBotton.trim() === "" ? "N/A" : labelBotton}
                  </Button>
                </td>
                <td><Button fullWidth onClick={goToUser}>Ver perfil</Button></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default UserTable;