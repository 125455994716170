import React, {useEffect, useState} from "react";
import { DiagnosticClientSearch } from "../../components/kam/DiagnosticUserSearch";
import { DiagnosticClientInfo } from "../../components/kam/DiagnosticClientInfo";
import { NewDiagnostic } from "../../components/kam/NewDiagnostic";
import { DiagnosticIndex } from "../../components/kam/DiagnosticIndex";
import FetchData from "../../utils";
import Notification from "../../components/Notification";

export function Diagnostic() {
  const [clients, setClients] = useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const [diagnostics, setDiagnostics] = useState([]);
  const [currentKam, setCurrentKam] = useState(null);
  const [notification, raiseAlert] = Notification("success", "");

  useEffect(() => {
    FetchData("/project/queryAllClients/", "GET").then((response) => {
      setClients(response["data"]);
      setCurrentKam(response["current_user"]);
    })
  }, []);

  return (
    <div>
      <DiagnosticClientSearch 
      clients={clients}
      setCurrentClient={setCurrentClient}
      setDiagnostics={setDiagnostics} />
      {currentClient ? 
      <>
        <DiagnosticClientInfo client={currentClient}/>
        <NewDiagnostic client={currentClient} currentKam={currentKam}
        raiseAlert={raiseAlert}
        setDiagnostics={setDiagnostics} />
        <DiagnosticIndex diagnostics={diagnostics} />
      </>
    : <h1>Seleccione algún cliente</h1>}
      {notification}
   </div>
  )
}