import React, { useState, useEffect } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import { Button } from "react-bootstrap";
import "../../styles/login.scss"
import fetchData from "../../utils";
import { Form } from "react-bootstrap";
import { LoadingView } from "../LoadingView";


export function RecoveryPassword(props) {

  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [haveLetter, setHaveLetter] = useState(false);
  const [have10Letters, set10Letters] = useState(false);
  const [haveNumber, setHaveNumber] = useState(false);
  const [match, setMatch] = useState(false);
  const [haveCapitalLetter, setHaveCapitalLetter] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [specialCaracter, setSpecialCaracter] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [notification, raiseAlert] = Notification("success", "");

  useEffect(() => {
    fetchData(`/user/verify_token/${token}`, "GET")
    .then((response) => {
      raiseAlert(response);
      setIsLoading(false);
    })
    .catch((error) => {
      navigate("/login");
    });
  }, [token]);

  if (window.localStorage.getItem("token")) {
    return <Navigate to="/home" />; 
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    fetchData(`/user/change_password/${token}/`, "PUT", {password: password, confirm_password: confirmPassword, old_password: "1"})
    .then((response) => {
      response["severity"] = "success";
      raiseAlert(response);
      setPassword("");
      setConfirmPassword("");
    }) 
    .catch((error) => {
      raiseAlert(error);
    });
  };

  const onChangePassword = async(e) => {
    e.preventDefault();
    const { value } = e.target;
    setPassword(value);

    const length = value.length >= 10;
    set10Letters(length);

    const number = value.match(/[0-9]/i);
    setHaveNumber(number);

    const letter = value.match(/[a-z]/i);
    setHaveLetter(letter);

    const capitalLetter = value.match(/[A-Z]/);
    setHaveCapitalLetter(capitalLetter);

    const isConfirmPassword = !(value.length === 0 || confirmPassword.length === 0)
    setConfirm(isConfirmPassword);

    const specialCaracterNew = value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
    setSpecialCaracter(specialCaracterNew);

    const matchLocal = value === confirmPassword && value.length > 0;
    setMatch(matchLocal);

    setIsPasswordValid(letter && length && number && capitalLetter && matchLocal && confirmPassword  && specialCaracterNew);

  };
  const onChangeConfirmPassword = async(e) => {
    e.preventDefault();
    const { value } = e.target;
    setConfirmPassword(value);

    const matchLocal = value === password && value.length > 0;
    setMatch(matchLocal);

    const confirmLocal = !(value.length === 0 || password.length === 0);
    setConfirm(confirmLocal);

    setIsPasswordValid(haveLetter && have10Letters && haveNumber && haveCapitalLetter && matchLocal && confirmLocal  && specialCaracter);

  };
  if (isLoading) return (<div className="big-card"><LoadingView/></div>);
  return (
    <div className="big-card">
    <h1>Recupera tu contraseña</h1>
      <Form onSubmit={onSubmitHandler}>
        <div className="flex-register">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Ingrese su contraseña nueva</Form.Label>
            <Form.Control type="password" placeholder="Contraseña" onChange={onChangePassword}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Confirmar contraseña nueva</Form.Label>
            <Form.Control type="password" placeholder="Confirmar contraseña" onChange={onChangeConfirmPassword}/>
          </Form.Group>
        </div>
          <p><b>La contraseña debe cumplir los siguientes requerimientos:</b></p>
          <ul className={"list-password"}>
            <li id="letter" className={haveLetter ? "text-success": "text-danger"}>Al menos <strong>una letra</strong></li>
            <li id="capital" className={haveCapitalLetter ? "text-success": "text-danger"}>Al menos <strong>una letra mayúscula</strong></li>
            <li id="number" className={haveNumber ? "text-success": "text-danger"}>Al menos <strong>un número</strong></li>
            <li id="length" className={have10Letters ? "text-success": "text-danger"}>Al menos <strong>10 carácteres</strong></li>
            <li id="match" className={specialCaracter? "text-success" : "text-danger"}>Al menos <strong>un caracter especial</strong></li>
            <small className={specialCaracter? "text-success" : "text-danger"}>{"!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"}</small>
            <li id="null" className={confirm? "text-success" : "text-danger"}>Debe <strong>confirmar la contraseña</strong></li>
            <li id="match" className={match? "text-success" : "text-danger"}>Las contraseñas <strong>deben coincidir</strong></li>
          </ul>
        <Button variant="success" type="submit" disabled={!isPasswordValid}>
          Cambiar contraseña
        </Button>
    </Form>
    {notification}
  </div>
  )
}