import { useState, useEffect,  } from "react";
import UserTable from "../../components/users/userTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import React from "react";
import { LoadingView } from "../LoadingView";
import { Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import { Form } from "react-bootstrap";
import { GoBackButton } from "../../components/Buttons";
import fetchData from "../../utils";
import { Validate } from "../../utils";

export function UserList() {
  
  // const [ButtonPopUp, setButtonPopUp] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersFillter, setUsersFillter] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  
  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);



  useEffect(() => {
    fetchData(`/user/users/`, "GET")
      .then((json) => {
        setUsers(json["data"]);
        setUsersFillter(json["data"]);
        setIsLoading(false);
        setAmount(json.data.length);
      });
  }, [navigate]);

  
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = Validate.accent_fold(e.target.value);
    setSearch(search);
    var usersFiltter = users.filter((user) => {
      const fixUsername = Validate.accent_fold(user.auth.username);
      const fixFistName = Validate.accent_fold(user.auth.first_name);
      const fixLastName = Validate.accent_fold(user.auth.last_name);
      return fixUsername.toLowerCase().includes(search.toLowerCase()) ||
      fixFistName.toLowerCase().includes(search.toLowerCase()) ||
      fixLastName.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.email.toLowerCase().includes(search.toLowerCase()) ||
      user.role.toLowerCase().includes(search.toLowerCase());
    });
    setUsersFillter(usersFiltter);
    setAmount(usersFiltter.length);
    
  };
  return (
    
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>

      </Grid>
      
      <h1>Usuarios</h1>
      {isLoading ? 
        (<LoadingView/>)
        : 
        <>
          <UserTable users={usersFillter} page_size={pageSize} page_index={pageIndex} />
          <p>
          Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
          </p>
        </>
      }
      <GoBackButton/>
    </div>
  );
}