import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import fetchData from "../../utils";
import { FormHelperText } from "@mui/material";

const MAX_SUBJECT_LENGTH = 100

export function NewDiagnostic(props) {
  const { client, currentKam, raiseAlert } = props;
  const [subjectState, setSubjectState] = useState("");

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const { value } = event.target.formBasicSubject;
    const body = {
      "data": {
        "subject": value,
        "client_id": client.id,
        "kam_id": currentKam.id,
      }
    }
    fetchData("/diagnostic/create/", "POST", body)
    .then((response) => {
      raiseAlert(response);
      setSubjectState("");
      return response["data"];
    })
    .then((data) => {
      props.setDiagnostics((prevState) => {
        return [...prevState, data];
      });
    })
    .catch((error) => {
      raiseAlert(error);
    });
  };

  const handleSubjectChange = (e) => {
    e.preventDefault();
    let newDescriptionState = e.target.value;
    setSubjectState(newDescriptionState);
  }

  return (
    <div>
      <div className="user-show">
        <h1>Crear nuevo diagnóstico</h1>
        <Form onSubmit={handleOnSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Cliente asociado: {client.user.auth.first_name} {client.user.auth.last_name}</Form.Label>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSubject">
            <Form.Label>Tema principal de la Comunicación/Reunión</Form.Label>
            <Form.Control 
              required
              type="text"
              placeholder="Tema" 
              onChange = {handleSubjectChange}
              value={subjectState}
              isInvalid={subjectState.length > MAX_SUBJECT_LENGTH}
            />
            <FormHelperText  error={subjectState.length > MAX_SUBJECT_LENGTH}>{subjectState.length}/{MAX_SUBJECT_LENGTH}</FormHelperText>
          </Form.Group>
          
          <Button variant="primary" type="submit" disabled={subjectState.length > MAX_SUBJECT_LENGTH}>
            Crear
          </Button>
        </Form>
      </div>
    </div>
  )
}