import React, { useState } from 'react';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import "../../styles/kamMeeting.scss";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Meetings(props) {
  const { meeting, index } = props;
  const [collapsed, setCollapsed] = useState(false);
  const date = new Date(meeting.date);
  const lawyers = meeting.lawyers.map(({user}) => user.auth.first_name + " " + user.auth.last_name);
  const kams = meeting.key_accounts_managers.map(({user}) => user.auth.first_name + " " + user.auth.last_name);
  const formatter = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' });
  return (
    <div>
      <Item key={index} >
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
      
          <Grid container> 
            <Grid item xs={12} md={8}>
              {<h3>{index +1}: {meeting.title}</h3>}
            </Grid>
            <Grid item xs={3} md={2}>
            </Grid>
            <Grid item xs={3} md={1}> 

            </Grid>
          
            <Grid item xs={3} md={1}>
              <Button onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? (
                  <ExpandLessIcon></ExpandLessIcon>
                ) : (
                  <ExpandMoreIcon></ExpandMoreIcon>
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Collapse in={collapsed} timeout="auto">
          <div className="meeting-detail">
            <p><b>Fecha:</b> {date.getDate()}/{date.getMonth()+1}/{date.getFullYear()}</p>

            <p><b>Descripción:</b></p>
            <p className={meeting.description.split(" ").length < 200 ? "small-paragraph" : "paragraph"}>{meeting.description}</p>

            <p><b>Posible solución:</b></p>
            <p className={meeting.possible_solution.split(" ").length < 200 ? "small-paragraph" : "paragraph"}>{meeting.possible_solution}</p>

            <p><b>Abogados asistentes:</b> {lawyers.length > 0 ? formatter.format(lawyers) : "N/A"}</p>

            <p><b>Kams asistentes:</b> {lawyers.length > 0 ? formatter.format(kams) : "N/A"}</p>
          </div>
        </Collapse>
      </Item>
    </div>
  );
}