import * as React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form } from "react-bootstrap";
import "../../styles/userShow.scss";
import { GenericalModal } from "../../views/kam/utilsComponent";
import { LoadingView } from "../../views/LoadingView";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {HtmlInfoTooltip} from "../Tooltips";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import Notification from "../Notification";
import { queryDeletePreviousExperience, queryCreatePreviousExperience, queryPreviousExperienceByLawyerID, queryUpdatePreviousExperience, queryGetLawyerIDByUserID,getUserByUsername, interpolateColor } from "./utils";
import { Grid, Typography } from "@mui/material";
import { queryCreatePendingService } from "../../views/lawyer/utils";
import { CloseButton, RejectButton } from "../Buttons";
import { lawyerData } from "../../dataClass";
import { useNavigate, Link } from "react-router-dom";
import "../../styles/tooltipLawyer.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LawyerExperienceForm } from "./descriptionPreviosJob"
import "../../styles/previousExperience.scss";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, elementName, theme, elementColor) {
  return {
    fontWeight:
      elementName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getStyles2(elementColor) {
  return {
    backgroundColor: elementColor,
  };
}

export function MultiSelect(props) {
  const selectCategory = props.category;
  const selectOptions = props.options;
  const theme = useTheme();
  const [elementName, setElementName] = React.useState([]);
  const [elementColor, setElementColor] = React.useState("#ffc107");
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setElementName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    // elementColor=="#0d6efd"?setElementColor("#ffc107"):setElementColor("#0d6efd");
  };

  return (
    <FormControl sx={{ mt: 3, width: "100%" }}>
      <InputLabel id="demo-multiple-chip-label">{selectCategory}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={elementName}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={getStyles2(elementColor)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {selectOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={getStyles(option, elementName, theme, elementColor)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function BasicStack(props) {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [confirmEraseExperience, setConfirmEraseExperience] = useState(false);
  const [actualIndex, setActualIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const {newJobObjects, setNewJobObjects} = props;
  let newJobObjects_auxiliar = newJobObjects;
  const {showEditOptions} = props;
  const user = props.user;
  const [lawyerID, setLawyerID] = useState (-1);
  const [notification, raiseAlert] = Notification("success", "");
  
  useEffect(()=>{
    queryGetLawyerIDByUserID(user.id).then((response)=>{
      setLawyerID(response.lawyerID);
    });
  },[user.id]);

  const expertiseLevelToString = {
    1: "1-10",
    2: "11-50",
    3: "51-200",
    4: "200+",
  };

  const expertiseStringToLevel = {
    "1-10": 1,
    "11-50": 2,
    "51-200": 3,
    "200+": 4,
  };
  const handleExpertiseLevel = (level)=>{
    return expertiseLevelToString[level];
  };
  const handleUpdateData =  (index) =>{
    queryUpdatePreviousExperience(
      newJobObjects[index].id,
      newJobObjects[index].quantity)
      .then((response)=>{
        raiseAlert(response);
        newJobObjects_auxiliar[index].isEditing = !newJobObjects_auxiliar[index].isEditing;
      });
  };

  function removeService(index) {
    const experienceToErase = newJobObjects_auxiliar.splice(index,1)[0];
    if (experienceToErase.id) {
      queryDeletePreviousExperience(experienceToErase.id)
      .then((response)=>{
        raiseAlert(response);
      });
    }
  }
  function collapseExperience(index) {
    newJobObjects_auxiliar = newJobObjects;
    newJobObjects_auxiliar[index].isCollapsed = !newJobObjects_auxiliar[index].isCollapsed;
    setNewJobObjects(newJobObjects_auxiliar);
    forceUpdate();
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const { jobExperienceQuantities } = e.target.elements;
    const index  = e.target.id;

    queryCreatePendingService(newJobObjects[index].pending_service.pending_service_name,
      newJobObjects[index].pending_service.pending_service_description,
      newJobObjects[index].userID)
      .then((response)=>{
        queryCreatePreviousExperience(
          newJobObjects[index].service.service_name,
          expertiseStringToLevel[jobExperienceQuantities.value],
          lawyerID,
          response.pendingServiceID)
          .then((response) =>{
            newJobObjects[e.target.id].id = response.previousJobID;
            newJobObjects[e.target.id].UUid = response.previousJobUUid;
            raiseAlert(response);
            newJobObjects_auxiliar[e.target.id].isDisabled = true;
            newJobObjects_auxiliar[e.target.id].isEditing = false;
            setNewJobObjects(newJobObjects_auxiliar);
            forceUpdate();
          })
          .catch((error)=>{
            raiseAlert(error);
          });
      })
      .catch((error)=>{
        raiseAlert(error);
      });
  };
  useEffect(()=>{
    if (lawyerID === -1){
      return ;
    }
    queryPreviousExperienceByLawyerID(lawyerID).then(
      (response) => {
        const data = response.data;
        for (let i = 0; i < data.length; i++) {
          data[i].isEditing = false;
          data[i].isCollapsed = false;
          data[i].isDisabled = true;
          for (let j = 0; j < data[i].job_descriptions.length; j++) {
            data[i].job_descriptions[j].isEditing = false;
            data[i].job_descriptions[j].isCollapsed = false;
            data[i].job_descriptions[j].firstEdit = false;
          }
        }
        setNewJobObjects(data);
        setIsLoading(false);
        newJobObjects_auxiliar = newJobObjects;
      }
    );
  },[lawyerID]);

  if (isLoading) {
    return (
      <div>
        <LoadingView />
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <GenericalModal show={confirmEraseExperience}
        title="¿Está seguro de eliminar la experiencia previa?"
        footer={(
          <>
            <RejectButton onClick={()=>{
              removeService(actualIndex);
              setConfirmEraseExperience(false);
              forceUpdate();}
            } >Eliminar </RejectButton>
            <CloseButton onClick={()=>{
              setConfirmEraseExperience(false);}
            }>Cerrar</CloseButton>
          </>
        )}
      ></GenericalModal>
      <h1 sx={{ textAlign: "center", width: "100%" }}>
          Experiencias Previas
      </h1>
      <Stack spacing={2}>
        {newJobObjects?.map((previousExperience, index) => {
          return (
            <Item key={index}>
              <Grid container> 
                <Grid item xs={12} md={10}>
                  { <h3>
                    {previousExperience.service.service_name ? previousExperience.service.service_name : previousExperience.pending_service.pending_service_name}
                    {previousExperience.service.service_name && previousExperience.pending_service.pending_service_name ? " (Homologado de '" + previousExperience.pending_service.pending_service_name + "')": ""}
                  </h3>}
                </Grid>
                <Grid item  xs={12} md={1}>


                </Grid>

                <Grid item  xs={12} md={1}>

                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button onClick={() => collapseExperience(index)}>
                      {previousExperience.isCollapsed? (
                        <ExpandLessIcon></ExpandLessIcon>
                      ) : (
                        <ExpandMoreIcon></ExpandMoreIcon>
                      )}
                    </Button>
                  </span>
                </Grid>
              </Grid>
              <Collapse in={previousExperience.isCollapsed} timeout="auto"  >
                <Form onSubmit={onSubmit} id={index}>
                  <div className="col-12">
                    <h4>
                      Cantidad de experiencias:
                      <ComboBox
                        disabled={previousExperience.isDisabled && !previousExperience.isEditing}
                        setCurrentOption={
                          (value)=>{
                            newJobObjects_auxiliar[index].quantity = expertiseStringToLevel[value];
                            setNewJobObjects(newJobObjects_auxiliar);
                            forceUpdate();
                          }}
                        value = {handleExpertiseLevel(previousExperience.quantity)}
                        id = "jobExperienceQuantities"
                        options={["1-10","11-50","51-200","200+"]}
                        category="Cantidad de experiencias"
                      ></ComboBox>
                    </h4>
                  </div>
                  {
                    showEditOptions? (<>
                      <Tooltip title="Remover" placement="top-end">
                        <span>

                          <Button
                            sx={{ color: "red", borderColor: "red",":hover": { bgcolor: "#DDCCCC", borderColor:"red",} }} 
                            disabled={previousExperience.isDisabled && !previousExperience.isEditing}
                            onClick={() => {setActualIndex(index);setConfirmEraseExperience(true);}}
                          >
                        Eliminar experiencias <DeleteIcon></DeleteIcon>
                          </Button>

                        </span>
                      </Tooltip>
                      {!previousExperience.isDisabled? (
                        <Button  type="submit"
                          variant="contained"
                          disabled={previousExperience.isDisabled}>
                    Agregar experiencias
                        </Button>
                      ) : (
                        <Button value={index} 
                          variant="contained"
                          onClick={
                            (e)=>{
                        
                              const index = e.target.value;
                              if(previousExperience.isEditing){
                                handleUpdateData(index);
                              } else {
                                newJobObjects_auxiliar[index].isEditing = !newJobObjects_auxiliar[index].isEditing;
                              }
                              
                              forceUpdate();
                            }
                          }>
                          {!previousExperience.isEditing?"Editar Experiencia previa":"Guardar Cambios"}
                        </Button>
                      )}
                    </>):<></>
                    
                  }
                  
                </Form>
                {showEditOptions && (
                <div className="add-job-previous-experience">
                  <Button variant="contained" onClick={()=>{
                    newJobObjects[index].job_descriptions.push({description:"",start_date:"",end_date:"",clientName:"",industryArea:"",
                      isEditing: true, isCollapsed: false, isDisabled: false, firstEdit: true});
                    setNewJobObjects(newJobObjects);
                    forceUpdate();
                  }} disabled={previousExperience.isEditing}>
                    Agregar experiencias <AddCircleIcon/>
                  </Button>
                  
                </div>
                  )}
                <Stack spacing={1} className="previous-jobs-detail">
                  {previousExperience.job_descriptions.map((job, index_2) => {
                    return (
                      <LawyerExperienceForm previosExperienceDescription={job} index={index_2} UUid={previousExperience.UUid}
                      raiseAlert={raiseAlert} showEditOptions={showEditOptions}/>
                    );
                    
                  })}
                </Stack>
                
                  {notification}
                </Collapse>
              


            </Item>
          );
        })}
      </Stack>
    </Box>
  );
}


export function ComboBox(props) {
  const {disabled} = props;
  const category = props.category;
  const options = props.options;
  const setCurrentOption = props.setCurrentOption;
  const defaultOption = props.defaultOption;
  const id = props.id;
  const value = props.value;
  return (
    <Autocomplete
      disabled={disabled}
      onChange={(event, value) => {
        setCurrentOption(value);
      }}
      disablePortal
      id={id}
      options={options}
      getOptionLabel={(option) => option.name ?? option }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.UUid ?? option.id ?? option}>
            {option.name ?? option}
          </li>
        );
      }}
      isOptionEqualToValue = {(option, value) => {
        return option.UUid? 
          option.UUid === value.UUid :
          option.ID? option.ID === value.ID : 
            option === value;
      }}
      defaultValue = {defaultOption ? defaultOption: null}
      value = {value ? value: null}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label = {category} />}
    />
  );
}


export const LawyerSelector = (props) => {
  let {lawyer_options, globalindex, localindex,proposal_objects,value, update} = props;
  let allowToEdit = !value.allowToEdit || proposal_objects[globalindex]?.allowToEdit;
  const disabled = !proposal_objects[globalindex]?.lawyerList[localindex].allowToEdit;
  const validValue = value !== null && value !== undefined && value !== "";
  const [selectedLawyer, setSelectedLawyer] = useState(validValue ? value:  "");
  const [lawyerInputValue,setLawyerInputValue] = useState(validValue ? value: "");
  function isValuePresent(value) {return (value !== null && value !== undefined && value !== "");}
  const [lawyers, setLawyers] = useState([new lawyerData()]);
  const [selectedLawyerInfo, setSelectedLawyerInfo] = useState(new lawyerData());
  useEffect(()=>{
    setLawyers(lawyer_options.map(item => {
      const lawyer_data = new lawyerData(item.UUid, item.rating.toFixed(2) ?? "", item.workload?? "", item.user.auth.username, item.user.auth.first_name +" " + item.user.auth.last_name, item.user.auth.email, item.user.UUid, item.suggested_payment, item.exceed_workload);
      if(lawyer_data.username == selectedLawyer?.username){
        setSelectedLawyerInfo(lawyer_data);
      }
      return {
        key: item.UUid,
        score:   item.rating?.toFixed(2) ?? 0,
        workload: item.work_load?? "",
        username: item.user.auth.username,
        fullname: item.user.auth.first_name + " " + item.user.auth.last_name,
        email: item.user.auth.email,
        category: item.category ?? "",
        userUUID: item.user.UUid,
        suggested_payment: item.suggested_payment,
        overWork: item.exceed_workload,
      };
    })
    );
  }, [disabled,lawyer_options]);

  useEffect(()=>{
    if (selectedLawyer === null || selectedLawyer === ""  || disabled ||lawyers.length === 1){
      return;
    }
    const suggested_cost =  proposal_objects[globalindex].price * selectedLawyer.suggested_payment;
    proposal_objects[globalindex].lawyerList[localindex].cost= Math.floor(suggested_cost*100)/100;
    update();
  },
  [selectedLawyer]);  
  const Tooltip = (lawyerData) => {
    const link = "/lawyers/experience/" + `${lawyerData.userUUID}`;
    return <React.Fragment key={lawyerData.key}>  
      <Typography color="inherit" key={lawyerData.key}>Información adicional</Typography>
      {lawyerData.overWork? <div style={{ color: "#c34f4b", fontWeight: "800", fontSize:"10"}}> <WarningIcon/> Exceso de carga laboral</div>:<></>}
      <table>
        {isValuePresent(lawyerData.fullname) && (<tr><th>Nombre</th> <td>{lawyerData.fullname}</td></tr>)}
        {isValuePresent(lawyerData.username) && (<tr> <th>Usuario</th> <td>{lawyerData.username}</td></tr>)}
        {isValuePresent(lawyerData.score) &&   (<tr> <th>Score</th> <td>{lawyerData.score}</td></tr>)}
        {isValuePresent(lawyerData.workload) &&   (<tr> <th>Carga Laboral Permitida</th> <td>{lawyerData.workload}</td></tr>)}
        {isValuePresent(lawyerData.email) &&  (<tr> <th>Correo</th> <td>{lawyerData.email}</td></tr>)}
      </table>
      <div className="tooltip-button">
        <Button variant="outline-primary"  key={"experience-button-"+ globalindex +"-" + localindex+"-"+lawyerData.key} href={link} fullWidth target="_blank">Ver experiencia</Button>
      </div>
    </React.Fragment>;
  };

  return (
    <React.Fragment>
      {selectedLawyer && disabled
        ?
        <h4 style={{  width: "100%",overflow:"hidden" }}>
          <HtmlInfoTooltip  key={"tooltip-"+ globalindex +"-" + localindex} data={props}  style={{ color: "#58862d", fontWeight: "800"}} title={Tooltip(selectedLawyerInfo)} placement="bottom"  enterDelay={100} leaveDelay={50}>
            <Box data={props} key={"toooltip-box-"+ globalindex +"-" + localindex}>
              {selectedLawyer?.fullname?? selectedLawyer?.username ?? selectedLawyer ?? null }
            </Box>
          </HtmlInfoTooltip>
        </h4>
        :
        <Autocomplete
          id="jobLawyer"
          disabled = {allowToEdit}
          style={{ width: "100%" }}
          options={lawyers}
          value={selectedLawyer}
          getOptionLabel={(option) => option.fullname}
          isOptionEqualToValue={(option, value) => option.lawyerUUID === value.lawyerUUID}
          renderOption={(props, option) => {
            const color = interpolateColor(option.score/10, 50);
            return (
              <HtmlInfoTooltip key={"render-"+ globalindex +"-" + localindex+"-"+option.key} {...props}  title={Tooltip(option)} placement="right-start"  enterDelay={100} leaveDelay={50}>
                <Box component="li" key={"render-box-"+ globalindex +"-" + localindex+"-"+option.key} {...props} style={{ backgroundColor: color, fontWeight: "600" }}>
                  {option.fullname}
                </Box>
              </HtmlInfoTooltip>
            );
          }}
          onChange={(event, newLawyer) => {
            setSelectedLawyer(newLawyer);
            proposal_objects[globalindex].lawyerList[localindex].fullname = newLawyer?.fullname ?? null;
            proposal_objects[globalindex].lawyerList[localindex].lawyerUUID = newLawyer?.key ?? null;
          }}

          inputValue={lawyerInputValue}
          onInputChange={(event, newLawyerInputValue) => {
            setLawyerInputValue(newLawyerInputValue);
          }}

          renderInput={(params) => {
            return <TextField label={"Abogados Disponibles"} {...params} />;
          }}
        />
      }
    </React.Fragment>
  );
};