import { useState, useEffect } from "react";
import "../../styles/userShow.scss";
import {
  BasicStack,
  ComboBox,
} from "../../components/users/multiSelect";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {Box , Grid,Button, Collapse, FormHelperText } from "@mui/material";
import {React} from "react";
import { LoadingView } from "../LoadingView";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {  Form } from "react-bootstrap";
import { queryAllServices } from "./utils";
import fetchData from "../../utils";
import Multiselect from "multiselect-react-dropdown";
import { queryAllIndustries, queryAllLawAreas, queryFilterServicesByAreaAndIndustry } from "../service/utils";

const MAX_SERVICE_DESCRIPTION_LENGTH = 500;

export function UserExperience() {

  const [user, setUser] = useState(null);
  const [services, setServices] = useState([]);
  const [servicesFillter, setServicesFillter] = useState([]);
  const [current_service_selected, setCurrentServiceSelected] = useState(null);
  const [collapseCreateLegalService, setCollapseCreateLegalService] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");
  const [newServiceDescription, setNewServiceDescription] = useState("");
  const [newJobObjects,setNewJobObjects] = useState([]);

  const [allIndustries, setAllIndustries] = useState([]);
  const [filterIndustries, setFilterIndustries] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [filterAreas, setFilterAreas] = useState([]);

  useEffect(() => {
    fetchData("/user/current_user/", "GET")
    .then((response) => {
      setUser(response.current_user);
    });
    queryAllServices().then((response)=>{
      setServices(response.data);
      setServicesFillter(response.data);
    });
    queryAllIndustries()
    .then((json)=>{
      setAllIndustries(json.data)
    })
    queryAllLawAreas()
    .then((json)=>{
      setAllAreas(json.data)
    })
  }, []);

  const handleIndustriesChange = (e) =>{
    setFilterIndustries(e.map((value)=> {return value.id}));
  }
  const handleAreasChange = (e) =>{
    setFilterAreas(e.map((value)=> {return value.id}));
  }

  useEffect(()=>{
    if (filterIndustries.length === 0 && filterAreas.length ===0){
      setServicesFillter(services);
      return;
    }
    
    queryFilterServicesByAreaAndIndustry(filterAreas, filterIndustries,"AND")
    .then((json)=>{
      setServicesFillter(json.data)
    })
  }
  ,[filterIndustries, filterIndustries.length, filterAreas, filterAreas.length]);
  if (user) {
    return (
      <>
        <div className="user-show">
          <h1>
          Agregar Experiencias Previas
          </h1>
          <h1>Filtros</h1>
          <Grid container marginBottom={"1.5vw"}>
            <Grid item xs={12} md={6} >
              <Box display="flex" justifyContent="center">
              <div>Filtrar por Industria</div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <Box display="flex" justifyContent="center" >
              Filtrar por Área
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <Box>
                <div><Multiselect showArrow options={allIndustries} displayValue="name" onRemove={handleIndustriesChange} onSelect={handleIndustriesChange} avoidHighlightFirstOption /></div>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} >
              <Box> 
                <Multiselect showArrow options={allAreas} displayValue="area_name" onRemove={handleAreasChange} onSelect={handleAreasChange} avoidHighlightFirstOption/>
              </Box>
            </Grid>
          </Grid>
          <Grid 
            container
            alignItems="center"
            display="flex"
            spacing={4}>
            <Grid item  xs={9} md={10}>
              <ComboBox
                setCurrentOption={setCurrentServiceSelected}
                value={current_service_selected}
                userID = "combo-box-demo"
                options={servicesFillter.map((service) => {return {"name": service.service_name,"UUid": service.UUid ,"id": service.id};})}
                category="Servicios Legales"
              >
              </ComboBox>
            </Grid>
        
        
            <Grid  item xs="auto"  md={2}>
            
              <Tooltip
                title={
                  <Typography fontSize={20}>
                    {current_service_selected === "" || current_service_selected === null
                      ? "Registra tus experiencias más relevantes durante tu desempeño profesional seleccionando por separado los servicios legales que has realizado a la fecha."
                      : "Agregar experiencias relacionadas a ese servicio."}{" "}
                  </Typography>
                }
                placement="right"
              ><span>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={current_service_selected === ""|| current_service_selected === null} 
                    onClick={() => {
                      if (current_service_selected != null) {
                        setNewJobObjects([
                          ...newJobObjects,
                          {
                            "service": {
                              ...current_service_selected,
                              "service_name": current_service_selected.name
                            },
                            "quantity": 1,
                            "isCollapsed":true,
                            "isDisabled": false,
                            "isEditing": true,
                            "id": "",
                            "pending_service": {
                              "pending_service_name": null,
                              "pending_service_description": null,
                            },
                            "userID" :user.id,
                            "job_descriptions": []
                          }
                        ]);
                        setCurrentServiceSelected(null);
                      }
                    }}
                  >
                    <AddCircleIcon></AddCircleIcon>
                  </Button>
                </span>
              </Tooltip>

            </Grid>
            <Grid item xs={12} md={10}>
              <h3>
                ¿No encuentras el servicio legal que estás buscando?
              </h3>            
            </Grid>

            <Grid item xs={12} md={2}>
              <Tooltip
                title={
                  <Typography fontSize={20}>
                    Si hay algun servicio legal frangmentado que no esté en el listado propuesto por iL, 
                    te invitamos a indicarlo para ser homologado o agregado dentro de tu experiencia profesional.
                  </Typography>
                }
                placement="right"
              >
                <Button
                  size="large"
                  variant="contained"
                  disabled={current_service_selected !== "" && current_service_selected !== null}
                  onClick={() => {setCollapseCreateLegalService(!collapseCreateLegalService);}}
                >
                  {collapseCreateLegalService? (
                    <ExpandLessIcon></ExpandLessIcon>
                  ) : (
                    <ExpandMoreIcon></ExpandMoreIcon>
                  )}                
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12} md={12}>

              <Collapse in={collapseCreateLegalService} timeout="auto">
                <Form onSubmit={(e)=>
                {
                  e.preventDefault();

                  setNewJobObjects([
                    ...newJobObjects,
                    {
                      "service": {
                        "service_name": null
                      },
                      "quantity":1,
                      "isCollapsed":true,
                      "isDisabled": false,
                      "isEditing": true,
                      "id": "",
                      "pending_service": {
                        "pending_service_name": newServiceName,
                        "pending_service_description": newServiceDescription,
                      },
                      "userID" :user.id,
                      "job_descriptions": []
                    },
                  ]);

                  setNewServiceName("");
                  setNewServiceDescription("");

                  setCurrentServiceSelected(null);
                }
                }>
                  <Form.Group>
                    Nombre del servicio legal
                    <Form.Control type="text" 
                      value={newServiceName}
                      onChange={(e)=>{setNewServiceName(e.target.value);}}
                      id="newServiceName"
                      label="newServiceName"
                      placeholder="Indique el nombre del servicio legal"
                    />
                    Description del servicio legal
                    <Form.Control as="textarea" 
                      value={newServiceDescription}
                      onChange={(e)=>{setNewServiceDescription(e.target.value);}}
                      rows="3" 
                      maxLength={MAX_SERVICE_DESCRIPTION_LENGTH} 
                      id="newServiceName"
                      label="newServiceName"
                      placeholder="Indique el nombre del servicio legal"
                    />
                  </Form.Group>
                  <FormHelperText  ext error={newServiceDescription.length===MAX_SERVICE_DESCRIPTION_LENGTH}>{newServiceDescription.length}/{MAX_SERVICE_DESCRIPTION_LENGTH}</FormHelperText>
                  <Button type="submit" disabled={newServiceName.length === 0 || newServiceDescription.length === 0}> Agregar experiencia previa </Button>
                </Form>
              </Collapse>
            </Grid>
          </Grid>
        
        </div>
        <div className="user-show">
          <BasicStack
            newJobObjects={newJobObjects}
            setNewJobObjects={setNewJobObjects}
            category="Servicios Legales"
            user = {user}
            showEditOptions={true}
          ></BasicStack>

        </div></>
    );
  }
  return (
    <div>
      <LoadingView />
    </div>
  );
}
