import '../styles/starRating.scss';
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";

export default function StarRating({ stars }) {
  // Máxima cantidad de estrellas
  const maxStars = 5;

  // Obtenemos el valor completo
  const starPercentage = (stars / maxStars) * 100;

  // Redondeamos el resultado si es decimal
  const starPercentageRounded = Math.round(starPercentage);

  // Creamos el estilo para que las estrellas amarillas
  // se vean según el número que recibimos.
  const StarStyles = () => {
    return {
      width: starPercentageRounded + "%"
    };
  };
  const starsFont = [];
    for (let i = 0; i < maxStars; i++) {
      starsFont.push((
        <StarIcon key={i} className="star"
        />));
    }

  return (
    <div className="star-rating">
      <div className="back-stars">
        {starsFont.map((star) => star)}
  
        <div className="front-stars" style={StarStyles()}>
          {starsFont.map((star) => star)}
        </div>
      </div>
    </div>
                
  );
}


export function StarRatingHover({ stars, setStars, handleChange=null }) {

  const [finalRating, setFinalRating] = useState(stars);
  const maxStars = 5;
  const starPercentage = (stars / maxStars) * 100;

  const starPercentageRounded = Math.round(starPercentage);

  const handleMouseOver = (i) => {
    setStars(i);
  };
  const handleMouseOut = () => {
    setStars(finalRating);
  };
  const rate = (i) => {
    setStars(i);
    setFinalRating(i);
    if (handleChange) {
      handleChange(i);
    }
  };
  const StarStyles = () => {
    return {
      width: starPercentageRounded + "%"
    };
  };
  const starsFont = [];
    for (let i = 0; i < maxStars; i++) {
      starsFont.push((
        <div className="star-hover">
          <StarIcon key={i} className="star" sx={{ zIndex: '1' }}/>
          <i className="star-half-1"  onMouseOver={() => handleMouseOver(1+i-0.5)}
                    onClick={() => rate(1+i-0.5)}
                    onMouseOut={() => handleMouseOut()}></i>
          <i className="star-half-2"  onMouseOver={() => handleMouseOver(1+i)}
                    onClick={() => rate(1+i)}
                    onMouseOut={() => handleMouseOut()}></i>
        </div>));
    }
  return (
    <div className="star-rating">
      <div className="back-stars">
        {starsFont.map((star) => star)}
  
        <div className="front-stars" style={StarStyles()}>
          {starsFont.map((star) => star)}
        </div>
      </div>
    </div>      
  );
}