import fetchData from "../../utils";

export async function queryProposalsByUser() {
    return fetchData("/project/queryProposalsByUser", "GET", null);
}
export async function getJobsByID(jobID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/getJobsByID/${jobID}`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
})
.then((response) => {
  if (!response.ok) {
    throw new Error(response.status);
  }
  else {
    return response.json();
  }
})
}

export async function queryAllServices() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryAllServices`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
})
.then((response) => {
  if (!response.ok) {
    throw new Error(response.status);
  }
  else {
    return response.json();
  }
})
}

export async function queryAcceptProposal(proposalID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryAcceptProposal/${proposalID}`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
})
.then((response) => {
  if (!response.ok) {
    throw new Error(response.status);
  }
  else {
    return response.json();
  }
})
}

export async function queryRejectProposal(proposalID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryRejectProposal/${proposalID}`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
})
.then((response) => {
  if (!response.ok) {
    throw new Error(response.status);
  }
  else {
    return response.json();
  }
})
}

export async function queryAllProposalRejectReasons() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryAllProposalRejectReasons`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
})
.then((response) => {
  if (!response.ok) {
    throw new Error(response.status);
  }
  else {
    return response.json();
  }
})
}


export async function queryCreateRejectReasons(rejectSelectedReasons, proposalID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryCreateRejectReasons`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
  body: JSON.stringify({
      "rejectSelectedReasons" : rejectSelectedReasons,
      "proposalID": proposalID,
  })
  })
  .then(async(response) => {
  if(!response.ok) {
    const errorMessage = await response.json();
    const error = Error(errorMessage["message"]);
    error.status = response.status;
    throw error;
  }
  else{
    return response.json();
  }
  })
}



export async function queryFilterLawyersByServices(serviceList) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryFilterLawyersByServices/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
      
    },
    body: JSON.stringify({
      "serviceList" : serviceList,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else{
        return response.json();
      }
    });
}


export async function getRejectReasonsByProposalID(proposalID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/getRejectReasonsByProposalID/${proposalID}`, {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
  })
  .then(async(response) => {
  if(!response.ok) {
    const errorMessage = await response.json();
    const error = Error(errorMessage["message"]);
    error.status = response.status;
    throw error;
  }
  else{
    return response.json();
  }
  })
}

export async function queryStartJob(jobID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryStartJob`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "jobID" : jobID,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}

export async function querySubmitJob(jobID){
  const url = "/project/querySubmitJob";
  const method = "PUT";
  const body = {"jobID": jobID};
  return await fetchData(url,method,body);
}

export async function queryCreatePendingService(pendingServiceName, pendingServiceDescription, userID) {
  const url = "/project/queryCreatePendingService";
  const method = "POST";
  const body = {
    "pendingServiceName": pendingServiceName,
    "pendingServiceDescription": pendingServiceDescription,
    "userID": userID,
  };
  return await fetchData(url,method,body);

}

export async function queryWorkLoad(jobID){
  const url = `/project/queryWorkLoad/${jobID}`;
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryDeleteProject(projectUUID){
  const url = "/project/queryDeleteProject";
  const method = "POST";
  const body = {
    "projectUUID": projectUUID,
  };
  return await fetchData(url,method,body);
}