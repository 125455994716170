import React from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/diagnosticIndex.scss"


export function DiagnosticIndex(props) {
  const { diagnostics } = props;
  const navigate = useNavigate();

  return (
    <div className="user-show">
      {diagnostics.length === 0 ? 
        <h2>No hay diagnósticos aun con este cliente, cree alguno para visualizarla</h2> 
        : 
        <div className='user-table'>
          <h1>Diagnósticos</h1>
          <div className="diagnostic-legend">
            <div className="diagnostic-legend-item">
              <div className="diagnostic-legend-item-color green"></div>
              <div className="diagnostic-legend-item-text">Pasó a propuesta</div>
            </div>
            <div className="diagnostic-legend-item">
              <div className="diagnostic-legend-item-color red"></div>
              <div className="diagnostic-legend-item-text">No concretado</div>
            </div>
            <div className="diagnostic-legend-item">
              <div className="diagnostic-legend-item-color gray"></div>
              <div className="diagnostic-legend-item-text">En proceso</div>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Título</th>
                <th>Ver</th>
              </tr>
            </thead>
            <tbody>
              {diagnostics.map((diagnostic, index) => {
                const goToMeeting = ()=>{navigate(`/diagnostic_meetings/${diagnostic.UUid}/`);};
                const color = diagnostic.status === "PASSED_TO_PROPOSAL" ? "green-line" : diagnostic.status === "REJECTED" ? "red-line" : "gray-line";
                return (
                  <tr key={index + 1} className={color}>
                    <th>{index + 1}</th>
                    <th>{diagnostic.subject}</th>
                    <th> <Button onClick={goToMeeting}>Ver</Button></th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      }
    </div>
  )
}