import React, { useEffect, useState } from "react";
import { ComboBox } from "../users/multiSelect";
import { Grid,Button} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SearchIcon from '@mui/icons-material/Search';
import FetchData from "../../utils";
import { SuccessButton } from "../Buttons";
import { useNavigate } from "react-router-dom";

export function DiagnosticClientSearch(props) {
  const { clients, setCurrentClient, setDiagnostics } = props;
  const [currentClientSelected, setCurrentClientSelected] = useState(null);
  const [options, setOptions] = useState([]); // [{name: "name", id: "id"}
  const navigate = useNavigate();

  const handleClick = () => {
    let clientSelected = clients.find(
      (client) => client.id === currentClientSelected.id);
    setCurrentClient(clientSelected);
    FetchData(`/diagnostic/index_kam/${clientSelected.id}/`, "GET")
    .then((response) => {
      setDiagnostics(response["data"]);
    })
    .catch((error) => {
      // console.log(error);
    });
  };
  useEffect(() => {
    const options = clients.map((client) => {
      let name = "";
      if (client.type.name === "Persona Jurídica") {
        name = `Empresa: ${client.company.name}, Contacto: ${client.user.auth.first_name} ${client.user.auth.last_name}`
      } else {
        name = `Persona: ${client.natural_person.first_name} ${client.natural_person.last_name}, Contacto: ${client.user.auth.first_name} ${client.user.auth.last_name}`
      }
      return {"name": name,"UUid": client.UUid ,"id": client.id};
    });
    setOptions(options);
  }, [clients]);
  return (
  <div className="user-show">
    <h1>
      Elegir cliente
    </h1>

    <Grid 
      container
      alignItems="center"
      display="flex"
      spacing={3}>
      <Grid item  xs={9} md={10}>
        <ComboBox
          setCurrentOption={setCurrentClientSelected}
          value={currentClientSelected}
          userID = "combo-box-demo"
          options={options}
          category="Buscar cliente"
        >
        </ComboBox>
      </Grid>


      <Grid  item xs="auto"  md={2}>
      
        <Tooltip
          title={
            <Typography fontSize={20}>
              {!currentClientSelected 
                ? "Seleccione un cliente"
                : "Buscar reunines diagnósticas previas."}
            </Typography>
          }
          placement="right"
        ><span>
            <Button
              size="large"
              variant="contained"
              disabled={!currentClientSelected} 
              onClick={handleClick}
            >
            <SearchIcon/>
            </Button>
          </span>
        </Tooltip>

      </Grid>
    </Grid>
    <div className="diagnostic-client-flex">
      <h3>¿El cliente no existe?</h3>
      <SuccessButton onClick={()=> navigate("/clients/create/")}>Crear cliente</SuccessButton>
    </div>
  </div>
  )
}