import fetchData from "../../utils";

export async function queryCreatePreviousExperience(selectedExperience, jobExperienceQuantities,lawyerID,pendingServiceID) {
  return await fetchData(`/project/queryCreatePreviousExperience`, "POST", {
    "selectedExperience": selectedExperience,
    "jobExperienceQuantities": jobExperienceQuantities,
    "lawyerID": lawyerID,
    "pendingServiceID": pendingServiceID ?? 0,
  });
}

export async function queryPreviousExperienceByLawyerID(lawyerID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryPreviousExperienceByLawyerID/${lawyerID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      else {
        return response.json();
      }
    });
}

export async function queryDeletePreviousExperience(previousExperienceID) {
  return await fetchData(`/project/queryDeletePreviousExperience`, "POST", {
      "previousExperienceID" : previousExperienceID,
    });
}

export async function queryUpdatePreviousExperience(id, jobExperienceQuantities) {
  return await fetchData(`/project/queryUpdatePreviousExperience`, "PUT", 
    {
      "id" : id,
      "jobExperienceQuantities": jobExperienceQuantities
    }).catch((error)=>{
      return(error);
    });
}
  
export async function queryGetLawyerIDByUserID(userID){
  const url =`/project/queryGetLawyerIDByUserID/${userID}`;
  const method = "GET";
  const body = null;
  return await fetchData(url, method, body);
}

export async function queryAllRoles(){
  const url ="/user/queryAllRoles";
  const method = "GET";
  const body = null;
  return await fetchData(url, method, body);

}
export async function getUserByUsername(username){
  const  url = `/user/getUserByUsername/${username}/`;
  const  method = "POST";
  const  body = { 
    "username":username,
  };
  return await fetchData(url,method,body);
}

export function interpolateColor(factor, opacity) {
  const color1 = "#ecf9ec";
  const color2 = "#00b304";
  const hexToRGB = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  const rgbToHex = (rgb) => {
    const [r, g, b] = rgb;
    const componentToHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const [r1, g1, b1] = hexToRGB(color1);
  const [r2, g2, b2] = hexToRGB(color2);

  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));

  return rgbToHex([r, g, b]) + opacity;
}