import React, { useState, useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import Apply from "../../img/Apply.jpg";
import LogIn from "../../img/Log_in.jpg";
import ImageCarousel from "../../components/auth/carousel";
import "../../styles/home.scss";
import { LoadingView } from "../LoadingView";

const imgs = [
  {
    src: Apply,
    firstLabel: "First slide label",
    secondLabel: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
  },
  {
    src: LogIn,
    firstLabel: "Second slide label",
    secondLabel: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export function Home () {
  const navigate = useNavigate();
  const [user, setUser] = useState({"auth":{}});
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/current_user/`, {
      headers: {
        "Content-Type":"application/json",
        "Authorization": `Token ${token}`,
      }})
      .then((response) => {
        if(!response.ok) {
          throw new Error(response.status);
        }
        else {
          return response.json();
        }
      })
      .then((json) => setUser(json["current_user"]))
      .then(() => setIsLoaded(true))
      .catch((error) => {
        if (error.message === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        }
      });
  }, [navigate]);
  if (isLoaded) {
    return (
      <div>
        <h1>Bienvenido {user.auth.first_name} {user.auth.last_name} </h1>
        <div className="container-carrousel">
          <div className="carrousel">
            <ImageCarousel imgs={imgs}/>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <LoadingView />
    </div>
  );
}