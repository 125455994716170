import { useState, useEffect  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "../../styles/userShow.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import fetchData from "../../utils";
import { CloseButton, RejectButton, SuccessButton } from "../../components/Buttons";
import { Form } from "react-bootstrap";
import { GenericalModal } from "../kam/utilsComponent";
import { queryDeleteLawArea, queryUpdateLawArea } from "./utils";
import Notification from "../../components/Notification";

export function LawAreaShow() {
  const [isEditing, setIsEditing] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEraseServiceModal, setShowEraseServiceModal] = useState(false);
  const [notification, raiseAlert] = Notification("success", "");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [lawArea, setLawArea] = useState(null);
  const { UUid } = useParams();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const onSubmit = ()=>{
    if (isEditing){
      queryUpdateLawArea(UUid, currentName).then((response)=>{
        let aux_lawArea = lawArea;
        aux_lawArea.area_name = currentName;
        setLawArea(aux_lawArea);
        raiseAlert(response);
      });
    }
    setShowConfirmationModal(false);
    setIsEditing(!isEditing);
  };
  useEffect(() => {
    const url = `/project/getLawAreaByID/${UUid}`;
    const method = "GET";
    fetchData(url,method).then((lawArea)=>{
      setLawArea(lawArea.data);
      setCurrentName(lawArea.data.area_name);
    });
  }, [navigate, UUid]);


  const  handleCancel = () => {
    setCurrentName(lawArea.area_name); 
    setShowConfirmationModal(false);
    setIsEditing(!isEditing);
  };

  const handleCancelSave = () => {
    setShowConfirmationModal(false);
  };


  const handleEraseLawArea = () =>{
    queryDeleteLawArea(UUid);
    setShowConfirmationModal(!showConfirmationModal);
    navigate(-1);  
  };

  if (lawArea) {
    return (
      <div>
        <GenericalModal
          show={showConfirmationModal}
          footer={(
            <>
              <SuccessButton onClick={onSubmit}>    Confirmar</SuccessButton>
              <CloseButton   onClick={handleCancelSave}>Cancelar</CloseButton>
            </>
          )}
          title={"¿Está seguro de modificar esta área legal?"}
        />  
        <GenericalModal
          show={showEraseServiceModal}
          title={"¿Está seguro de eliminar esta área legal?"}
          footer={(
            <>
              <RejectButton onClick={()=>{handleEraseLawArea();}}>    Eliminar</RejectButton>
              <CloseButton  onClick={()=>{setShowEraseServiceModal(false);}}>Cancelar</CloseButton>
            </>
          )}
        />     

        <Form>
          <div className="user-show">
            <h1>Area del derecho</h1>
            <div>
              <div className="user-show-info">
                <Form.Label>
                  Nombre:
                </Form.Label>
                {isEditing ?
                  <Form.Control as="input"
                    value={currentName}
                    onChange={(e)=> setCurrentName(e.target.value)}
                    sx={{ width: "80%" }}
                    label="Nombre del servicio legal"
                    placeholder="Por favor complementar el nombre del servicio legal"
                  />
                  : 
                  <div style={{"marginLeft": "10px"}}>
                    {lawArea.area_name?? "NA"}
                  </div>
                }
              </div>
            </div>

            {isEditing?
              <>
                <SuccessButton 
                  onClick={()=>{
                    setShowConfirmationModal(true);}}
                  disabled={!currentName}
                > 
                Guardar Cambios
                </SuccessButton>
                <Button variant="contained"            
                  onClick={()=> {
                    handleCancel();}}
                > 
                Volver sin guardar
                </Button>
                <RejectButton onClick={()=>{setShowEraseServiceModal(true);}}> Eliminar área Legal</RejectButton>
              </>
              :
              <>
                <CloseButton 
                  onClick={()=>{
                    setIsEditing(!isEditing);
                    onSubmit();
                    forceUpdate();}}
                  disabled={!currentName|| lawArea.is_deleted}
                > 
              Editar Área del Derecho
                </CloseButton>
                <Button variant="contained" onClick={handleBack}>
                  {!isEditing? "Volver" : "Volver sin guardar"}
                </Button>   
              </>
            }   
          </div>
        </Form>
        {notification}
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
