import { useState } from "react";
import { Form } from "react-bootstrap";
import { Button } from "@mui/material";
import  fetchData  from "../../utils"


export function PdfField(props) {
  const { title, description, baseDescription, allFields, setAllFields, order, pdfField } = props;
  const { raiseAlert } = props;
  const [descriptionState, setDescriptionState] = useState(description);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const body = {
      "data": {
        "description": descriptionState,
      }
    };
    fetchData(`/project/update_project_field/${pdfField.id}`, "PUT", body)
    .then((response) => {
      raiseAlert(response);
      setAllFields(
      allFields.map((field) => {
        if (field.field === title) {
          field.field_description = descriptionState;
        }
        return field;
      }));
    })
    .catch((error) => {
      raiseAlert(error);
    })

  }
  const handleDescriptionChange = (e) => {
    e.preventDefault();
    let newDescriptionState = e.target.value;
    setDescriptionState(newDescriptionState);
  }
  return(
    <div className="user-show"> 
      <h1>
        Editar sección PDF 
      </h1>
      <Form onSubmit={onSubmitHandler}>
        
        <Form.Label className = "form-label">{order}. {title}</Form.Label>
        <Form.Control as="textarea"
          id="fieldDescription"           
          sx={{ width: "80%" }}
          label="Descripción"
          rows={10}
          placeholder="Por favor complementar con información adicional relevante"
          onChange = {handleDescriptionChange}
          value={descriptionState}
        />
        <Button
          variant="contained"
          disabled={ descriptionState === baseDescription }
          style={{color:"secondary"}}
          onClick={() => setDescriptionState(baseDescription)}
        >
        Volver al texto base
        </Button>

        <Button
          variant="contained"
          disabled={ description === descriptionState }
          style={{color:"secondary"}}
          type="submit" 
        >
        {description === descriptionState ? "No hay nada que actualizar" : "Actualizar cambios"}
        </Button>
      </Form>
    </div>
  )

}