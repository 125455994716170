import { EditRole } from "./editRole";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { SuccessButton } from "../Buttons";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import React, { useState } from "react";
import { Button } from "@mui/material";
import { GenericalModal } from "../../views/kam/utilsComponent";
import Switch from "react-switch";
import fetchData from "../../utils";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { phoneData } from "../country/phoneData";




export function UserData({ user, isEditing, isAdmin, userAdmin, currentRole,
  currentRoleName, setCurrentRole, setCurrentRoleName, userUUid, setUser, setIsEditing,
  raiseAlert, setLawyer, lawyer, isKAM, isActiveUser, countries, kam, setKam,
  setUpdateSidebar }) {
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentName, setCurrentName] = useState(user.auth.first_name);
  const [email_verified, setEmailVerified] = useState(user.is_email_verified);
  const [currentLastName, setCurrentLastName] = useState(user.auth.last_name);
  const [currentEmail, setCurrentEmail] = useState(user.auth.email);
  const [currentWorkLoad, setCurrentWorkLoad] = useState(lawyer?.work_load?? null);
  const [switchChecked, setSwitchChecked] = useState(lawyer?.is_accepted === "Aceptado");
  const [jurisdiction, setJurisdiction] = useState(lawyer?.jurisdiction ?? null);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [nacionality, setNacionality] = useState(user.nacionality);
  const [currentPhone, setCurrentPhone] = useState(user.phone);
  const [switchCheckedKam, setSwitchCheckedKam] = useState(kam?.is_working_as_lawyer);

  const onSubmitHandler = (e) => {
    setLoadingSendEmail(true);
    const newUser = {
      auth: {
        id: user.auth.id,
        first_name: currentName,
        last_name: currentLastName,
        email: currentEmail,
      },
      id: user.id,
      UUid: user.UUid,
      role_id: currentRole,
      role: currentRoleName,
      is_email_verified: email_verified,
      nacionality: nacionality,
      phone: currentPhone,
      lawyer: {
        work_load: currentWorkLoad,
        is_accepted: switchChecked,
        jurisdiction: jurisdiction,
      },
      kam: {
        is_working_as_lawyer: switchCheckedKam,
      },
    };

    const body = JSON.stringify({
      user: newUser,
    });
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/queryUserByID/${userUUid}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: body,
    })
      .then(async(response) => {
        if (!response.ok) {
          const errorMessage = await response.json();
          const error = Error(errorMessage["message"]);
          error.status = response.status;
          throw error;
        } else {
          setUser(newUser);
          return response.json();
        }
      })
      .then(async(json) => {
        if (newUser.role === "KAM" && switchCheckedKam) {
          setLawyer(json["user"]["kam"]["lawyer"]);
        } else if (lawyer && newUser.role !== "Lawyer") {
          setLawyer(null);
        } else if (lawyer) {
          lawyer.is_accepted = switchChecked ? "Aceptado": "Pendiente";
          lawyer.jurisdiction = jurisdiction;
          setLawyer(
            lawyer
          );
        } else if (!lawyer && newUser.role === "Lawyer") {
          window.location.reload();
          setLawyer(newUser.lawyer)
        } 
        if (kam) {
          setKam((prev) => ({
            ...prev,
            is_working_as_lawyer: switchCheckedKam})
            );
          if (kam.is_working_as_lawyer !== switchCheckedKam) {
            setUpdateSidebar((prev) => !prev);
          }
        }
        json["message"]=[];
        json["severity"]="success";
        if (json["mail_changed"]) {
          setEmailVerified(false);
          const response = await fetchData("/mailer/send_validation/", "POST", {user: newUser.auth});
          json["message"].push(response.message);
          raiseAlert(json);
        } else {
          json["message"].push("Usuario actualizado correctamente.");
          raiseAlert(json);
        }
          
      })
      .then(() => {
        setLoadingSendEmail(false);
        setIsEditing(false);
      })
      .catch((error) => {
        if (error.status === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        } else {
          raiseAlert(error);
          setLoadingSendEmail(false);
        }
      });
  };
  const handleChangeSwitch = (checked) => {
    setSwitchChecked(!switchChecked);
  };

  const handleChangeSwitchKAM = (checked) => {
    setSwitchCheckedKam((prev) => !prev);
  }

  const valor = {
    "Aceptado": true,
    "Pendiente": false
  }
  return (
    <Form id="edit-user">
      <GenericalModal
        show={showConfirmationModal}
        title = {"¿Está seguro de editar el perfil?"}
        body={(lawyer && switchChecked !== valor[lawyer.is_accepted] && isAdmin ) && `Al confirmar va a cambiar el estado a ${!lawyer.is_accepted === "Aceptado" ? "no aceptado" : "aceptado"} del abogado`}
        footer=
          {<>
            <SuccessButton type="submit" onClick={()=>{
              onSubmitHandler();
              setShowConfirmationModal(false);
            }}>  Confirmar </SuccessButton>
            <Button variant="danger" onClick={()=>{setShowConfirmationModal(false);}}>Cancelar</Button>
          </>}
      />

      <div className="user-show-info ">
        <b>
          <Form.Label>Nombre: </Form.Label>
        </b>

        {!isEditing ? (
          <p>{user.auth.first_name}</p>
        ) : (
          <Form.Control
            name="firstName"
            type="text"
            placeholder="Nombre"
            value={currentName}
            onChange={(e)=> setCurrentName(e.target.value)}
          />
        )}
      </div>
      <Form.Group>
        <div className="user-show-info">
          <b>
            <Form.Label>Apellido: </Form.Label>
          </b>
          {!isEditing ? (
            <p>{user.auth.last_name}</p>
          ) : (
            <Form.Control
              name="lastName"
              type="text"
              placeholder="Apellido"
              value={currentLastName}
              onChange={(e)=> setCurrentLastName(e.target.value)}
            />
          )}
        </div>
      </Form.Group>
      <Form.Group>
        <div className="user-show-info">
          <b>
            <Form.Label>Email: </Form.Label>
          </b>
          {!isEditing ? (
            <div className="email-icon">
              <p>{user.auth.email ? user.auth.email : "N/A"}</p>
              {email_verified ? 
                <AiFillCheckCircle style={{"color": "green"}}/>
                : 
                <>
                  <AiFillCloseCircle style={{"color": "red"}}/>
                </>
              }
            </div>
          ) : (
            <Form.Control
              name="email"
              type="text"
              placeholder="email"
              value={currentEmail}
              onChange={(e)=> setCurrentEmail(e.target.value)}
            />
          )}
        </div>
      </Form.Group>
      <Form.Group>
        <div className="user-show-info">
          <b>
            <Form.Label>Teléfono: </Form.Label>
          </b>
          {!isEditing ? (
            <>
              <p>{user.nacionality?.code_number && `+${user.nacionality.code_number} `}{user.phone ? user.phone : "N/A"}</p>
              
            </>
          ) : (
            <Form.Control
              name="phone"
              type="text"
              placeholder="Ingrese su número de teléfono (formato: sin + y sin código país: ejemplo 912345678)"
              value={currentPhone}
              onChange={(e)=> setCurrentPhone(e.target.value)}
            />
          )}
        </div>
      </Form.Group>
      <Form.Group>
        <div className="user-show-info">
          <b>
            <Form.Label>Nacionalidad: </Form.Label>
          </b>
          {!isEditing ? (
            <>
              <p>{user.nacionality ? user.nacionality.name : "N/A"}</p>
            </>
          ) : (
            <Autocomplete
                id="country-select-demo"
                options={countries}
                name="country"
                autoHighlight
                value={nacionality}
                getOptionLabel={(option) => {
                  if (option.name === "") {
                    return "";
                  }
                  return `${option.name} (${option.value}) +${phoneData[option.value.toUpperCase()]}`
                }}
                onChange={(event, newValue) => setNacionality(newValue)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      <img
                      loading="lazy"
                      src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                      alt=""
                    />
                      {option.name} ({option.value}) +{phoneData[option.value.toUpperCase()]}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Elige un país"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
          )}
        </div>
      </Form.Group>
      <div className="user-show-info">
        <b>
          <p>Rol: </p>
        </b>
        {!isEditing || !isAdmin || userAdmin ? (
          <p>{user.role}</p>
        ) : (
          <EditRole
            currentRoleName={currentRoleName}
            setCurrentRole={setCurrentRole}
            setCurrentRoleName={setCurrentRoleName}
          />
        )}
      </div>
      {lawyer !== null ?
        <>
          <Form.Group>
            <div className="user-show-info">
              <b>
                <Form.Label>Horas semanales disponibles: </Form.Label>
              </b>
        
              {!isEditing ? (
                
                <p>{  currentWorkLoad >= 0 ? currentWorkLoad : "N/A"}</p>
              ) : (
                <Form.Control
                  name="work_load"
                  type="number"
                  placeholder="work_load"
                  value={currentWorkLoad}
                  onChange={(e)=> setCurrentWorkLoad(e.target.value)}
                />
              )}
            </div>
          </Form.Group>
          <Form.Group>
          <div className="user-show-info">
            <b>
              <Form.Label>Jurisdicción: </Form.Label>
            </b>
      
            {!isEditing ? (
              
              <p>{lawyer.jurisdiction ? lawyer.jurisdiction.name : "N/A"}</p>
            ) : (
              <Autocomplete
                id="country-select-demo"
                options={countries}
                name="country"
                autoHighlight
                value={jurisdiction}
                getOptionLabel={(option) => {
                  if (option.name === "") {
                    return "";
                  }
                  return `${option.name} (${option.value}) +${phoneData[option.value.toUpperCase()]}`
                }}
                onChange={(event, newValue) => setJurisdiction(newValue)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      <img
                      loading="lazy"
                      src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                      alt=""
                    />
                      {option.name} ({option.value}) +{phoneData[option.value.toUpperCase()]}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Elige un país"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
            </div>
          </Form.Group>
        </>:<></>
      }
      <div className="user-show-info">

        <b> 
          <Form.Label>Perfil activo: </Form.Label>
        </b>
        <p>
          {isActiveUser ?
            <AiFillCheckCircle style={{"color": "green"}}/>
          : 
            <AiFillCloseCircle style={{"color": "red"}}/>
          }
        </p>
      </div>
      {user.role === "KAM" && (
        <div className="user-show-info">

          <b> 
            <Form.Label>Trabaja como abogado: </Form.Label>
          </b>
          {!isEditing ? (
          <p>
            {kam.is_working_as_lawyer ?
              <AiFillCheckCircle style={{"color": "green"}}/>
            : 
              <AiFillCloseCircle style={{"color": "red"}}/>
            }
          </p>
          ) : (
            <Switch onChange={handleChangeSwitchKAM} checked={switchCheckedKam}
            offColor="#FF0000" onColor="#00FF00"/>
          )}
        </div>

      )}
      
      {lawyer && !isKAM && (
        <div className="user-show-info">
          <b>
            <Form.Label>Aceptado:</Form.Label>
          </b>
          {!isEditing || !isAdmin? (
          <p>
            {lawyer.is_accepted === "Aceptado" ? 
            <AiFillCheckCircle style={{"color": "green"}}/>
             : 
             <AiFillCloseCircle style={{"color": "red"}}/>}

          </p>
          ) : (
            <Switch onChange={handleChangeSwitch} checked={switchChecked}
            offColor="#FF0000" onColor="#00FF00"/>
          )}
        </div>
      )}
      {isEditing ? (
        <>
          <SuccessButton onClick={()=>{setShowConfirmationModal(true);}} disabled={loadingSendEmail}>
            Guardar perfil
            {loadingSendEmail &&
              <CircularProgress size={20} color="inherit" />
            }
          </SuccessButton>
        </>
      ) : (
        <></>
      )}
    </Form>
  );
}
