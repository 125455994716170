import React from "react";

export function DiagnosticClientInfo(props) {
  const { client } = props;
  return (
    <div>
      <div className="user-show">
        <h1>Datos del cliente</h1>
        <p>Nombre: {client.user.auth.first_name}</p>
        <p>Apellido: {client.user.auth.last_name}</p>
        <p>Email: {client.user.auth.email}</p>
      </div>
    </div>
  )
}