import { useState, useEffect } from "react";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import {Form } from "react-bootstrap";
import { Grid } from "@mui/material";
import LawAreaTable from "../../components/law_area/LawAreaTable";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import { queryAllErasedLawAreas } from "./utils";
import { GoBackButton } from "../../components/Buttons";

export function RestoreLawArea () {
  const [lawAreas, setLawAreas] = useState([]);
  const [lawAreasFillter, setLawAreasFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  useEffect(() => {
    queryAllErasedLawAreas().then((response)=>{
      setLawAreas(response.data);
      setLawAreasFillter(response.data);
      setIsLoading(false);
      setAmount(response.data.length);
    });
  }, []);
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    var lawAreasFiltter = lawAreas.filter(({ area_name,  id }) => {
      return area_name.toLowerCase().includes(search.toLowerCase()) ||
      id.toString().includes(search.toLowerCase());
    });
    setLawAreasFillter(lawAreasFiltter);
    setAmount(lawAreasFiltter.length);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);
  
  return (
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
      </Grid>

      <h1>Servicios legales</h1>

      {isLoading? (<LoadingView/>): 
        <>
          <LawAreaTable areas={lawAreasFillter} page_size={pageSize} page_index={pageIndex} type={"Restore"}/>
    Mostrando {amount ?? pageIndex * pageSize+ 1} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
  );
}