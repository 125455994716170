import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/inicio.scss";


export function CardComponent(props) {
  const { title, description, link, linkText, img, imgAlt } = props;
  const navigate =useNavigate();
  const goToLink = ()=> navigate(link);
  return (
      <div className="cardInicio">
        <div className="face front">
          <img src={img} alt={imgAlt}></img>
          <h3>{title}</h3>
        </div>
        <div className="face back" onClick={goToLink}>
          <h3>{title}</h3>
          <p>{description}</p>
          <div className="link">
            <p>{linkText}</p>
          </div>
        </div>
      </div>
    
  );
}