import { useState, useEffect } from "react";
import PendingServiceTable from "../../components/service/pendingServiceTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { Box, Grid } from "@mui/material";
import { queryAllPendingServices } from "./utils";
import { Form } from "react-bootstrap";

export function ServicesProposalList() {
  
  const [services, setServices] = useState([]);
  const [servicesFillter, setServicesFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    queryAllPendingServices()
      .then((json) => {
        setServices(json["data"]);
        setServicesFillter(json["data"]);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.message === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        } else if (error.message === "403") {
          navigate(-1);
        }
      }
      );
  }, [navigate]);

  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    var servicesFiltter = services.filter(({ pending_service_name, industries, lawAreas, pending_service_description }) => {
      return pending_service_name.toLowerCase().includes(search.toLowerCase()) ||
      pending_service_description.toLowerCase().includes(search.toLowerCase()) 
    }
    );
    setServicesFillter(servicesFiltter);
  };
  
  return (
    <>
        <div className="user-show-extended">
        <h1>Filtros</h1>
        <Grid container>
    <Grid item xs={12} md={3}>
    Buscar en la tabla:
    </Grid>
    <Grid item xs={12} md={9} >
      <Box display="flex" justifyContent="center" >
          <Form.Control 
            as="input" 
            placeholder="Búsqueda por texto libre"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
          </Box>
          </Grid>
    </Grid>

        </div>
    
    <div className="user-show-extended">
      <h1>Solicitudes de reconocimiento de servicio legal</h1>
      {isLoading? (<LoadingView/>): <PendingServiceTable users={servicesFillter}/>}
    </div>
    </>
  );
}