import { useState, useEffect  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "../../styles/userShow.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { queryNaturalPersonByUUID } from "./utils";
import { Form } from "react-bootstrap";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import fetchData from "../../utils";
import Notification from "../../components/Notification";


export function NaturalPersonShow() {
  const [person, setPerson] = useState(null);
  const [auxPerson, setAuxPerson] = useState(null); // Para guardar los cambios antes de guardarlos en la base de datos
  const { UUid } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  const [notification, raiseAlert] = Notification("success", "");
  const navigate = useNavigate();

  useEffect(() => {
    queryNaturalPersonByUUID(UUid).then((response) => {
      setPerson(response["data"]);
      setAuxPerson(response["data"]);
    });
  }, [navigate, UUid]);

  const handleBack = () => {
    navigate(-1);
  };
  const handleEdit = () => {
    setIsEditing(!isEditing);
    setAuxPerson(person);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    fetchData(`/user/natural_person/update/${auxPerson.UUid}/`, "PUT", auxPerson)
    .then((response) => {
      setPerson(auxPerson);
      setIsEditing(false)
      response.severity = "success"
      raiseAlert(response);
    })
    .catch((error) => {
      error.severity = "error"
      raiseAlert(error);
    })
  };

  if (person) {
    return (
      <div>
        <div className="user-show">
          <h1> {(person.first_name + " " + person.last_name).trim() !== "" ? "Representado: " + person.first_name + " " + person.last_name : "Persona natural: sin datos"}</h1>
          <div>
            <Form onSubmit={onSubmitHandler}>
              <Form.Group className="mb-3">
                <div className="user-show-info">
                  <b><label>Nombre: </label></b>
                  <p>{(person.first_name).trim() !== "" ? person.first_name : "N/A"}</p>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="user-show-info">
                  <b><label>Apellido: </label></b>
                  {isEditing ? 
                    (<Form.Control
                      name="Apellido"
                      type="text"
                      placeholder="Apellido"
                      value={auxPerson.last_name}
                      onChange={(e)=> setAuxPerson({...auxPerson, last_name: e.target.value})}
                    />)
                    :
                    <p>{(person.last_name).trim() !== "" ? person.last_name : "N/A"}</p>
                  }
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <div className="user-show-info">
                  <b><label>Rut: </label></b>
                  {isEditing ? 
                    (<Form.Control
                      name="Rut"
                      type="text"
                      placeholder="Rut"
                      value={auxPerson.rut}
                      onChange={(e)=> setAuxPerson({...auxPerson, rut: e.target.value})}
                    />)
                    :
                    <p>{person.rut !== "" ? person.rut : "N/A"}</p>
                  }
                </div>
              </Form.Group>
              {isEditing && (<SuccessButton type="submit">Guardar cambios</SuccessButton>)}
            </Form>
          </div>
          <CloseButton onClick={handleEdit}> {!isEditing ? "Editar persona" : "Volver sin guardar"} </CloseButton>
          <Button color="primary" variant="contained" onClick={handleBack}>
            Volver
          </Button>   
        </div>
        {notification}
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
