import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import "../../styles/register.scss";
import Notification from "../../components/Notification";
import { queryCreateUser } from "../client/utils";
import fetchData from "../../utils";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  MDBInputGroup
} from 'mdb-react-ui-kit';
import { CircularProgress } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingView } from "../LoadingView";
import { phoneData } from "../../components/country/phoneData"

export function Register() {
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    fetchData("/country/all/", "GET")
    .then((response) => {
      setCountries(response.data);
      setIsLoading(false);
    })
  }, []);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const { type } = useParams();
  const navigate = useNavigate();
  const [haveLetter, setHaveLetter] = useState(false);
  const [have10Letters, set10Letters] = useState(false);
  const [haveNumber, setHaveNumber] = useState(false);
  const [match, setMatch] = useState(false);
  const [haveCapitalLetter, setHaveCapitalLetter] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [specialCaracter, setSpecialCaracter] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [user, setUser] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    phone: "",
    residence: "",
    jurisdiction: "",
    type: type,
  });

  const [notification, raiseAlert] = Notification("success", "");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);


  const handleChangePassword = (value) => {

    const { confirmPassword } = user;
    const length = value.length >= 10;
    set10Letters(length);

    const number = value.match(/[0-9]/i);
    setHaveNumber(number);

    const letter = value.match(/[a-z]/i);
    setHaveLetter(letter);

    const capitalLetter = value.match(/[A-Z]/);
    setHaveCapitalLetter(capitalLetter);

    const isConfirmPassword = !(value.length === 0 || confirmPassword.length === 0)
    setConfirm(isConfirmPassword);

    const specialCaracterNew = value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
    setSpecialCaracter(specialCaracterNew);

    const matchLocal = value === confirmPassword && value.length > 0;
    setMatch(matchLocal);

    setIsPasswordValid(letter && length && number && capitalLetter && matchLocal && confirmPassword  && specialCaracterNew);

  };
  const handleChangeConfirmPassword = (value) => {

    const { password } = user;
    const matchLocal = value === password && value.length > 0;
    setMatch(matchLocal);

    const confirmLocal = !(value.length === 0 || password.length === 0);
    setConfirm(confirmLocal);

    setIsPasswordValid(haveLetter && have10Letters && haveNumber && haveCapitalLetter && matchLocal && confirmLocal  && specialCaracter);

  };

  const clickHandlerPassword = () => {
    setShowPass(!showPass);
  };

  const clickHandlerConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "password") {
      handleChangePassword(value);
    } else if (name === "confirmPassword") {
      handleChangeConfirmPassword(value);
    }

  };
  const onSubmitHandler = async(e) => {

    e.preventDefault();
    if (user.username === "" || user.email === "" || 
        user.firstName === "" || user.lastName === "" || 
        user.password === "" || user.confirmPassword === "" || 
        user.phone === "" || user.residence === "" || user.jurisdiction === "") {
      const alert = {
        severity: "error",
        message: "Por favor, complete todos los campos",
      }
      raiseAlert(alert);
      return;
    }

    setLoadingSendEmail(true);
    queryCreateUser(user)
    .then((json) => {
      return json.user;
    })
    .then(({auth}) => {
      fetchData("/mailer/send_validation/", "POST", {user: auth})
      .then((json) => {
        json["severity"] = "success";
        raiseAlert(json);
        setLoadingSendEmail(false);
      })
    })
    .catch((error) => {
      raiseAlert(error);
      setLoadingSendEmail(false);
    });
  };
  const isValid = isPasswordValid;
  if (isLoading) {
    return <LoadingView />
  }
  return (
    <div className="big-card">
      {type === "client" ? <h1>Regístrate</h1> : <h1>Postula aquí</h1>}
        <Form onSubmit={onSubmitHandler}>
          <div className="flex-register">
            <Form.Group className="mb-3">
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su nombre de usuario" onChange={handleChange} name="username"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={handleChange} name="email"/>
            </Form.Group>
          </div>
          <div className="flex-register">
            <Form.Group className="mb-3">
              <Form.Label>Ingrese su nombre</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su nombre" onChange={handleChange} name="firstName"/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ingrese su apellido</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su apellido" onChange={handleChange} name="lastName"/>
            </Form.Group>
          </div>
          <div className="flex-register">
            <Form.Group className="mb-3">
              <Form.Label>Residencia </Form.Label>
              <Autocomplete
                id="country-select-demo"
                options={countries}
                name="country"
                autoHighlight
                getOptionLabel={(option) => {
                  return `${option.name} (${option.value}) +${phoneData[option.value.toUpperCase()]}`
                }}
                onChange={(event, newValue) => {
                  setUser((prevState) => ({
                    ...prevState,
                    residence: newValue.value,
                }));}}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      <img
                      loading="lazy"
                      src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                      alt=""
                    />
                      {option.name} ({option.value}) +{phoneData[option.value.toUpperCase()]}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Elige un país"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Jurisdicción</Form.Label>
              <Autocomplete
                id="country-select-demo"
                options={countries}
                name="country"
                autoHighlight
                getOptionLabel={(option) => {
                  return `${option.name} (${option.value}) +${phoneData[option.value.toUpperCase()]}`
                }}
                onChange={(event, newValue) => {
                  setUser((prevState) => ({
                    ...prevState,
                    jurisdiction: newValue?.value,
                }));}}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      <img
                      loading="lazy"
                      src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                      alt=""
                    />
                      {option.name} ({option.value}) +{phoneData[option.value.toUpperCase()]}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Elige un país"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Número telefónico</Form.Label>
            <Form.Control type="text" placeholder="Ingrese su número (formato: sin + y sin código país: ejemplo 912345678)" onChange={handleChange} name="phone"/>
          </Form.Group>
          <div className="flex-register">
            <Form.Group className="mb-3">
              <Form.Label>Ingrese su contraseña</Form.Label>
              <InputGroup>
                <MDBInputGroup className="show-password" noWrap textAfter={showPass ? <AiOutlineEye onClick={clickHandlerPassword}/> : <AiOutlineEyeInvisible onClick={clickHandlerPassword}/>}>
                  <Form.Control type={showPass ? "text" : "password"} placeholder="Contraseña" onChange={handleChange} name="password"/>
                </MDBInputGroup>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirmar contraseña</Form.Label>
              <InputGroup>
                <MDBInputGroup className="show-password" noWrap textAfter={showConfirmPass ? <AiOutlineEye onClick={clickHandlerConfirmPassword}/> : <AiOutlineEyeInvisible onClick={clickHandlerConfirmPassword}/>}>
                  <Form.Control type={showConfirmPass ? "text" : "password"} placeholder="Confirmar contraseña" onChange={handleChange} name="confirmPassword"/>
                </MDBInputGroup>
              </InputGroup>
            </Form.Group>
          </div>
            <p><b>La contraseña debe cumplir los siguientes requerimientos:</b></p>
            <ul className={"list-password"}>
              <li id="letter" className={haveLetter ? "text-success": "text-danger"}>Al menos <strong>una letra</strong></li>
              <li id="capital" className={haveCapitalLetter ? "text-success": "text-danger"}>Al menos <strong>una letra mayúscula</strong></li>
              <li id="number" className={haveNumber ? "text-success": "text-danger"}>Al menos <strong>un número</strong></li>
              <li id="length" className={have10Letters ? "text-success": "text-danger"}>Al menos <strong>10 carácteres</strong></li>
              <li id="match" className={specialCaracter? "text-success" : "text-danger"}>Al menos <strong>un caracter especial</strong></li>
              <small className={specialCaracter? "text-success" : "text-danger"}>{"!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"}</small>
              <li id="null" className={confirm? "text-success" : "text-danger"}>Debe <strong>confirmar la contraseña</strong></li>
              <li id="match" className={match? "text-success" : "text-danger"}>Las contraseñas <strong>deben coincidir</strong></li>
            </ul>
          <Button variant="primary" type="submit" disabled={!isValid || loadingSendEmail}>
            Crear cuenta
            {loadingSendEmail &&
              <CircularProgress size={20} color="inherit" />
            }
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)} >
            Volver
          </Button>
          <p>¿Ya tienes una cuenta? <Link to="/login">Iniciar sesión</Link></p>
      </Form>
      {notification}
    </div>
  );
  
}