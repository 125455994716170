export default async function fetchData(url, method, body=null) {
  if (body) {
    body = JSON.stringify(body);
  }
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: body,
  })
    .then(async(response) => {
      if (!response.ok) {
        if(response.status === 500) {
          const error = Error("Ha ocurrido un problema, pónganse en contacto con el administrador");
          error.status = response.status;
          throw error;
        }
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    })
    .catch((error) => {
      if (error.status === 401) {
        window.localStorage.removeItem("token");
        window.location.replace(window.location.origin);
      } else if (error.status === 403) {
        window.location.replace(window.location.origin);
      } 
      throw error;
    });
}

export const Validate = {
  number: function(value){
    return /^-?\d+$/.test(value);
  },
  rut: function(rutCompleto) {
    if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto))
      return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return (Validate.getDigito(rut) == digv);
  },
  getDigito: function(T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : "k";
  },
  email: function(email){
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  },
  phone: function(phone){
    return Validate.number(phone) && phone.length=== 11;
  },
  text: function(text){
    return text.trim().length>0;
  },
  accent_fold: function (s) {
    if (!s) { return ''; }
    let ret = '';
    const accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','Á':'a', 'É':'e', 'Í':'i','Ó':'o','Ú':'u'};
    for (var i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  },
  isValidUUID: function(UUID){
    return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(UUID);
  },
  date: function(date){
    return /([12]\d{3}-(0[1-9]|1[0-2])-([0-9]([0-9])?|[12]\d|3[01]))/.test(date);
  }
};


export const deltaTime = {
  getDays: function(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  },
  getMinutes: function(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60));
  },
  getHours: function(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60));
  },
  getSeconds: function(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000));
  },
  getNotificationTime: function(date1) {
    const date2 = new Date();
    const date1Date = new Date(date1);
    const diffTime = Math.abs(date2 - date1Date);
    if (diffTime < 1000 * 60) {
      return `${Math.ceil(diffTime / 1000)} segundos`;
    } else if (diffTime < 1000 * 60 * 60) {
      return `${Math.ceil(diffTime / (1000 * 60))} minutos`;
    } else if (diffTime < 1000 * 60 * 60 * 24) {
      return `${Math.ceil(diffTime / (1000 * 60 * 60))} horas`;
    } else if (diffTime < 1000 * 60 * 60 * 24 * 30) {
      return `${Math.ceil(diffTime / (1000 * 60 * 60 * 24))} días`;
    } else if (diffTime < 1000 * 60 * 60 * 24 * 30 * 12) {
      return `${Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30))} meses`;
    } else {
      return "más de un año"
    }
  }
};
