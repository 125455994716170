import { useState, useEffect, useRef  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/userShow.scss";
import { Button, Grid } from "@mui/material";
import { LoadingView } from "../LoadingView";
import React from "react";
import { CloseButton, RejectButton, SuccessButton } from "../../components/Buttons";
import { Form } from "react-bootstrap";
import { getIndustriesByServiceID, getLawAreasByServiceID, getServiceByID, queryAllIndustries, queryAllLawAreas, queryDeleteService, queryUpdateService } from "./utils";
import Notification from "../../components/Notification";
import { Multiselect } from "multiselect-react-dropdown";
import { GenericalModal } from "../kam/utilsComponent";

export function ServiceShow() {
  const { UUid } = useParams();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [lawAreas, setLawAreas] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentName, setCurrentName] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [notification, raiseAlert] = Notification("success", "");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  
  const [currentLawAreas, setCurrentLawAreas] = useState([]);
  const [currentIndustries, setCurrentIndustries] = useState([]);
  const [defaultIndustries, setDefaultIndustries] = useState([]);
  const [defaultLawAreas, setDefaultLawAreas] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEraseServiceModal, setShowEraseServiceModal] = useState(false);
  const lawAreasRef = useRef();
  const industriesRef = useRef();

  const handleMultiSelectLawArea = (e) =>{
    let aux_currentLawAreas = [];
    e.forEach((element,index) => {
      aux_currentLawAreas[index] = element;
      setCurrentLawAreas(aux_currentLawAreas);
    });
  };

  const handleMultiSelectIndustries = (e) =>{
    let aux_currentIndustries = [];
    e.forEach((element,index) => {
      aux_currentIndustries[index] = element;
    });
    setCurrentIndustries(aux_currentIndustries);
  };

  useEffect(()=>{
    queryAllLawAreas().then((response)=>{
      if (response.success){    
        setLawAreas(response.data);
      }
    });
    queryAllIndustries().then((response)=>{
      if (response.success){    
        setIndustries(response.data);
      }
    });
  },[]);

  const onSubmit = ()=>{
    setIsEditing(!isEditing);
    if (isEditing){
      queryUpdateService(UUid, currentName,currentDescription, currentLawAreas, currentIndustries).then((response)=>{
        raiseAlert(response);
      }).catch((error)=>{
        raiseAlert(error);
      });
    }
    setShowConfirmationModal(false);
  };

  const  handleCancel = () => {
    getServiceByID(UUid)
      .then((json) => {
        setCurrentName(json["data"].service_name);
        setCurrentDescription(json["data"].service_description);
        getIndustriesByServiceID(json["data"].id).then((response)=>{
          let aux_defaultIndustries = [];
          response.data.forEach(({industry},index) => {
            aux_defaultIndustries[index] = industry;
          });
          setCurrentIndustries(aux_defaultIndustries);
          setDefaultIndustries(aux_defaultIndustries);
        });
        getLawAreasByServiceID(json["data"].id).then((response)=>{
          let aux_defaultAreas = [];
          response.data.forEach(({area},index) => {
            aux_defaultAreas[index] = area;
          });
          setCurrentLawAreas(aux_defaultAreas);
          setDefaultLawAreas(aux_defaultAreas);
        }); 
        lawAreasRef.current.state.selectedValues= defaultLawAreas;
        industriesRef.current.state.selectedValues= defaultIndustries;
      });
    setIsEditing(!isEditing);
    setShowConfirmationModal(false);
  };

  useEffect(() =>{
    setCurrentIndustries(defaultIndustries);
    setCurrentLawAreas(defaultLawAreas);
  }, [defaultIndustries,defaultLawAreas]);

  useEffect(() => {
    getServiceByID(UUid)
      .then((json) => {
        setCurrentName(json["data"].service_name);
        setCurrentDescription(json["data"].service_description);
        getIndustriesByServiceID(json["data"].id).then((response)=>{
          let aux_defaultIndustries = [];
          response.data.forEach(({industry},index) => {
            aux_defaultIndustries[index] = industry;
          });
          setDefaultIndustries(aux_defaultIndustries);
        });
        getLawAreasByServiceID(json["data"].id).then((response)=>{
          let aux_defaultAreas = [];
          response.data.forEach(({area},index) => {
            aux_defaultAreas[index] = area;
          });
          setDefaultLawAreas(aux_defaultAreas);
          setCurrentLawAreas(aux_defaultAreas);
          forceUpdate();
        });
      });
  }, [navigate, UUid]);

  const handleEraseLegalService = () =>{
    queryDeleteService(UUid);
    navigate(-1);  
  };

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(()=>{
    if (currentName ){  
      setIsLoading(false);
    }
  }, [currentName]);
  if (!isLoading) {
    return (
      <div>
        <GenericalModal
          show={showConfirmationModal}
          footer={(
            <>
              <SuccessButton onClick={onSubmit}>    Confirmar</SuccessButton>
              <CloseButton   onClick={handleCancel}>Cancelar</CloseButton>
            </>
          )}
          title={"¿Está seguro de modificar este servicio legal?"}
        />  
        <GenericalModal
          show={showEraseServiceModal}
          title={"¿Está seguro de eliminar este servicio legal?"}
          footer={(
            <>
              <RejectButton onClick={()=>{handleEraseLegalService();}}>    Eliminar</RejectButton>
              <CloseButton  onClick={()=>{setShowEraseServiceModal(false);}}>Cancelar</CloseButton>
            </>
          )}
        />     
        <div className="user-show">
          <h1>Servicio legal</h1>
          <Form>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Nombre:
                </Form.Label>
                {isEditing ?
                  <Form.Control as="input"
                    value={currentName}
                    onChange={(e)=> setCurrentName(e.target.value)}
                    sx={{ width: "80%" }}
                    label="Nombre del servicio legal"
                    placeholder="Por favor completar el nombre del servicio legal"
                  />
                  : 
                  <div style={{"marginLeft": "10px"}}>
                    {currentName?? "NA"}
                  </div>
                }
              </Grid>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Descripción:
                </Form.Label>
                {isEditing ?
                  <Form.Control as="textarea"
                    value={currentDescription}
                    onChange={(e)=> setCurrentDescription(e.target.value)}
                    sx={{ width: "80%" }}
                    label="Descripción del servicio legal"
                    placeholder="Por favor complementar la descripción del servicio legal"
                  />
                  : 
                  <div style={{"marginLeft": "10px"}}>
                    {currentDescription?? "NA"}
                  </div>
                }
              </Grid>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Industrias:
                </Form.Label>
                {isEditing ? 
                  <Multiselect 
                    ref={industriesRef}
                    showArrow 
                    options={industries} 
                    displayValue="name" 
                    onRemove={handleMultiSelectIndustries} 
                    onSelect={handleMultiSelectIndustries} 
                    avoidHighlightFirstOption disable={!isEditing}
                    selectedValues={defaultIndustries}/>
                  :                
                  currentIndustries?.map((industry)=>{
                    const goToIndustry = ()=>{navigate(`/industries/${industry.UUid}`);};
                    return (<Button  key={industry.UUid} onClick={goToIndustry}>{industry.name}</Button>);
                  })
                  
                }

              </Grid>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Areas:
                </Form.Label>
                {isEditing ? 
                  <Multiselect 
                    ref={lawAreasRef}
                    showArrow
                    options={lawAreas} 
                    displayValue="area_name" 
                    onRemove={handleMultiSelectLawArea} 
                    onSelect={handleMultiSelectLawArea} 
                    avoidHighlightFirstOption disable={!isEditing}
                    selectedValues={defaultLawAreas}/>
                  :
                  currentLawAreas?.map((lawArea)=>{
                    const goToIndustry = ()=>{navigate(`/law_areas/${lawArea.UUid}`);};
                    return (<Button  key={lawArea.UUid} onClick={goToIndustry}>{lawArea.area_name}</Button>);
                  })    }
              </Grid>
            </Grid>

          </Form>

          {isEditing?
            <>
              <SuccessButton  
                onClick={()=>{
                  setShowConfirmationModal(true);}}
                disabled={!currentName}
              > 
                Guardar Cambios
              </SuccessButton>
              <Button variant="contained"            
                onClick={()=> {
                  handleCancel();}}
              > 
                Volver sin guardar
              </Button>
              <RejectButton onClick={()=>{setShowEraseServiceModal(true);}}> Eliminar servicio Legal</RejectButton>
            </>
            :
            <>
              <CloseButton  
                onClick={()=>{
                  setIsEditing(!isEditing);
                  onSubmit();
                  forceUpdate();}}
                disabled={!currentName}
              > 
              Editar Servicio Legal
              </CloseButton>
              <Button variant="contained" onClick={handleBack}>
                {!isEditing? "Volver" : "Volver sin guardar"}
              </Button>   
            </>
          }

        </div>
        {notification}
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
