import { useState, useEffect  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "../../styles/userShow.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { queryCompanyByUUID } from "./utils";
import { Form } from "react-bootstrap";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import fetchData from "../../utils";
import Notification from "../../components/Notification";

export function CompanyShow() {
  const [company, setCompany] = useState(null);
  const [auxCompany, setAuxCompany] = useState(null); // Para guardar los cambios antes de guardarlos en la base de datos
  const [isEditing, setIsEditing] = useState(false);
  const { UUid } = useParams();
  const [notification, raiseAlert] = Notification("success", "");
  const navigate = useNavigate();
  useEffect(() => {
    queryCompanyByUUID(UUid).then((response) => {
      setCompany(response["data"]);
      setAuxCompany(response["data"]);
    });
  }, [navigate, UUid]);

  const handleBack = () => {
    navigate(-1);
  };
  const handleEdit = () => {
    setIsEditing(!isEditing);
    setAuxCompany(company);
  };

  const onSubmitHandle = (e) => {
    e.preventDefault();
    fetchData(`/user/enterprise/update/${auxCompany.UUid}/`, "PUT", auxCompany)
    .then((response) => {
      setCompany(auxCompany);
      setIsEditing(false)
      response.servrity = "success"
      raiseAlert(response);
    })
    .catch((error) => {
      error.severity = "error"
      raiseAlert(error);
    })
  };
  if (company) {
    return (
      <div>
        
        <div className="user-show">
          <h1> {company.name !== "" ? company.name : "Empresa: Sin información"}</h1>
          <div>
            <Form onSubmit={onSubmitHandle}>
              <div className="user-show-info">
                <b><label>Rut: </label></b>
                {isEditing ? 
                (<Form.Control
                  name="rut"
                  type="text"
                  placeholder="Rut de la empresa"
                  value={auxCompany.rut}
                  onChange={(e)=> setAuxCompany({...auxCompany, rut: e.target.value})}
                />)
                :
                <p>{company.rut !== "" ? company.rut : "Sin información"}</p>  
              }
                
              </div>
              <div className="user-show-info">
                <b><label>Giro: </label></b>
                {isEditing ? 
                  (<Form.Control
                    name="giro"
                    type="text"
                    placeholder="Giro de la empresa"
                    value={auxCompany.giro}
                    onChange={(e)=> setAuxCompany({...auxCompany, giro: e.target.value})}
                  />)
                :
                  <p>{company.giro !== "" ? company.giro : "Sin información"}</p>
                }
              </div>
              <div className="user-show-info">
                <b><label>Razón Social:</label></b>
                {isEditing ? 
                (<Form.Control
                  name="giro"
                  type="text"
                  placeholder="Razón social de la empresa"
                  value={auxCompany.razon_social}
                  onChange={(e)=> setAuxCompany({...auxCompany, razon_social: e.target.value})}
                />)
                :
                <p>{company.razon_social !== ""  && company.razon_social ? company.razon_social : "Sin información"}</p>
                }
              </div>
              {isEditing && (<SuccessButton type="submit">Guardar cambios</SuccessButton>)}
              
            </Form>
          </div>
          <CloseButton onClick={handleEdit}> {!isEditing ? "Editar empresa" : "Volver sin guardar"} </CloseButton>
          <Button color="primary" variant="contained" onClick={handleBack}>
            Volver
          </Button>   
        </div>
        {notification}
      </div>

    );
  }
  return (
    <LoadingView/>
  );
}
