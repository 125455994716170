export const traduction = {
  "home": "Inicio",
  "users": "Usuarios",
  "user": "Usuario",
  "proposals": "Propuestas",
  "profile": "Perfil",
  "lawyers": "Abogados",
  "lawyer": "Abogado",
  "clients": "Clientes",
  "client": "Cliente",
  "natural_person": "Persona Natural",
  "services": "Servicios",
  "service": "Servicio",
  "requests": "Solicitudes",
  "lsa": "RSL",
  "law_areas": "Áreas de Derecho",
  "create": "Crear",
  "restore": "Restaurar",
  "industries": "Industrias",
  "industrie": "Industria",
  "industry": "Industria",
  "commercial_contracts": "Contratos Comerciales",
  "commercial_contract": "Contrato Comercial",
  "projects": "Proyectos",
  "experience": "Experiencia",
  "company": "Empresa",
  "diagnostic_meetings": "Reuniones Diagnóstico",
  "diagnostic_meeting": "Reunión Diagnóstico",
  "jobs": "Trabajos",
  "my_experience": "Mi experiencia",
  "active": "Activo",
  "completed": "Completado",
  "notifications": "Notificaciones",
  "notification": "Notificación",
  "list": "Lista",
  "historical_services": "Servicios Históricos",
};