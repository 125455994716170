import { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/userShow.scss";
import { SuccessButton } from "../Buttons";
import React from "react";


export function EditPassword({ setIsEditingPassword, setIsEditing, raiseAlert }) {

  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [haveLetter, setHaveLetter] = useState(false);
  const [have10Letters, set10Letters] = useState(false);
  const [haveNumber, setHaveNumber] = useState(false);
  const [match, setMatch] = useState(false);
  const [haveCapitalLetter, setHaveCapitalLetter] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [specialCaracter, setSpecialCaracter] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const onChangePassword = async(e) => {
    e.preventDefault();
    const { value } = e.target;
    setNewPassword(value);

    const length = value.length >= 10;
    set10Letters(length);

    const number = value.match(/[0-9]/i);
    setHaveNumber(number);

    const letter = value.match(/[a-z]/i);
    setHaveLetter(letter);

    const capitalLetter = value.match(/[A-Z]/);
    setHaveCapitalLetter(capitalLetter);

    const confirmPassword = !(value.length === 0 || confirmNewPassword.length === 0)
    setConfirm(confirmPassword);

    const specialCaracterNew = value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
    setSpecialCaracter(specialCaracterNew);

    const matchLocal = value === confirmNewPassword && value.length > 0;
    setMatch(matchLocal);

    setIsPasswordValid(letter && length && number && capitalLetter && matchLocal && confirmPassword && oldPassword.length > 0 && specialCaracterNew);

  };
  const onChangeConfirmPassword = async(e) => {
    e.preventDefault();
    const { value } = e.target;
    setConfirmNewPassword(value);

    const matchLocal = value === newPassword && value.length > 0;
    setMatch(matchLocal);

    const confirmLocal = !(value.length === 0 || newPassword.length === 0);
    setConfirm(confirmLocal);

    setIsPasswordValid(haveLetter && have10Letters && haveNumber && haveCapitalLetter && matchLocal && confirmLocal && oldPassword.length > 0 && specialCaracter);

  };
  const onSubmitHandler = async(e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/update_password/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        "old_password": oldPassword,
        "password": newPassword,
        "confirm_password": confirmNewPassword,
      }),
    })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      } else {
        return response.json();
      }
    })
    .then((json) => {
      raiseAlert(json);
      setIsEditingPassword(false);
      setIsEditing(false);
    })
    .catch((error) => {
      if (error.status === 400) {
        raiseAlert(error);

      } else if (error.status === 401) {
        window.localStorage.removeItem("token");
        navigate("/");
      } else if (error.status === 403) {
        navigate("/home")
      }
    });
  };

  const onChangeOldPassword = async(e) => {
    const { value } = e.target;
    setOldPassword(value);
    setIsPasswordValid(haveLetter && have10Letters && haveNumber && haveCapitalLetter && match && confirm && value.length > 0 && specialCaracter);

  };
  return (
        <Form className="edit-password" onSubmit={onSubmitHandler}>
          <h2>Editar contraseña</h2>
          <div className="password-flex">
            <div>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contraseña actual: </Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Contraseña actual"
                    onChange={onChangeOldPassword}
                  />
              </Form.Group>
              <p><b>La contraseña debe cumplir los siguientes requerimientos:</b></p>
              <ul className={"list-password"}>
                <li id="letter" className={haveLetter ? "text-success": "text-danger"}>Al menos <strong>una letra</strong></li>
                <li id="capital" className={haveCapitalLetter ? "text-success": "text-danger"}>Al menos <strong>una letra mayúscula</strong></li>
                <li id="number" className={haveNumber ? "text-success": "text-danger"}>Al menos <strong>un número</strong></li>
                <li id="length" className={have10Letters ? "text-success": "text-danger"}>Al menos <strong>10 carácteres</strong></li>
                <li id="match" className={specialCaracter? "text-success" : "text-danger"}>Al menos <strong>un caracter especial</strong></li>
                <small className={specialCaracter? "text-success" : "text-danger"}>{"!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"}</small>
                <li id="null" className={confirm? "text-success" : "text-danger"}>Debe <strong>confirmar la contraseña</strong></li>
                <li id="match" className={match? "text-success" : "text-danger"}>Las contraseñas <strong>deben coincidir</strong></li>
                <li id="match" className={oldPassword.length > 0? "text-success" : "text-danger"}>Tu antigua contraseña <strong>no puede estar vacía</strong></li>
              </ul>
            </div>
            <div>
              <Form.Group className="mb-3 bigger">
                  <Form.Label>Nueva contraseña: </Form.Label>
                  <Form.Control
                    name="newPassword"
                    type="password"
                    placeholder="Nueva contraseña"
                    value={newPassword}
                    onChange={onChangePassword}
                  />
              </Form.Group>
              <Form.Group className="mb-3">
                  <Form.Label>Confirmar contraseña: </Form.Label>
                  <Form.Control
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirmar contraseña"
                    value={confirmNewPassword}
                    onChange={onChangeConfirmPassword}
                  />
              </Form.Group>
            </div>
          </div>
      <SuccessButton disabled={!isPasswordValid} type="submit"> Actualizar contraseña </SuccessButton>     
    </Form>
  );
}