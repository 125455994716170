import { useState, useEffect  } from "react";
import ClientTable from "../../components/client/userTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { GoBackButton, SuccessButton } from "../../components/Buttons";
import { queryAllClients } from "../kam/utils";
import { CustomPagination, RowsPerPage } from "../../components/Pagination.jsx";
import { Form } from "react-bootstrap";
import { Validate } from "../../utils";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { queryAllClientType } from "./utils";
export function ClientList() {
  
  const [users, setUsers] = useState([]);
  const [usersFillter, setUsersFillter] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const [representativeType, setRepresentativeType] = useState({});
  const [allClientType, setAllClientType] = useState([]);
  useEffect(()=>{
    queryAllClientType().then((response)=>{setAllClientType(response.data);});
  },[]);
  
  useEffect(() => {
    queryAllClients()
      .then((json) => {
        setUsers(json["data"]);
        setUsersFillter(json["data"]);
        setIsLoading(false);
        setAmount(json["data"].length);
      });
  }, []);

  const goToCreateClient = () =>{ 
    let path = "/clients/create"; 
    navigate(path);
  };
  function searchClient(search) {
    return users.filter(({ user, id }) => {
      const fixUsername = Validate.accent_fold(user.auth.username);
      const fixFistName = Validate.accent_fold(user.auth.first_name);
      const fixLastName = Validate.accent_fold(user.auth.last_name);
      return fixUsername.toLowerCase().includes(search.toLowerCase()) ||
      fixFistName.toLowerCase().includes(search.toLowerCase()) ||
      fixLastName.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.email.toLowerCase().includes(search.toLowerCase()) ||
      id.toString().includes(search.toLowerCase());
    });
  }
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = Validate.accent_fold(e.target.value);
    setSearch(search);
    let usersFiltter = searchClient(search).filter((user)=> user.type.name.includes(representativeType?.name ?? ""));
    setUsersFillter(usersFiltter);
    setAmount(usersFiltter.length);
  };

  useEffect(()=>{
    setAmount(usersFillter.length);
  },[usersFillter.length]);

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);

  return (
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
            <>
              Página 
              <CustomPagination
                pageIndex={pageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={setPageIndex}
              />
            </>
        </Grid>  
        <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>        
        <Grid item xs={12} md={3}>
          <SuccessButton fullWidth onClick={goToCreateClient}> Crear Cliente</SuccessButton>
        </Grid>
      </Grid>
      <Autocomplete
        onChange={(event, value) => {
          setRepresentativeType(value??"");
          let usersFiltter = searchClient(search).filter((user)=> user.type.name.includes(value?.name ?? ""));
          setUsersFillter(usersFiltter);
          setAmount(usersFiltter.length);
        }}
        id={1}
        options={allClientType}
        getOptionLabel={(option) => option.name ?? option }
        value = {representativeType?.name ?? ""}
        renderInput={(params) => <TextField {...params} label = {"Tipo de cliente"} />}
      />
      <h1>Clientes</h1>
      {isLoading ? (<LoadingView/>): 
        <>
          <ClientTable users={usersFillter} userType={representativeType?.name}  page_size={pageSize} page_index={pageIndex}/>
          {<>Mostrando {Math.min((pageIndex * pageSize+ 1),amount)} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.</>}
        </>}
      <GoBackButton/>
    </div>
  );
}