import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import logo from "../../img/logo-impacto-legal.jpg";
import engranajes from "../../img/engranajes.jpg"
import FontSansPro from '../../styles/fonts/open-sans-regular.ttf';
import FontSansProItalic from '../../styles/fonts/open-sans-italic.ttf';
import FontSansPro700 from '../../styles/fonts/open-sans-700.ttf';


// Register font
Font.register({
  family: 'Sans Serif',
  fonts: [
    {
      src: FontSansPro,
    },
    {
      src: FontSansProItalic,
      fontStyle: 'italic',

    },
    {
      src: FontSansPro700,
      fontWeight: 'bold'
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    marginBottom: 20,
    marginTop: 20,
    paddingBottom: 65,
    paddingTop: 20
  },
  section: {
    margin: 10,
    padding: 5,
    marginRight: 20,
  },
  subSection: {
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontStyle: 'italic',
    textDecorationLine: 'underline',
    flex: 1,
  },
  subTitle: {
    fontSize: 18,
    textAlign: "left",
    marginBottom: 10,
  },
  subSubTitle: {
    fontSize: 14,
    textAlign: "left",
    marginBottom: 10,
  },
  paragraph: {
    // marginLeft: 10,
    fontFamily: 'Sans Serif',
    fontSize: 10,
    textAlign: "justify",
  },
  subTitleBold: {
    fontFamily: 'Sans Serif',
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 10,
    textDecoration: 'underline'
  },

  image: {
    objectFit: null,
    width: 75,
    height: 75,
    margin: 10,
  },
});



// Create Document Component
function ProposalDocument({ nameProject, descriptionProject, clientFirstName, clientEmail,
                            clientLastName, proposalServices, proposalObjects, allFields, kam }) {
  
  const date = new Date();
  let month = date.getMonth()+1;
  let day = date.getDate();
  if (month < 10) {
    month = `0${month}`
  }
  if (day < 10) {
    day = `0${day}`
  }
  return (
      
      <Document>
        {/* Start of the document*/}
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
        
          <View style={styles.section}>
            <View style={{display: "flex", flexDirection: "row"}}>
              <Image
                style={styles.image}
                src={logo}
              />
              <Text style={styles.title}>Propuesta comercial: {nameProject}</Text>
            </View>
            <Text style={{...styles.paragraph, paddingLeft: 25}}>Fecha: {day}/{month}/{date.getFullYear()}</Text>
            <Text style={{...styles.paragraph, paddingLeft: 25}}>Destinatario: {clientFirstName} {clientLastName}</Text>
            <Text style={{...styles.paragraph, paddingLeft: 25}}>Correo electrónico: {clientEmail}</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.subTitle}>Descripción general de la propuesta</Text>
            <Text style={styles.paragraph}>{descriptionProject}</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.subSubTitle}>Estimado(a) {clientFirstName} {clientLastName}</Text>
            <Text style={styles.paragraph}>Por medio de la presente, queremos agradecer la confianza depositada en <Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text> y la solicitud de una propuesta de trabajo para asesora a vuestra empresa en aquellas materias que más adelante se indican.</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.subTitleBold}>PROPUESTA DE VALOR - IMPACTO LEGAL</Text>
            <Text style={styles.paragraph}><Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text> es una empresa de servicios legales y consultoría legal estratégica la cual ofrece a sus clientes una propuesta de valor integral y de largo plazo llamada “<Text style={{fontWeight: 'bold'}}>El Viaje de Impacto Legal</Text>” la cual consiste en la prestación de servicios legales flexibles y costo eficientes, bajo un modelo operacional tecnologizado y descentralizado en abogados <Text style={{fontStyle: 'italic'}}>on-demand</Text>, modelo que permite a <Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text> y por tanto a nuestros clientes, lograr <Text style={{fontWeight: 'bold'}}>considerables ahorros económicos en servicios legales de alta gama</Text>, así como también entender de mejor manera su data legal y contractual, acelerando su camino hacia la <Text style={{fontWeight: 'bold'}}>transformación digital</Text> de sus procesos legales. Nuestra empresa posee más de <Text style={{fontWeight: 'bold'}}>80 abogados asociados</Text>.</Text>
            <Image
                // style={styles.image}
                src={engranajes}
              />
            <Text style={styles.paragraph}><Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text> es una empresa comprometida con la generación de impacto positivo a la sociedad y al planeta.</Text>
            <Text style={styles.paragraph}>Nuestro modelo flexible nos permite capitalizar recursos humanos de alto valor, subutilizados debido a la inflexibilidad de la industria legal tradicional, siendo <Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text> la empresa de la industria legal con mejores <Text style={{fontWeight: 'bold'}}>índices de equidad de género, meritocracia e inclusividad laboral</Text>.</Text>
            <Text style={styles.paragraph}>Asimismo, nuestro modelo e intenso uso de la tecnología nos permite digitalizar gran parte de la cadena de valor de procesos legales, tanto la nuestra interna, como la de nuestros  clientes, ahorrando transportes, uso de espacios físicos y por tanto contribuyendo tanto a la reducción de emisiones de C02, como a la construcción de <Text style={{fontWeight: 'bold'}}>ciudades sostenibles e inteligentes</Text>.</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.title}>Servicios legales</Text>
          </View>
          
            {proposalObjects.map((service, index) => (
                <View style={styles.subSection} wrap={false}>
                  <Text style={styles.subTitle}>{index+1}. {service.service}</Text>
                  <Text style={{...styles.paragraph, paddingBottom: 10}}><Text style={{fontWeight: 'bold'}}>Alcance del servicio legal:</Text> {service.description}</Text>
                  <Text style={{...styles.paragraph, paddingBottom: 10}}><Text style={{fontWeight: 'bold'}}>Abogado/a encargado/a:</Text> {service.selectedLawyer?.fullname ?? "Aún no definido."}</Text>
                  <Text style={{...styles.paragraph, paddingBottom: 10}}><Text style={{fontWeight: 'bold'}}>Valor UF es de:</Text> {service.price}</Text>
                  <Text style={{...styles.paragraph, paddingBottom: 10}}><Text style={{fontWeight: 'bold'}}>Plazo de entrega:</Text> {service.endDate ?? "Aún no definido."}</Text>
                  <Text style={styles.paragraph}>El pago del honorario estipulado para cada servicio será de un 50% al inicio del cada servicio contratado y 50% contra envío del entregable acordado.</Text>

                </View>
              ))
            }
            <View style={styles.subSection} wrap={false}>
              <Text style={styles.subTitle}>EQUIPO Y EXPERIENCIA</Text>
              <Text style={{...styles.paragraph, paddingBottom: 10}}> La asesoría que se propone será liderada como <Text style={{fontWeight: 'bold'}}>Key Account Manager (KAM)</Text> por {kam.user.auth.first_name} {kam.user.auth.last_name}, quien es ………………</Text>
              <Text style={styles.paragraph}>	Asimismo, estarán disponibles para atender al cliente, los más de 70 abogados Asociados de  <Text style={{fontWeight: 'bold'}}>IMPACTO LEGAL®</Text>.</Text>

            </View>
            {allFields.map((field) => (
                <View style={styles.subSection} wrap={false}>
                  <Text style={styles.subTitle}>{field.field}</Text>
                  <Text style={styles.paragraph}>{field.field_description}</Text>
                </View>
              ))
            }
        </Page>
      </Document>
  );
}
export default ProposalDocument;
