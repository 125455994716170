import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";


function UserTable(props) {
  const { users } = props;
  const { page_size, page_index } = props;
  const navigate = useNavigate();
  return (
    <div className='user-table'>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Nombre de usuario</th>
            <th>Correo</th>
            <th>Rol</th>
            <th>Ver perfil</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            const goToUser = ()=>{navigate(`/users/${user.UUid}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size &&  (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.auth.first_name}</td>
                <td>{user.auth.last_name}</td>
                <td>{user.auth.username}</td>
                <td>{user.auth.email}</td>
                <td>{user.role}</td>
                <td><Button onClick={goToUser}>Ver</Button></td>
              </tr>
            );})}
        </tbody>
      </Table>
    </div>
  );
}

export default UserTable;