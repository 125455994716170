import { useState, useEffect } from "react";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import {Form } from "react-bootstrap";
import { queryAllErasedServices } from "./utils";
import { Grid } from "@mui/material";
import ServiceTable from "../../components/service/serviceTable";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import { GoBackButton } from "../../components/Buttons";

export function RestoreService () {
  const [services, setServices] = useState([]);
  const [servicesFillter, setServicesFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  useEffect(() => {
    queryAllErasedServices().then((response)=>{
      setServices(response.data);
      setServicesFillter(response.data);
      setIsLoading(false);
      setAmount(response.data.length);
    });
  }, []);
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    let servicesFiltter = services.filter(({ service_name, industry, area, id }) => {
      return service_name.toLowerCase().includes(search.toLowerCase()) ||
      industry.filter(({name})=>{
        return (name.toLowerCase().includes(search.toLowerCase()));
      }).length > 0 ||
      area.filter(({area_name})=>{
        return (area_name.toLowerCase().includes(search.toLowerCase()));
      }).length > 0 ||
      id.toString().includes(search.toLowerCase());
    });
    setServicesFillter(servicesFiltter);
    setAmount(servicesFiltter.length);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);
  
  return (
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
      </Grid>

      <h1>Servicios legales</h1>

      {isLoading? (<LoadingView/>): 
        <>
          <ServiceTable services={servicesFillter} page_size={pageSize} page_index={pageIndex} type={"Restore"}/>
    Mostrando {amount ?? pageIndex * pageSize+ 1} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
  );
}