import { useState, useEffect  } from "react";
import { useNavigate}  from "react-router-dom";
import "../../styles/userShow.scss";
import Form from "react-bootstrap/Form";
import {React} from "react";
import PropTypes from "prop-types";
import {LoadingView} from "../../views/LoadingView";
import { queryAllRoles } from "./utils";
export function EditRole({ currentRoleName, setCurrentRole, setCurrentRoleName }) {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    queryAllRoles()
      .then((role_json) => {
        setRoles(role_json["data"]);
      })
      .catch((error) => {
        if (error.message === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        }
      }
      );
  }, [navigate]);

  
  const handleChangeRole = async(event) => {
    await setCurrentRole(event.target.value);
    await setCurrentRoleName(event.target.options[event.target.selectedIndex].text);
  };
  if (roles) {
    return (
      <div>
        <Form.Select aria-label="Default select example" onChange={handleChangeRole}>
          {roles.map(({id, rol_name}) => (
            <option value={id} key={id} selected={"selected" ? currentRoleName === rol_name : ""}>{rol_name}</option>
          ))}
        </Form.Select>
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
EditRole.propTypes = {
  currentRoleName: PropTypes.string.isRequired,
  setCurrentRole: PropTypes.number.isRequired,
  setCurrentRoleName: PropTypes.string.isRequired,
};