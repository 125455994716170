import { useState, useEffect  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/userShow.scss";
import {Button, Grid } from "@mui/material";
import { LoadingView } from "../LoadingView";
import React from "react";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import { Col, Row, Form } from "react-bootstrap";
import { getPendingServiceByID, getPreviousJobExperienceByPendingServiceID, queryAcceptService, queryAllServices } from "./utils";
import {ComboBox,} from "../../components/users/multiSelect";
import Stack from "@mui/material/Stack";
import { LawyerExperienceForm } from "../../components/users/descriptionPreviosJob"
  
export function PendingServiceShow() {
  const { UUid } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [currentName, setCurrentName] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [services,setServices] = useState([]);
  
  const [pendingServiceID, setPendingServiceID] = useState("");
  const [currentServiceSelected, setCurrentServiceSelected] = useState(null);
  const [previousExperience, setExperienceData] = useState([]);

  const onSave = ()=>{
    queryAcceptService(currentServiceSelected,pendingServiceID);
    navigate(-1);
  };


  useEffect(() => {
    getPendingServiceByID(UUid)
      .then((json) => {
        setCurrentName(json["data"].pending_service_name);
        setCurrentDescription(json["data"].pending_service_description);
        setPendingServiceID(json["data"].id);
      });

    getPreviousJobExperienceByPendingServiceID(UUid).then((response)=>{
      setExperienceData(response.data);
    });

    queryAllServices().then((response)=>{
      setServices(response.data);
    });
  }, [navigate, UUid]);
  const expertiseLevelToString = {
    1: "1-10",
    2: "11-50",
    3: "51-200",
    4: "200+",
  };

  const handleExpertiseLevel = (level)=>{
    return expertiseLevelToString[level];
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(()=>{
    if (currentName && previousExperience.job_descriptions !== undefined){  
      setIsLoading(false);
    }
  }, [currentName, previousExperience]);
  if (!isLoading) {
    return (
      <div>
        <div className="user-show">
          <h1>Solicitud de RSL</h1>
          <Form>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Nombre:
                </Form.Label>
                {currentName}
              </Grid>
              <Grid item xs={12} md={12}>
                <Form.Label>
                  Descripción:
                </Form.Label>
                {currentDescription}
              </Grid>
            </Grid>
          </Form>
          <Grid container> 
            <Grid item xs={12} md={10}>
              { <h3>
                {previousExperience.serviceName ? previousExperience.serviceName : previousExperience.pendingServiceName}
                {previousExperience.serviceName && previousExperience.pendingServiceName!== "" ? " (Homologado de '" + previousExperience.pendingServiceName + "')": ""}
              </h3>}
            </Grid>
            <Grid item  xs={12} md={1}>


            </Grid>


          </Grid>
        </div>
        <div className="user-show">
          <h1>Experiencia previa relacionada</h1>
          <Form >
            <div className="user-show-info">
              <b>
                <label>
                        Cantidad de experiencias:
                </label>
              </b>
              <p>
                {handleExpertiseLevel(previousExperience.quantity)}
              </p>

            </div>
            {previousExperience.job_descriptions.length > 0 ? (
              <Stack spacing={1} className="previous-jobs-detail">
                {previousExperience.job_descriptions.map((job, index_2) => {
                  return (
                    <LawyerExperienceForm previosExperienceDescription={job} index={index_2}
                    UUid={previousExperience.UUid} showEditOptions={false}/>
                  );
                  
                })}
              </Stack>
            ) : (
              <div className="user-show-info">
                <b>
                  <label>
                    El abogado no agregó ningún detalle de alguna experiencia previa
                  </label>
                </b>
              </div>
            )}
                  
          </Form>
        </div>

        <div className="user-show">
          <h1>Homologar servicio legal</h1>
          <ComboBox
            setCurrentOption={setCurrentServiceSelected}
            value={currentServiceSelected}
            userID = "combo-box-demo"
            options={services.map((service) => service.service_name)}
            category="Servicios Legales"
          >
          </ComboBox>
          <SuccessButton  
            onClick={()=>{
              onSave();
              forceUpdate();}}
          > 
                Homologar
          </SuccessButton>
        </div>
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
