import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsInfoCircle } from "react-icons/bs";
import { FormHelperText, Grid, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import fetchData from "../../utils";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f8f9fd",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


export function LawyerExperienceForm({ previosExperienceDescription, index, UUid, raiseAlert, showEditOptions }) {

  const [newJobObject, setNewJobObject] = useState(previosExperienceDescription);
  const [collapsed, setCollapsed] = useState(false);
  const MAX_DESCRIPTION_LENGTH = 500;

  const handleEdit = () => {
    setNewJobObject({...newJobObject, isEditing: true});
  };

  const handleSave = () => {
    const body = {
      UUid: UUid,
      data: {
        ...newJobObject
      }
    }
    const method = newJobObject.firstEdit? "POST": "PUT";
    fetchData("/project/previous_job_description/create", method, body)
    .then((res) => {
      const { data } = res;
      setNewJobObject({...newJobObject, isEditing: false, firstEdit: false,
        id: data.id, UUid: data.UUid});
      let alert = {"severity": "success", "message": res.message };
      raiseAlert(alert);
    })
    .catch((err) => {
      console.log(err);
      let alert = {"severity": "error", "message": err.message };
      raiseAlert(alert);
    });
    
  };

  return (
    <Item>
      <Grid container> 
        <Grid item xs={12} md={10}>
          <h3>
            Experiencia N° {index + 1}
          </h3>
        </Grid>
        <Grid item  xs={12} md={1}>


        </Grid>

        <Grid item  xs={12} md={1}>

          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setCollapsed(!collapsed) }>
              {collapsed? (
                <ExpandLessIcon></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon></ExpandMoreIcon>
              )}
            </Button>
          </span>
        </Grid>
      </Grid>
    
      <Collapse in={collapsed} timeout="auto"  >
                        
                        
        <Form>
          <Form.Label className = "form-label" >
            Descripción de los trabajos realizados (*) 
            <Tooltip title={<Typography fontSize={20}>Describa las experiencias más relevantes que haya realizado de este servicio legal</Typography>} placement="right">
              <span style={{marginLeft: 0}}> <BsInfoCircle/> </span>
            </Tooltip>
          </Form.Label>
          <Form.Control 
            id="description"
            label="Descripción (*)" 
            as="textarea" 
            rows="3" 
            disabled={!newJobObject.isEditing}
            value={newJobObject.description}
            onChange={(e)=> setNewJobObject({...newJobObject, description: e.target.value})}
            isInvalid={newJobObject.description.length>MAX_DESCRIPTION_LENGTH}
            placeholder="Por favor complementar con información adicional relevante"/>
          <FormHelperText  ext error={newJobObject.description.length>MAX_DESCRIPTION_LENGTH}>{newJobObject.description.length}/{MAX_DESCRIPTION_LENGTH}</FormHelperText>
          <Form.Group  as={Row} className="mb-4" >
            <Form.Label className = "form-label"   >Cuantía (UF)</Form.Label>
            <Col>
              <Form.Control type="number"
                value ={newJobObject.quantity??0}
                onChange={(e)=> setNewJobObject({...newJobObject, quantity: e.target.value})}
                disabled={!newJobObject.isEditing}
                id="cuantia"
                sx={{ width: "30%" }}
                label="Cuantía"
                placeholder="Por favor indique la cuantía aproximada de los casos"
              />
            </Col>
          </Form.Group>
          <Form.Group  as={Row} className="mb-4 ">
            <Form.Label className = "form-label"  > Fechas aproximadas de trabajo</Form.Label>
            <Col>
                Inicio
              <Form.Control type="date"id="start_date" 
                value={newJobObject.start_date?.split("T")[0]??""} 
                onChange={(e)=> setNewJobObject({...newJobObject, start_date: e.target.value})}
                disabled={!newJobObject.isEditing}/>
            </Col>
            <Col>
              Término
              <Form.Control type="date" id="end_date" 
                value={newJobObject.end_date?.split("T")[0]??""} 
                onChange={(e)=> setNewJobObject({...newJobObject, end_date: e.target.value})}
                disabled={!newJobObject.isEditing}/>           
            </Col>
          </Form.Group>


          <Form.Group as={Row}  className='mb-4'>
            <Form.Label className = "form-label"> Información del cliente</Form.Label>
            <Col>
            Nombre
              <Form.Control 
                value ={newJobObject.clientName?? ""}
                onChange={(e)=> setNewJobObject({...newJobObject, clientName: e.target.value})}
                type="text"
                disabled={!newJobObject.isEditing}
                id="clientName"
                label="Cliente"
                placeholder="Por favor indique el nombre del cliente"
              />
            </Col>
            <Col>
            Industria
              <Form.Control type="text"
                value = {newJobObject.industryArea ?? ""}
                onChange={(e) => setNewJobObject({...newJobObject, industryArea: e.target.value})}
                

                disabled={!newJobObject.isEditing}
                id="industryArea"
                label="Industria"
                placeholder="Por favor indique la industria del cliente"
              />         
            </Col>
          </Form.Group>
          {showEditOptions && (
            newJobObject.isEditing ?
              <Button color="success" variant="contained" onClick={handleSave} disabled={newJobObject.description.length>MAX_DESCRIPTION_LENGTH}>
                { newJobObject.firstEdit ? "Crear nuevo trabajo": "Guardar cambios"}
              </Button> :
              <Button color="success" variant="contained" onClick={handleEdit}>
                Editar
              </Button>
          )}
          
          
        </Form>
      </Collapse>
    </Item>
  )
}