import fetchData from "../../utils";


export async function getJobsByID(jobID) {
  const url = `/project/getJobsByID/${jobID}`;
  const method = "GET";
  const body = null;
  return fetchData(url, method, body);
}

export async function queryCompanyByUUID(UUid){
  const url = `/user/queryCompanyByUUID/${UUid}/`;
  const method = "GET";
  const body = null;
  return fetchData(url, method, body);
}