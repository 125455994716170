export class lawyerData{
  constructor(key, score, workload, username, fullname, email, userUUID, suggested_payment, overWork){
    this.key = key ?? null;
    this.score = score ?? null;
    this.workload = workload ?? null;
    this.username = username ?? null;
    this.fullname = fullname ?? null;
    this.email = email ?? null;
    this.userUUID = userUUID ?? null;
    this.suggested_payment = suggested_payment ?? null;
    this.overWork = overWork ?? null;
  }
}
