import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const SuccessButton = styled(Button)(() => ({
  backgroundColor:"#6ca437",
  margin:"5px",
  color: "white", 
  padding:"6px 16px",
  ":hover": { 
    backgroundColor: "#58862d",},
  ":disabled": {
    color:"#CCCCCC",
  },
}));

const CloseButton = styled(Button)(() => ({
  color: "black", 
  margin:"5px",
  padding:"6px 16px",
  backgroundColor: "#DDDDDD", 
  ":hover": { 
    backgroundColor: "#CCCCCC",} 
}));
  
const RejectButton = styled(Button)(() => ({
  color: "white", 
  margin:"5px",
  padding:"6px 16px",
  backgroundColor: "#c34f4b", 
  ":hover": { 
    backgroundColor: "#a33b37",},
  ":disabled": {
    color:"#CCCCCC",
  },
}));
  
function GoBackButton(){
  const navigate = useNavigate();
  return (
    <div>
      <SuccessButton onClick={()=>{navigate(-1);}}>Volver</SuccessButton>
    </div>);
}

function ButtonStateComponent(logicMode, setLogicMode, State1, State2) {
  const handleButtonClick = (mode) => {
    setLogicMode(mode === logicMode ? null : mode);
  };
  return (
    <div>
      <Button
        variant={logicMode === State1 ? 'contained' : 'outlined'}
        onClick={() => {if (logicMode !== State1){handleButtonClick(State1)}}}
      >
        {State1}
      </Button>
      <Button
        variant={logicMode === State2 ? 'contained' : 'outlined'}
        onClick={() => {if (logicMode !== State2){handleButtonClick(State2)}}}
      >
        {State2}
      </Button>
    </div>
  );
}

export {SuccessButton, CloseButton, RejectButton, GoBackButton, ButtonStateComponent};
