import { useState, useEffect, useRef  } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/userShow.scss";
import { Grid } from "@mui/material";
import { LoadingView } from "../LoadingView";
import React from "react";
import { Form } from "react-bootstrap";
import Notification from "../../components/Notification";
import { queryAllServices } from "../service/utils";
import { queryHistoricalDataByHistoricalService, queryHomologateHistoricalService } from "./utils";
import { ComboBox } from "../../components/users/multiSelect";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { set } from "immutable";
import { SuccessButton } from "../../components/Buttons";

export function HistoricalDataShow() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [allServices, setAllServices] = useState([]);
  const [historicalServices, setHistoricalServices] = useState(false);
  const [currentServiceSelected, setCurrentServiceSelected] = useState(null);
  const [collapseArray, setCollapseArray] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [notification, raiseAlert] = Notification("success", "");


  useEffect(()=>{queryHistoricalDataByHistoricalService
    (id).then(
      (response)=>{
        setHistoricalServices(response.data);
        queryAllServices().then((services)=>{
          setAllServices(services.data);
          services.data.forEach(service => {
            if (service.id === response.data[0].service) {
              setCurrentServiceSelected({
                "name": service.service_name, 
                "UUid": service.UUid, 
                "ID": service.id, 
                "suggested_price": service.price
              });
            }
          });
        })
        setCollapseArray(new Array(response.data.length).fill(false));
      }
    ).then(()=>{setIsLoading(false);})
  },[id]);

  const onSubmit = ()=>{
    queryHomologateHistoricalService(id,currentServiceSelected.UUid).then(
      (response)=>{
        raiseAlert(response);
      }
    )
  };



  const handleBack = () => {
    navigate(-1);
  };

  if (!isLoading) {
    return (
      <div>
        <div className="user-show">
          <h1>Servicio Legal Histórico</h1>
          <Form>
          <Form.Group className="mb-3">
              <div className="user-show-info">
                <b><label>Nombre: </label></b>
                {historicalServices && historicalServices[0].nombreServicioLegal}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
            <b><label>Servicio Equivalente: </label></b>
            <ComboBox
            setCurrentOption={setCurrentServiceSelected}
            value = {currentServiceSelected??""}
            id = "combo-box-demo"
            options={allServices.map((service) => {return {"name": service.service_name, "UUid": service.UUid, "ID": service.id, "suggested_price": service.price};})}
            />       
            </Form.Group>  
            <SuccessButton 
            onClick={onSubmit}
            disabled={currentServiceSelected===null || currentServiceSelected=== "" | currentServiceSelected?.name === " "}
            > 
            Homologar Servicio Histórico
            </SuccessButton>
          </Form>
        </div>
        <div className="user-show">
        {historicalServices && historicalServices.map((element,index) => {
              return <div key={"historical-data-"+index}>
              <Grid container marginY={"10px"}>
              <Grid item md={10} xs={10}>
                <h1> Servicio Histórico #{index}</h1>
                </Grid>
                <Grid item md={2} xs={2}>
                <Button onClick={() => {
                  let aux_collapseArray = collapseArray;
                  aux_collapseArray[index] = !collapseArray[index];
                  setCollapseArray(aux_collapseArray);
                  forceUpdate();
                  }}>
                  {collapseArray[index]? (
                    <ExpandLessIcon></ExpandLessIcon>
                  ) : (
                    <ExpandMoreIcon></ExpandMoreIcon>
                  )}
                </Button>
                  </Grid>
                </Grid>
                <Collapse in={collapseArray[index]} timeout="auto"  >
                    <Form.Group className="mb-3">
                      <div className="user-show-info">
                        <b><label>Descripción: </label></b>
                        {element?.description}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                    <div className="user-show-info">
                    <b><label>Fecha: </label></b>
                      {element?.date}
                    </div>
                    </Form.Group>  
                    <Form.Group className="mb-3">
                    <div className="user-show-info">
                    <b><label>Precio (UF): </label></b>
                      {element?.precio}
                    </div>
                    </Form.Group>  
                    
                    <Form.Group className="mb-3">
                    </Form.Group>
                  </Collapse>

              </div>
            })
          }
        </div>
        {notification}
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
