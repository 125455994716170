import fetchData from "../../utils";

export async function queryCreateLawArea(lawAreaName){
  const url = "/project/queryCreateLawArea";
  const method= "POST";
  const body = {
    "lawAreaName": lawAreaName?? "",
  };
  return await fetchData(url,method,body);
}

export async function queryDeleteLawArea(UUid){
  const url = "/project/queryDeleteLawArea";
  const method ="POST";
  const body = {"lawAreaID" : UUid};
  return await fetchData(url,method,body);
}

export async function queryUpdateLawArea(UUid,lawAreaName){
  const url = "/project/queryUpdateLawArea";
  const method ="POST";
  const body = {
    "lawAreaID" : UUid,
    "lawAreaName": lawAreaName};
  return await fetchData(url,method,body);
}


export async function queryRestoreLawArea(id){
  const url = "/project/queryRestoreLawArea";
  const method ="POST";
  const body = {"lawAreaID" : id};
  return await fetchData(url,method,body);
}


export async function queryAllErasedLawAreas() {
  const url = "/project/queryAllErasedLawAreas";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}