import Carousel from 'react-bootstrap/Carousel';

function ImageCarousel({ imgs }) {
  return (
    <Carousel>
      {imgs.map((img, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={img.src}
            alt={img.firstLabel}
          />
          <Carousel.Caption>
            <h3>{img.firstLabel}</h3>
            <p>{img.secondLabel}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ImageCarousel;