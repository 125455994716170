import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/userShow.scss";
import { EditPassword } from "../../components/users/editPassword";
import { UserData } from "../../components/users/userData";
import Notification from "../../components/Notification";
import { Button } from "@mui/material";
import {React} from "react";
import { LoadingView } from "../LoadingView";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import {queryUserByID} from "./utils.js";
import fetchData from "../../utils";
import { GenericalModal } from "../../views/kam/utilsComponent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import EmailIcon from '@mui/icons-material/Email';
import { CircularProgress } from "@mui/material";


export function UserShow({setUpdateSidebar}) {
  const [notification, raiseAlert] = Notification("success", "");

  const [openMessage, setOpenMessage] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severity, setSeverity] = useState("success");
  const [user, setUser] = useState(null);
  const [lawyer, setLawyer] = useState(null);
  const [kam, setKam] = useState(null);
  const { UUid } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRole, setCurrentRole] = useState(0);
  const [currentRoleName, setCurrentRoleName] = useState(null);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    queryUserByID(UUid)
      .then((json) => {
        setUser(json["data"]);
        setIsActiveUser(json["data"]["auth"].is_active);
        setLawyer(json["lawyer"] ?? null);
        setCurrentUser(json["current_user"]);
        setCurrentRoleName(json["data"].role);
        setKam(json["kam"] ?? null);
      });
  }, [navigate, UUid]);

  useEffect(() => {
    fetchData("/country/all/", "GET")
    .then((response) => {
      setCountries(response.data);
    })
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  const handleEdit = () => {
    setIsEditing(true);
  };
  
  const handleEditBack = () => {
    setIsEditing(false);
    setIsEditingPassword(false);
  };

  const handleEditPassword = () => {
    setIsEditing(true);
    setIsEditingPassword(true);
  }; 

  const onSubmitHandler = () => {
    fetchData(`/user/deactivate_user/${user.UUid}/`, "PATCH")
    .then((json) => {
      raiseAlert(json);
      setIsActiveUser(!isActiveUser);
    })
    .catch((error) => {
      raiseAlert(error);
    });
  };

  const handleEmailVerification = () => {
    setLoadingSendEmail(true);
    const newUser = {
        id: user.auth.id,
        first_name: user.auth.first_name,
        last_name: user.auth.last_name,
        email: user.auth.email,
    };
    fetchData("/mailer/send_validation/", "POST", {user: newUser})
    .then((json) => {
      let alert  = {"severity": "success", "message": ["Se ha enviado un mail de validación al nuevo email."] };
      raiseAlert(alert);
      })
      .then(() => {
        setLoadingSendEmail(false);
      })
  };

  if (user && countries) {
    const isAdmin = currentUser.role === "Admin";
    const userAdmin = user.role === "Admin";
    const canEdit = isAdmin || user.id === currentUser.id;
    const canEditPass = user.id === currentUser.id;
    const modalText = isActiveUser ? "¿Está seguro que desea desactivar este usuario? Esto ocacionará que el usuario no pueda ingresar a la aplicación." : "¿Está seguro que desea activar este usuario? Esto ocacionará que el usuario pueda ingresar a la aplicación.";
    return (
      <div>
        <GenericalModal
        show={showConfirmationModal}
        title = {"¿Está seguro de editar el estado abogado?"}
        body={modalText}
        footer=
          {<>
            <SuccessButton type="submit" onClick={()=>{
              onSubmitHandler();
              setShowConfirmationModal(false);
            }}>  Confirmar </SuccessButton>
            <Button variant="danger" onClick={()=>{setShowConfirmationModal(false);}}>Cancelar</Button>
          </>}
      />
        <div className="user-show">
          <h1>Perfil de {user.auth.first_name}</h1>
          {isEditingPassword ? (
            <EditPassword setIsEditing={setIsEditing} setIsEditingPassword={setIsEditingPassword} raiseAlert={raiseAlert} />
          ) : (
            <div>
              <UserData user={user} isEditing={isEditing} isAdmin={isAdmin} userAdmin={userAdmin} currentRole={currentRole}
                currentRoleName={currentRoleName} setCurrentRole={setCurrentRole} setCurrentRoleName={setCurrentRoleName} userUUid={UUid}
                setUser={setUser} setIsEditing={setIsEditing} raiseAlert={raiseAlert} kam={kam} setKam={setKam}
                lawyer={lawyer??null} setLawyer={setLawyer} isActiveUser={isActiveUser} canEdit={canEdit} countries={countries}
                setUpdateSidebar={setUpdateSidebar}/>
            </div>
          )}
          <div className="user-show-buttons">
            {!isEditing ? (
              <Button variant="contained"   onClick={handleBack}>
                Volver
              </Button>
            ) : (
              <Button 
                variant="contained" 
                onClick={handleEditBack}>
                Volver sin guardar
              </Button>
            )}
            {canEdit && !isEditing && (
              <CloseButton onClick={handleEdit}> Editar Perfil </CloseButton>
            )}
            {canEditPass && !isEditing && (
              <SuccessButton onClick={handleEditPassword}> Cambiar Contraseña </SuccessButton>
            )}
            {(isAdmin && !userAdmin) && (
              <Button variant="contained" color="error" onClick={() => {setShowConfirmationModal(true)}}>
                {isActiveUser ? "Desactivar perfil" : "Activar perfil"}
              </Button>)
              }
            {canEdit && !user.is_email_verified && !isEditing && 
              <>
                <Tooltip 
                  title={
                      <Typography fontSize={20}>
                        El link del mail expira en 10 minutos.
                      </Typography>
                    }
                  placement="right">
                  
                    <Button variant="contained" color="secondary" onClick={handleEmailVerification}
                    disabled={loadingSendEmail}>
                      Reenviar mail de confirmación 
                      {!loadingSendEmail ? 
                        <EmailIcon/> : <CircularProgress size={20} color="inherit" />
                      }
                    </Button>
                
                </Tooltip>
              </>
            }
          </div>
        </div>
        {notification}
      </div>
    );
  }
  
  return (
    <div style={{marginTop:"16%"}}>
      <LoadingView />
    </div>
    
  );
}
