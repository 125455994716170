import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";

function HistoricalServicesTable(props) {
  const navigate = useNavigate();
  const { historicalServices } = props;
  const { page_size,page_index } = props;
  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Servicio Legal Histórico</th>
            <th>Servicio Legal Equivalente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {historicalServices.map(({ servicioLegalFragmentado, service, nombreServicioLegal}, index) => {
            const goToHistoricalService = ()=>{navigate(`/historical_services/${servicioLegalFragmentado}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={servicioLegalFragmentado}>
                <td>{servicioLegalFragmentado}</td>
                <td>{nombreServicioLegal}</td>
                <td>{service?.service_name}</td>
                <td>
                    <Button fullWidth onClick={goToHistoricalService}>Ver</Button>
                </td>
              </tr>
            ) ;})}
        </tbody>
      </Table>
    </div>
  );
}

export default HistoricalServicesTable;