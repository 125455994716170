import Table from "react-bootstrap/Table";
import "../../styles/userTable.scss";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

UserTable.propTypes = {
  lawyers: PropTypes.arrayOf(PropTypes.object).isRequired,
  userType: PropTypes.string.isRequired};

export function UserTable(props) {
  const { lawyers, userType } = props;
  const { page_size, page_index } = props;
  const navigate = useNavigate();
  return (
    <div className='user-table'>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Nombre de usuario</th>
            <th>Correo</th>
            <th>Puntaje</th>
            
            {userType === "Admin"?
              (
              <>
                <th>Estado</th>
                <th>Ver perfil</th> 
              </>
              ):               
              <th>Ver experiencia</th>                
            }
          </tr>
        </thead>
        <tbody>
          {lawyers.map(({ user, score, id, is_accepted }, index) => {
            const goToExperience = ()=>{navigate(`/lawyers/experience/${user.UUid}`);};
            const goToUser = ()=>{navigate(`/users/${user.UUid}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={id}>
                <td>{ index+1 }</td>
                <td>{user.auth.first_name}</td>
                <td>{user.auth.last_name}</td>
                <td>{user.auth.username}</td>
                <td>{user.auth.email}</td>
                <td>{score}</td>
                {userType === "Admin"?
                  <>
                    <td>{is_accepted}</td>
                    <td><Button onClick={goToUser}>Ver</Button></td>
                  </>:
                  <td><Button onClick={goToExperience}>Experiencia</Button></td>
                }
              </tr>
            );})}
        </tbody>
      </Table>
    </div>
  );
}

export default UserTable;