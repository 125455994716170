import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {Login} from "./views/auth/login";
import { UserShow } from "./views/users/show";
import { UserList } from "./views/users/index";
import { Notification } from "./views/notification/show"
import { Notifications } from "./views/notification/index"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./styles/index.scss";
import { NavBar } from "./components/navBar";
import Footer from "./components/footer";
import { useState } from "react";
import { LawyerList } from "./views/lawyer/index";
import { ClientList } from "./views/client/index";
import { IndustryShow } from "./views/industry/show";
import { CompanyShow } from "./views/company/show";
import { ServicesList } from "./views/service/index";
import { ServiceShow } from "./views/service/show";
import { Home } from "./views/auth/home";
import { Inicio } from "./views/auth/inicio";
import { Register } from "./views/auth/registration";
import { Proposals } from "./views/kam/proposals";
import { ProjectShow } from "./views/kam/project";
import { LawyerProposalJobs } from "./views/lawyer/lawyerProposalJobs";
import { UserExperience } from "./views/lawyer/previousExperience";
import {ShowUserExperience} from "./views/lawyer/showPreviousExperience";
import { ForgotPassword } from "./views/auth/forgotPassword";
import { RecoveryPassword } from "./views/auth/recoveryPassword";

import React from "react";
import { LawyerActiveJobs } from "./views/lawyer/laywerActiveJobs";
import { LawyerCompletedJobs } from "./views/lawyer/lawyerCompletedJobs";
import { CreateService } from "./views/service/create";
import { LawAreaShow } from "./views/law_area/show";
import { CreateClient } from "./views/client/create";
import { ServicesProposalList } from "./views/service/proposals";
import { PendingServiceShow } from "./views/service/pendingshow";
import { KAMList } from "./views/kam";
import { CreateKAM } from "./views/kam/create";
import { NaturalPersonShow } from "./views/natural_person/show";

import { LawAreaList } from "./views/law_area";
import { Diagnostic } from "./views/kam/diagnostic"
import { DiagnosticMeeting } from "./views/kam/diagnosticMeeting";

import { CreateLawArea } from "./views/law_area/create";
import { IndustryList } from "./views/industry";
import { CreateIndustry } from "./views/industry/create";
import SideBar from "./components/SideBar";
import { RestoreService } from "./views/service/restore";
import { RestoreLawArea } from "./views/law_area/restore";
import { RestoreIndustry } from "./views/industry/restore";

import { Validate } from "./views/auth/validate";
import { ActiveProjects } from "./views/kam/ActiveProjects";
import { CompletedProjects } from "./views/kam/CompletedProjects";
import { HistoricalData } from "./views/historical";
import { HistoricalDataShow } from "./views/historical/show";

import { CountryIndex } from "./views/country/countryIndex";


const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return children;
};

function App() {

  const [isLogged, setIsLogged] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  const [notificationAmount, setNotificationAmount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [updateSiderbar, setUpdateSidebar] = useState(false);
  return (

  <BrowserRouter>
    <NavBar isLogged={isLogged} setSidebar={setSidebar} sidebar={sidebar} setIsLogged={setIsLogged}
    notificationAmount={notificationAmount} setNotificationAmount={setNotificationAmount} notificationList={notificationList}
    setNotificationList={setNotificationList}/>
    {isLogged &&
      (<SideBar sidebar={sidebar} setSidebar={setSidebar} updateSiderbar={updateSiderbar}></SideBar>)}
    <div className={sidebar && isLogged ? "page-container active" : "page-container"}>
      <Routes>
        {/* Common Routes*/}
        <Route path="/" element={<Inicio setIsLogged={setIsLogged}/>} />
        <Route path="/register/:type" element={<Register setIsLogged={setIsLogged}/>} />
        <Route path="/login" element={<Login setIsLogged={setIsLogged}/>} />
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="validate/:token" element={<Validate/>}></Route>
        <Route path="forgot_password/" element={<ForgotPassword/>}></Route>
        <Route path="recovery_password/:token" element={<RecoveryPassword/>}></Route>
        <Route path="/notifications/:UUid" element={<ProtectedRoute><Notification/></ProtectedRoute>} />
        <Route path="/notifications" element={<ProtectedRoute><Notifications setNotificationAmount={setNotificationAmount} setNotificationNavList={setNotificationList}/></ProtectedRoute>} />
        {/* Admin Routes*/}
        <Route path="/requests/lsa/:UUid" element={<ProtectedRoute><PendingServiceShow/></ProtectedRoute>} />
        <Route path="/kams" element={<ProtectedRoute><KAMList /></ProtectedRoute>} />
        <Route path="/lawyers" element={<ProtectedRoute><LawyerList/></ProtectedRoute>} />
        <Route path="/clients/list" element={<ProtectedRoute><ClientList/></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><UserList /></ProtectedRoute> } />
        <Route path="/kams/create" element={<ProtectedRoute><CreateKAM /></ProtectedRoute>} />
        <Route path="/services/create" element={<ProtectedRoute><CreateService/></ProtectedRoute> } />
        <Route path="/services/restore" element={<ProtectedRoute><RestoreService/></ProtectedRoute> } />
        <Route path="/clients/create" element={<ProtectedRoute><CreateClient/></ProtectedRoute> } />
        <Route path="/law_areas" element={<ProtectedRoute><LawAreaList/></ProtectedRoute> } />
        <Route path="/law_areas/create" element={<ProtectedRoute><CreateLawArea/></ProtectedRoute> } />
        <Route path="/law_areas/restore" element={<ProtectedRoute><RestoreLawArea/></ProtectedRoute> } />
        <Route path="/industries" element={<ProtectedRoute><IndustryList/></ProtectedRoute> } />
        <Route path="/industries/create" element={<ProtectedRoute><CreateIndustry/></ProtectedRoute> } />
        <Route path="/industries/restore" element={<ProtectedRoute><RestoreIndustry/></ProtectedRoute> } />
        <Route path="/historical_services" element={<ProtectedRoute><HistoricalData/></ProtectedRoute> } />
        <Route path="/historical_services/:id" element={<ProtectedRoute><HistoricalDataShow/></ProtectedRoute> } />
        <Route path="/requests/lsa" element={<ProtectedRoute><ServicesProposalList/></ProtectedRoute> } />
        <Route path="/countries" element={<ProtectedRoute><CountryIndex/></ProtectedRoute> } />
        {/* Lawyer Routes*/}
        <Route path="/users/:UUid" element={<ProtectedRoute><UserShow setUpdateSidebar={setUpdateSidebar}/></ProtectedRoute>} />
        <Route path="/industries/:UUid" element={<ProtectedRoute><IndustryShow/></ProtectedRoute>} />
        <Route path="/company/:UUid" element={<ProtectedRoute><CompanyShow/></ProtectedRoute>} />
        <Route path="/natural_person/:UUid" element={<ProtectedRoute><NaturalPersonShow/></ProtectedRoute>} />
        <Route path="/law_areas/:UUid" element={<ProtectedRoute><LawAreaShow/></ProtectedRoute>} />
        <Route path="/services/" element={<ProtectedRoute><ServicesList/></ProtectedRoute>} />
        <Route path="/services/:UUid" element={<ProtectedRoute><ServiceShow/></ProtectedRoute>} />
        <Route path="/propuestas/" element={<ProtectedRoute><LawyerProposalJobs/></ProtectedRoute>} />
        <Route path="/my_experience" element={<ProtectedRoute><UserExperience/></ProtectedRoute>} />
        <Route path="/lawyers/experience/:userID" element={<ProtectedRoute><ShowUserExperience/></ProtectedRoute>} />
        <Route path="/jobs/active" element={<ProtectedRoute><LawyerActiveJobs/></ProtectedRoute>} />
        <Route path="/jobs/completed" element={<ProtectedRoute><LawyerCompletedJobs/></ProtectedRoute>} />
        {/* KAM Routes*/}
        <Route path="/proposals/commercial_contracts" element={<ProtectedRoute><Proposals/></ProtectedRoute>} />
        <Route path="/projects/active" element={<ProtectedRoute><ActiveProjects/></ProtectedRoute>}/>
        <Route path="projects/completed" element={<ProtectedRoute><CompletedProjects/></ProtectedRoute>} />
        <Route path="/proposals/commercial_contracts/:projectUUid" element={<ProtectedRoute><ProjectShow/></ProtectedRoute>} />
        <Route path="/diagnostic_meetings/" element={<ProtectedRoute><Diagnostic/></ProtectedRoute>}></Route>
        <Route path="/diagnostic_meetings/:UUid" element={<ProtectedRoute><DiagnosticMeeting/></ProtectedRoute>}></Route>
      </Routes>
    </div>
    <Footer />
  </BrowserRouter>

  );
}

export default App;
