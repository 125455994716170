import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import fetchData from "../../utils";
import { FormHelperText } from "@mui/material";

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 10000;
const MAX_SOLUTION_LENGTH = 10000;

export function MeetingCreate(props) {

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [solution, setSolution] = useState("");
  const [date, setDate] = useState(new Date());
  const { lawyers, kams, setMeetings, diagnostic } = props;
  const { raiseAlert } = props;
  const [selectedLawyers, setSelectedLawyers] = useState([]);
  const [selectedKams, setSelectedKams] = useState([]);
  
  const handleAddLawyer = (selectedList, selectedItem) => {
    setSelectedLawyers(selectedList);
  };
  const handleRemoveLawyer = (selectedList, selectedItem) => {
    setSelectedLawyers(selectedList);
  };

  const handleAddKam = (selectedList, selectedItem) => {
    setSelectedKams(selectedList);
  };
  const handleRemoveKam = (selectedList, selectedItem) => {
    setSelectedKams(selectedList);
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (title === "" || description === "" || solution === "" || date === "") {
      let alert  = {"severity": "error", "message": ["Todos los campos con asterisco son obligatorios"] };
      raiseAlert(alert);
      return;
    }
    const body = { data: {
        title: title,
        description: description,
        solution: solution,
        date: date,
        lawyers: selectedLawyers.map((lawyer) => lawyer.id),
        kams: selectedKams.map((kam) => kam.id),
        diagnosticId: diagnostic.id
      }
    }
    fetchData('/diagnostic/create_meeting/', 'POST', body)
    .then((response) => {
      setMeetings((prevState) => {
        return [...prevState, response.data];
      });
      raiseAlert(response);
      setDescription("");
      setSelectedLawyers([]);
      setSelectedKams([]);
      setDate(new Date());
      setSolution("");
      setTitle("");

    })
    .catch((error) => {
      raiseAlert(error);
    });
  };

  return (
    <div className="user-show">
      <h1>Crear reunión/comunicación</h1>
      <Form onSubmit={handleOnSubmit}>

          <Form.Group className="mb-3" controlId="formBasicSubject">
            <Form.Label>Tema principal de la reunión*</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Tema" 
              onChange = {(e) => setTitle(e.target.value)}
              value={title}
              isInvalid={title.length > MAX_TITLE_LENGTH}
            />
            <FormHelperText  error={title.length > MAX_TITLE_LENGTH}>{title.length}/{MAX_TITLE_LENGTH}</FormHelperText>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSubject">
            <Form.Label>Fecha de la reunión*</Form.Label>
            <Form.Control 
              type="date"
              placeholder="Posible solución" 
              onChange = {(e) => setDate(e.target.value)}
              value={date}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSubject">
            <Form.Label>Descripción y temas tratados*</Form.Label>
            <Form.Control 
              as="textarea"
              placeholder="Descripción detallada de la reunión"
              rows={10}
              onChange = {(e) => setDescription(e.target.value)}
              value={description}
              isInvalid={description.length > MAX_DESCRIPTION_LENGTH}
            />
            <FormHelperText  error={description.length > MAX_DESCRIPTION_LENGTH}>{description.length}/{MAX_DESCRIPTION_LENGTH}</FormHelperText>
          </Form.Group>

          <Form.Group className="mb-3" controlId="text">
            <Form.Label>Posible solución*</Form.Label>
            <Form.Control 
              as="textarea"
              placeholder="Posible solución"
              rows={10}
              onChange = {(e) => setSolution(e.target.value)}
              value={solution}
              isInvalid={solution.length > MAX_SOLUTION_LENGTH}
            />
            <FormHelperText  error={solution.length > MAX_SOLUTION_LENGTH}>{solution.length}/{MAX_SOLUTION_LENGTH}</FormHelperText>
          </Form.Group>

          <Form.Group>
            <Form.Label>Abogados presentes</Form.Label>
            <Multiselect showArrow options={lawyers} displayValue="name"
              onRemove={handleRemoveLawyer} onSelect={handleAddLawyer} 
              avoidHighlightFirstOption selectedValues={selectedLawyers}
              className={"multi-select-meeting"}/>
          </Form.Group>

          <Form.Group>
            <Form.Label>Kams presentes</Form.Label>
            <Multiselect showArrow options={kams} displayValue="name"
              onRemove={handleRemoveKam} onSelect={handleAddKam} 
              avoidHighlightFirstOption selectedValues={selectedKams}
              className={"multi-select-meeting"}/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Crear
          </Button>
        </Form>
    </div>
  )
}