import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import fetchData from "../../utils";
import { SuccessButton } from "../../components/Buttons";
import { deltaTime } from "../../utils";
import { LoadingView } from "../LoadingView";


export function Notification() {
  const { UUid } = useParams();
  const [notification, setNotification] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(`/notification/seen-notification/${UUid}`, "GET")
    .then((json) => {
      setNotification(json["data"]["notification"]);
      setIsLoading(false);
    })
  }, [UUid])

  return (
    <div className="user-show">
      {isLoading ? 
        <LoadingView />
        :
        <>
          <h1>{notification.title}</h1>
          <p><b>Fecha: </b>Hace {deltaTime.getNotificationTime(notification.created_at)}</p>
          <h2>Descripción:</h2>
          <p>{notification.body} {notification.link && (<Link to={notification.link}>Ir al enlace</Link>)}</p>
          <SuccessButton onClick={()=> navigate("/notifications")}>Volver a todas las notificaciones</SuccessButton>
        </>
      }
    </div>
  );
}