import * as React from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {LawyerSelector} from "../../components/users/multiSelect";
import Paper from "@mui/material/Paper";
import {Modal} from "react-bootstrap";
import { CircularProgress } from "@mui/material";

import Stack from "@mui/material/Stack";
import {  Form } from "react-bootstrap";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { queryCompleteJob, queryCreateLawyerProposal, queryDeleteJob, queryCreateConfirmedProposal, queryUpdateJob, queryTerminateJob, queryAllStatus, queryNextJobStep, sendProposalMail, querySelectLawyerForJob } from "./utils";
import { FormHelperText, Grid } from "@mui/material";
import { CheckCircleOutlineOutlined, Circle, CircleOutlined } from "@mui/icons-material";
import { CloseButton, RejectButton, SuccessButton } from "../../components/Buttons";
import { LoadingView } from "../LoadingView";
import { StarRatingHover } from "../../components/starRating";
import fetchData, { Validate } from "../../utils";

const labels = {
  0.5: "Malo",
  1: "Malo",
  1.5: "Deficiente",
  2: "Deficiente",
  2.5: "Deficiente",
  3: "Aceptable",
  3.5: "Aceptable",
  4: "Aceptable",
  4.5: "Bueno",
  5: "Excelente",
};

const MAX_JOB_DESCRIPTION_LENGTH = 2000;

const LABELSRATINGJOB = {
  speed: "Evaluar según su rapidez",
  legal_quality: "Evaluar según su calidad jurídica en el servicio",
  client_communication: "Evaluar según su comunicación con el cliente",
  kam_communication: "Evaluar según su comunicación con el KAM"}
  ;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
  
export function RenderOverWorkWeeks(exceed_weeks) {
  return (            
    <>  
      <b>Semana(s) con exceso de carga laboral: </b>
      <ol>
        {exceed_weeks.data.map((week,index)=>{
          return <>
            <li key={index}>
            Desde {week.start_date.substring(0,10)} al {week.end_date.substring(0,10)}
            </li>
            <b>Carga laboral estimada: </b>
            <ul> {week.expected_workload} horas
            </ul>
            {week.jobs.length > 0 && (
              <b>Trabajos durante la semana con exceso de carga laboral: </b>
            )}
            {week.jobs.map((job,index)=>{
              return <ul key={index}> {job.service.service_name}</ul>;
            })}
          </>;
        })}
      </ol>
    </>
  );
}

export function ServiceStack(props) {
  const proposalObjects =   props.proposal_objects;
  const setProposalObjects =props.set_proposal_objects; 
  const {project} = props;
  const raiseAlert =props.raise_alert;
  const forceUpdate = props.force_update;

  const [rejectData,setRejectData] = React.useState({"title":"", "body": <></>, "show": false});
  const [rejectDataIndex,setRejectDataIndex] = React.useState(0);

  const [actualIndex, setGlobalActualIndex] = React.useState(-1);
  const [localActualIndex, setLocalActualIndex] = React.useState(-1);
  const [actuaLawyerUUID, setLawyerUUID] = React.useState("");
  const [actualLawyerCost, setLawyerCost] = React.useState();
  const [actualJobID, setJobID] = React.useState("");
  const [confirmEraseExperience, setConfirmEraseExperience] = React.useState(false);
  const [confirmationCompleteModal,setConfirmationCompleteModal] = React.useState(false);
  const [terminationModal,setTerminationModal] = React.useState(false);
  const [nextStepModal,setNextStepModal] = React.useState(false);
  const [allStatus, setAllStatus] = React.useState([]);
  const [sendingMail, setSendingMail] = React.useState(false);

  React.useEffect(()=>{
    queryAllStatus().then((response)=>{
      setAllStatus(response.data);
    });
  },[]);

  const handleSend = () =>{
    const job = proposalObjects[actualIndex];
    const lawyer = job.lawyerList[localActualIndex];
    proposalObjects[actualIndex].disabled = true;
    lawyer.allowToEdit = !lawyer.allowToEdit;
    lawyer.status = "Esperando Respuesta del Abogado";  

    setSendingMail(true);
    queryCreateConfirmedProposal(actuaLawyerUUID, actualLawyerCost, actualJobID).then((response)=>{
      raiseAlert(response);
    });

    sendProposalMail(actualJobID, actuaLawyerUUID)
      .then((response)=>{
        setRejectData({...rejectData, "show": false});
        setSendingMail(false);
        raiseAlert(response);
      }).catch((error)=>{
        let alert = {"severity": "error", "message": ["Existe un error al mandar el correo"] };            
        raiseAlert(alert);
      });
    
  };
  const handleCancel = () =>{
    proposalObjects[actualIndex].waiting_status = "Mandar propuesta";
    proposalObjects[actualIndex].lawyerList[localActualIndex].allowToEdit = true;
    proposalObjects[actualIndex].lawyerList[localActualIndex].status = "Mandar propuesta";
    setRejectData({...rejectData, "show": false});
  };

  function updateRejectData(response, job, cost){
    setRejectDataIndex(0);
    let reject_data = {
      "show": true,
      "title": [],
      "body":[],
    };
    if (job.price * 0.7 < cost || job.price*0.5 > cost){
      reject_data.title.push("El pago al abogado está fuera del rango sugerido");
      reject_data.body.push(
        <>
          <h5>
            El pago del abogado está más allá del rango sugerido
          </h5>
          <p>
          Se sugiere que esté entre el 50 % del precio del trabajo como mínimo ({job.price * 0.5}) y un 70% del precio como máximo ({job.price * 0.7}).
          </p>
          <p>
          El pago actualmente al abogado actualmente se está ofreciendo {proposalObjects[actualIndex].lawyerList[localActualIndex].cost} UF como pago.
          </p>
        </>
      );
    }
    if (response.reasons){
      reject_data.title.push("Este abogado ya rechazó esta propuesta. ¿Está seguro que desea volver a enviar la misma propuesta?");
      reject_data.body.push(
        <>
          <h5>
                Motivos del rechazo:
          </h5>
          {response.reasons?.length > 0? (<>
            <Grid container>
              {response.reasons.map((reason)=> 
                <>
                  <Grid  item xs={1} md={1}>
                  </Grid>
                  <Grid  item xs={11} md={11}>
                    <li>
                      {reason}
                    </li>
                  </Grid>
                </>)}
            </Grid>
          </>):<>No especificado</>
          }  
        </>
      );
    }
    if (response.exceed_weeks.length){
      reject_data.title.push("Este abogado tiene una alta carga laboral");
      reject_data.body.push(
        <>
          <p>El abogado tendrá un exceso de carga laboral si acepta el trabajo ({job.service}) según su disponibilidad ({response.work_load} horas semanales). La información en detalle es la siguiente:</p>
          <b>Carga laboral de {job.service}: </b>
          <ul>{job.workload} horas</ul>
          <RenderOverWorkWeeks data={response.exceed_weeks}/>
        </>
      );
    }
    setRejectData(reject_data);
    return ;
  }

  function removeService(index) {
    const proposalToRemove = proposalObjects[index].jobID;
    queryDeleteJob(proposalToRemove).then(
      (response) =>{
        raiseAlert(response);
      }
    );
    proposalObjects.splice(index,1);
    setGlobalActualIndex(-1);
  }

  function completeService(index){
    const proposalToComplete = proposalObjects[index].jobID;
    const rating = proposalObjects[index].rating;
    let alert  = {"severity": "error", "message": [] };
    const evaluation = proposalObjects[index].evaluation;
    if (proposalObjects[index].selectedLawyer === {})
    {
      alert["message"].push("No puede marcar una propuesta que no tiene abogado designado como completa.");
      raiseAlert(alert);
      return;
    }
    queryCompleteJob(proposalToComplete, rating, evaluation)
      .then((response) =>{
        proposalObjects[index].new_status = response.new_status;
        raiseAlert(response);
      }
      );
  }

  function terminateService(index){
    const proposalToTerminate = proposalObjects[index].jobID;
    if (proposalObjects[index].new_status.name !== "Completo / Pendiente Pago"){
      return;
    }
    queryTerminateJob(proposalToTerminate).then(
      (response) =>{
        proposalObjects[index].new_status = response.new_status;
        raiseAlert(response);
      }
    );
  }

  function collapseExperience(index) {
    proposalObjects[index].collapsed = !proposalObjects[index].collapsed;
    forceUpdate();
  }
  const onSubmitHandlerProposal = (e) => {
    e.preventDefault();
    const {globalindex, localindex } = e.target.elements;
    const job = proposalObjects[globalindex.value];
    const lawyer = job.lawyerList[localindex.value];
    let alert  = {"severity": "error", "message": [] };

    setGlobalActualIndex(globalindex.value);
    setLocalActualIndex(localindex.value);
    setLawyerCost(lawyer.cost);
    setLawyerUUID(lawyer.lawyerUUID);
    setJobID(job.jobID);
    const electedLawyer = job.elegibleLawyers.find(lawyer_ => lawyer_.UUid === lawyer.lawyerUUID);
    if(!Validate.isValidUUID(lawyer.lawyerUUID)){
      alert["message"].push("Seleccione un abogado");
    }
    else{
      const count = job.lawyerList.reduce((acc, lawyer_) => {
        if (lawyer_.lawyerUUID === lawyer.lawyerUUID && !lawyer_.allowToEdit ) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      if (count >= 1){
        alert["message"].push("El abogado ya cuenta con una propuesta para este trabajo");
      }
    }
    if(!parseFloat(lawyer.cost) || lawyer.cost <= 0){
      alert["message"].push("Debe darle un pago al abogado");
    }
    if (alert["message"].length){
      return raiseAlert(alert);
    }
    if (electedLawyer.confirmation_modal || job.price * 0.7 < lawyer.cost || job.price*0.5 > lawyer.cost|| electedLawyer.reasons){
      updateRejectData(electedLawyer, job, lawyer.cost);
    }
    else{
      lawyer.allowToEdit = !lawyer.allowToEdit;
      lawyer.status = "Esperando Respuesta del Abogado";  
      proposalObjects[globalindex.value].disabled = true;
      queryCreateLawyerProposal(lawyer.lawyerUUID, job.jobID, lawyer.cost).
        then((response)=>{
          raiseAlert(response);
          setSendingMail(true);
          sendProposalMail(job.jobID, lawyer.lawyerUUID)
            .then((response)=>{
              setSendingMail(false);
              raiseAlert(response);
            }).catch((error)=>{
              setSendingMail(false);
              let alert = {"severity": "error", "message": ["Existe un error al mandar el correo"] };            
              raiseAlert(alert);
            });
        });
    }

  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const index =e.target.id;
    setGlobalActualIndex(index);
    let alert  = {"severity": "error", "message": [] };
    if( Validate.date(proposalObjects[index].startDate)  || Validate.date( proposalObjects[index].endDate)){
      alert["message"].push("Seleccione fechas de inicio y término del trabajo");
      return raiseAlert(alert);
    }
    proposalObjects[index].waiting_status = "Esperando Respuesta del Abogado";
    proposalObjects[index].disabled = true;
    forceUpdate();
  };

  if (allStatus.length === 0){
    return (
      <LoadingView/>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <GenericalModal show={confirmEraseExperience}
        title="¿Está seguro de eliminar el servicio?"
        footer={(
          <>
            <RejectButton onClick={()=>{
              removeService(actualIndex);
              setConfirmEraseExperience(false);
              forceUpdate();}
            } >Eliminar </RejectButton>
            <CloseButton onClick={()=>{
              setConfirmEraseExperience(false);}
            }>Cerrar</CloseButton>
          </>
        )}
      ></GenericalModal>
      <GenericalModal show={confirmationCompleteModal}
        title="¿Está seguro de confirmar el servicio?"
        body={(
          <>
            <p>Seleccione la cantidad de estrellas que desea evaluar al abogado en cada campo (debe evaluarlo antes de confirmar):</p>
            <HoverRating proposalObjects={proposalObjects}
              setProposalObjects={setProposalObjects} actualIndex={actualIndex} type={1}
              text={"Evaluar al abogado con respecto a su desempeño general:"}/>
            {Object.entries(LABELSRATINGJOB).map(([key, label]) => 
              <HoverRating key={key} proposalObjects={proposalObjects}
                setProposalObjects={setProposalObjects} actualIndex={actualIndex}
                text={label} type={key}/>
            )
            }
          </>
          
        )}
        footer={(
          <>
            <SuccessButton onClick={()=>{
              completeService(actualIndex);
              setConfirmationCompleteModal(false);
              forceUpdate();}
            } 
            disabled={actualIndex === -1 ? true : proposalObjects[actualIndex].rating === null || !Object.values(proposalObjects[actualIndex].evaluation).every((actual) => actual !== null)}
            >Confirmar Servicio </SuccessButton>
            <CloseButton onClick={()=>{
              setConfirmationCompleteModal(false);}

            }>Cerrar</CloseButton>
          </>
        )}
      ></GenericalModal>

      <GenericalModal show={nextStepModal}
        title={"¿Está seguro de marcar como " + allStatus[proposalObjects[actualIndex]?.new_status.order]?.name+"?"} 
        body ="Esta acción es irreversible. Por favor confirme que el abogado efectivamente está en la etapa correspondiente."
        footer={(
          <>
            <SuccessButton onClick={()=>{
              queryNextJobStep(proposalObjects[actualIndex].jobID).then(
                (response)=>{
                  raiseAlert(response);
                  proposalObjects[actualIndex].new_status = allStatus[proposalObjects[actualIndex]?.new_status.order];
                }
              );
              setNextStepModal(false);
              forceUpdate();}
            } 
            >Marcar como {allStatus[proposalObjects[actualIndex]?.new_status.order]?.name}</SuccessButton>
            <CloseButton onClick={()=>{
              setNextStepModal(false);}
            }>Cerrar</CloseButton>
          </>
        )}
      ></GenericalModal>

      <GenericalModal show={terminationModal}
        title="¿Está seguro de terminar el servicio?"
        body ="Terminar el servicio contempla que el cliente está satisfecho con el trabajo y que el pago se ha realizado."
        footer={(
          <>
            <SuccessButton onClick={()=>{
              terminateService(actualIndex);
              setTerminationModal(false);
              forceUpdate();}
            } 
            >Terminar Servicio </SuccessButton>
            <CloseButton onClick={()=>{
              setTerminationModal(false);}

            }>Cerrar</CloseButton>
          </>
        )}
      ></GenericalModal>
      
      <GenericalModal
        show={rejectData.show}
        title={rejectData.title[rejectDataIndex]}
        body={rejectData.body[rejectDataIndex]}
        footer={(
          <>
            {rejectDataIndex === rejectData.body.length-1?
              <Button variant="success"onClick={handleSend} disabled={sendingMail}>Enviar {sendingMail? <CircularProgress size={20} color="inherit" /> : <></>}</Button>
              :
              <Button variant="success"onClick={()=>{setRejectDataIndex(rejectDataIndex + 1);}} disabled={sendingMail}>Siguiente {sendingMail? <CircularProgress size={20} color="inherit" /> : <></>}</Button>
            }
            <Button variant="danger" onClick={handleCancel} disabled={sendingMail}>Cerrar</Button>
          </>
        )}
      ></GenericalModal>  
      <Stack spacing={2}>
        <h1>
            Servicios Legales
        </h1>        
        {proposalObjects.map((option, index) => {
          const isJobActive = Object.keys(proposalObjects[index].selectedLawyer).length !== 0 && proposalObjects[index].new_status.order >= 3 ;
          const isJobComplete = proposalObjects[index].new_status.name === "Completo / Pendiente Pago";
          const isJobTerminated = proposalObjects[index].new_status.name === "Terminado";
          return (
            <Item key={index} style={ isJobComplete || isJobTerminated? {backgroundColor:"#f2f9ec"}:{}} >
              <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
            
                <Grid container> 
                  <Grid item xs={12} md={8}>
                    {<h3>{index +1}: {option.service}</h3>}
                  </Grid>
                  <Grid item xs={3} md={2}>
                  </Grid>
                  <Grid item xs={3} md={1}> 
                    {project.is_accepted === null? (
                      <Button onClick={() => {setGlobalActualIndex(index);
                        setConfirmEraseExperience(true);}}>
                        <Tooltip title="Remover Servicio Legal" placement="top-end">
                          <DeleteIcon  sx={{color: "red"}} />
                        </Tooltip>
                      </Button>
                    ):
                      <Tooltip 
                        title={
                          !isJobActive? "" : isJobTerminated? "Trabajo Terminado" : "Marcar como '" + allStatus[proposalObjects[index].new_status.order].name + "'."
                        } 
                        placement="top-end">
                        <span>
                          <Button 
                            disabled={!isJobActive || isJobTerminated} 
                            onClick={() => {
                              if (isJobComplete){
                                setTerminationModal(true);
                              }
                              else{
                                if (proposalObjects[index].new_status.order === 5){
                                  setConfirmationCompleteModal(true);
                                }
                                else{
                                  setNextStepModal(true);
                                }
                              }
                              setGlobalActualIndex(index);
                            }}>
                            {
                              isJobTerminated ? (<Circle sx={{color:"#6ca437"}}/>):
                                isJobComplete ? (<CheckCircleOutlineOutlined  sx={{color:"#6ca437"}}/>) : 
                                  isJobActive ? (<CircleOutlined   sx={{color:"#6ca437"}}/>) : 
                                    (<CircleOutlined/>)
                            }
                          </Button>
                        </span>
                      </Tooltip>
                    }
                  </Grid>
                
                  <Grid item xs={3} md={1}>
                    <Button onClick={() => collapseExperience(index)}>
                      {proposalObjects[index]?.collapsed ? (
                        <ExpandLessIcon></ExpandLessIcon>
                      ) : (
                        <ExpandMoreIcon></ExpandMoreIcon>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <Collapse in={proposalObjects[index]?.collapsed} timeout="auto">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <h5  style={{fontWeight: "800"}}> Detalle del trabajo </h5>
                  </Grid>

                </Grid>
                <Form onSubmit={onSubmitHandler} id={index}>
                  <Form.Control type="hidden"
                    id="jobID"
                    value={proposalObjects[index]?.jobID??""}>
                  </Form.Control>


                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control as="textarea" rows={3} value={proposalObjects[index]?.description??""}
                        disabled={ !proposalObjects[index]?.allowToEdit}
                        onChange={(e) => {
                          proposalObjects[index].description = e.target.value;
                          forceUpdate();
                        }}
                      />
                      <FormHelperText error={proposalObjects[index].description.length===MAX_JOB_DESCRIPTION_LENGTH}>{proposalObjects[index].description.length}/{MAX_JOB_DESCRIPTION_LENGTH}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Form.Label> Precio por trabajo (UF)</Form.Label>
                      <Form.Control type="number" value={proposalObjects[index]?.price??""}
                        placeholder="Ingrese algo"
                        disabled={!proposalObjects[index]?.allowToEdit}
                        onChange={(e) => {
                          proposalObjects[index].price = e.target.value;
                          forceUpdate();
                        }}/>
                    </Grid>


                    <Grid item xs={12} md={6}>
                      <Form.Label> Carga laboral estimada en horas</Form.Label>
                      <Form.Control type="number" value={proposalObjects[index]?.workload??""}
                        disabled={!proposalObjects[index]?.allowToEdit}
                        onChange={(e) => {
                          proposalObjects[index].workload  = e.target.value;
                          forceUpdate();
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Form.Label> Fecha esperada del inicio del trabajo</Form.Label>
                      <Form.Control type="date" value={proposalObjects[index]?.startDate??""}
                        disabled={!proposalObjects[index]?.allowToEdit}
                        onChange={(e) => {
                          proposalObjects[index].startDate = e.target.value;
                          forceUpdate();
                        }}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Form.Label> Fecha esperada del entregable</Form.Label>
                      <Form.Control type="date" value={proposalObjects[index]?.endDate ??""}
                        disabled={!proposalObjects[index]?.allowToEdit}
                        onChange={(e) => {
                          proposalObjects[index].endDate = e.target.value;
                          forceUpdate();
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={12} md={12} style={{marginTop:"10px"}}>

                      <Button 
                        id = {index}
                        variant="contained"
                        disabled={proposalObjects[index]?.disabled}
                        onClick= {
                          (e) => {
                            const index = e.target.id;
                            if (proposalObjects[index]?.allowToEdit) {
                              queryUpdateJob(proposalObjects[index]).then((response)=>{
                                raiseAlert(response);
                                proposalObjects[index].allowToEdit = !response.success;
                                proposalObjects[index].elegibleLawyers = response.elegibleLawyers;
                                forceUpdate();
                              });
                            } else {
                              proposalObjects[index].allowToEdit = true;
                            }
                            forceUpdate();
                          }
                        }>
                        {proposalObjects[index]?.allowToEdit? "Guardar Cambios":"Editar Trabajo"}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>


                <Grid container marginY={"10px"}>
                  <Grid item xs={12} md={9}>
                    <h5  style={{fontWeight: "800"}}> Contacto Abogados </h5>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        proposalObjects[index].lawyerList.push({
                          "status": "Mandar propuesta",
                          "cost": "",
                          "allowToEdit": true,
                          "lawyerUUID": null,
                          "fullname": "",
                        });
                        forceUpdate();
                      }}
                      disabled={proposalObjects[index].lawyerList.length>=5 || proposalObjects[index].new_status.order != 2}
                      style={{color:"secondary"}}
                    >
                        Agregar Abogado <AddCircleIcon></AddCircleIcon>
                    </Button>
                  </Grid>

                  <Grid container spacing={2} marginY={"10px"}>
                    <Grid item xs={12} md={4}>
                        Abogado
                    </Grid>
                    <Grid item xs={12} md={4}>
                        Estado
                    </Grid>
                    <Grid item xs={4} md={2}>
                         Pago al abogado (UF)
                    </Grid>
                  </Grid>
                  {proposalObjects[index].lawyerList.map((lawyer,local_index)=>{
                    // #TODO: refactoring esto (si hay un abogado seleccionado solo muestra ese).
                    if (proposalObjects[index].selectedLawyer.lawyerUUID){
                      if (proposalObjects[index].selectedLawyer.lawyerUUID !== lawyer.lawyerUUID)
                      {
                        return ;
                      }
                    }

                    return <Form 
                      onSubmit={onSubmitHandlerProposal} 
                      key={local_index} 
                      style={{width:"100%"}}>
                      <Form.Control type="hidden" id="globalindex" value={index}></Form.Control>
                      <Form.Control type="hidden" id="localindex" value={local_index}></Form.Control>

                      <Grid container spacing={2} marginY={"10px"}>
                      
                        <Grid item xs={4} md={4}>
                          <LawyerSelector
                            id="lawyerSelector"
                            lawyer_options = {proposalObjects[index].elegibleLawyers}
                            globalindex={index}
                            localindex={local_index}
                            proposal_objects={proposalObjects}
                            value={lawyer}
                            update = {forceUpdate}
                          />
                        </Grid>
                        <Grid item xs={4} md={4} >
                          <h5 style={{  width: "100%",overflow:"hidden" }}>
                            <Box component='li' data={props}>
                              {proposalObjects[index].new_status.order >= 3?
                                proposalObjects[index].new_status.name:
                                lawyer.status
                              }
                            </Box>
                          </h5>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <Form.Control type="number" value={lawyer.cost??""}
                            disabled={!lawyer.allowToEdit || proposalObjects[index]?.allowToEdit}
                            onChange={(e) => {
                              proposalObjects[index].lawyerList[local_index].cost = e.target.value;
                              forceUpdate();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {/* Vemos si el estado es 2, es decir "pendiente asignación" */}
                          {proposalObjects[index].new_status.order === 2? 
                            (lawyer.status === "Mandar propuesta" || lawyer.status === "Propuesta Rechazada" || lawyer.status === "Propuesta Expirada") || sendingMail && lawyer.lawyerUUID === actuaLawyerUUID?
                              <Button 
                                variant="contained"
                                id = {index}
                                type="submit" 
                                disabled={!lawyer.allowToEdit || proposalObjects[index]?.allowToEdit|| !proposalObjects[index]?.startDate || !proposalObjects[index]?.endDate}
                              >
                                {sendingMail && lawyer.lawyerUUID === actuaLawyerUUID? <><CircularProgress size={20} color="inherit"/>Enviando correo</>:<>
                                Mandar propuesta
                                </> }
                              </Button>
                              :
                              <Button 
                                variant="contained"
                                id = {index}
                                onClick={()=>{
                                  setSendingMail(true);
                                  querySelectLawyerForJob(lawyer.lawyerUUID,proposalObjects[index].jobID)
                                  .then((response)=>{
                                    let alert = {"severity": "success", "message": response.message };          
                                    proposalObjects[index].new_status.order = 3;
                                    proposalObjects[index].selectedLawyer = lawyer;
                                    raiseAlert(alert);
                                  })
                                  .then(()=>{
                                    const body = {"data": {
                                      "jobId": proposalObjects[index].jobID, "lawyerUUid": lawyer.lawyerUUID
                                      }
                                    }
                                    fetchData("/mailer/send_selected_lawyer_mail/", "POST", body)
                                    .then((response)=>{
                                      let alert = {"severity": "success", "message": response.message };          
                                      raiseAlert(alert);
                                      setSendingMail(false);
                                    })
                                    .catch((error)=>{
                                      let alert = {"severity": "error", "message": error.message };          
                                      raiseAlert(alert);
                                      setSendingMail(false);
                                    })
                                  })
                                  .catch((error)=>{
                                    let alert = {"severity": "error", "message": error.message };          
                                    raiseAlert(alert);
                                    setSendingMail(false);
                                  })
                                }}
                                disabled={lawyer.status !== "Propuesta Aceptada" || proposalObjects[index].new_status.order != 2 }
                              >
                                {sendingMail && lawyer.lawyerUUID === actuaLawyerUUID? <><CircularProgress size={20} color="inherit"/>Enviando correo</>:
                                  <>Seleccionar abogado</> }
                              </Button>
                            : 
                            <></>
                          }
                        </Grid>
                      </Grid>
                    </Form>;
                  })
                  }
                </Grid>
              </Collapse>
            </Item>
          );
        })}
      </Stack>
    </Box>
  );
}


export function GenericalModal(props) {
  const {show,footer,body, title} = props;
  return (
    <Modal show={show} backdrop="static" >
      {title &&
      (<Modal.Header>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      )}
      {body &&
      (
        <Modal.Body>
          {body}
        </Modal.Body>
      )}
      {footer &&
      (
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      )}
    </Modal>);
}


export default function HoverRating(props) {
  const { proposalObjects, setProposalObjects, actualIndex } = props;
  const { text, type } = props;
  const [value, setValue] = React.useState(type === 1 ? 
    (proposalObjects[actualIndex].rating === null ? 2.5 : proposalObjects[actualIndex].rating) :
    proposalObjects[actualIndex].evaluation[type] === null ? 2.5 : proposalObjects[actualIndex].evaluation[type]);
  //const [value, setValue] = React.useState(proposalObjects[actualIndex].evaluation[key] === null ? 2.5 : proposalObjects[actualIndex].evaluation[key])
  const [hover, setHover] = React.useState(-1);
  const handleChange = (newValue) => {
    if (newValue === null) {
      return;
    }
    if (type === 1) {
      let aux_proposalObjects = [...proposalObjects];
      aux_proposalObjects[actualIndex].rating = newValue;
      setProposalObjects(aux_proposalObjects);
    } else {
      let aux_proposalObjects = [...proposalObjects];
      aux_proposalObjects[actualIndex].evaluation[type] = newValue;
      setProposalObjects(aux_proposalObjects);
    }
  };
  const realValue = hover !== -1 ? hover : value;
  return (
    <>
      <hr/>
      <p>
        {text}
      </p>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <StarRatingHover stars={realValue} setStars={setValue} handleChange={handleChange}></StarRatingHover>
        </Grid>
        <Grid item xs={7} sx={{alignItems: "center"}}>
          {value !== null && (
            <Box sx={{ ml: 2 }}>Estrella{realValue === 1 ? "" : "s"}: {hover !== -1 ? hover : value} {labels[realValue]}</Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
