import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Form } from "react-bootstrap";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import React from "react";
import {Edit,Delete} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GenericalModal } from "../../views/kam/utilsComponent";
import { CloseButton, RejectButton } from "../../components/Buttons";
import { queryDeleteProject, queryWorkLoad } from "../../views/lawyer/utils";
import Notification from "../Notification";


export function ProjectsTable(props){
  const {actionHeader, baseLink, filteredProjects} = props;
  const [amount, setAmount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [searchProjects, setSearchProjects] = useState(filteredProjects);
  const navigate = useNavigate();
  const [deleteData, setDeleteData] = React.useState({"title":"¿Estás seguro de eliminar esta propuesta comercial? Esta acción es irreversible.", "body": <></>, "show": false, "projectUUID":""});
  const [notification, raiseAlert] = Notification("success", "");
  
  useEffect(() => {
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex) {
      setPageIndex(newMaxIndex);
    }
    
  }, [amount, pageSize, pageIndex]);
  
  function isValuePresent(value) {return (value !== null && value !== undefined && value !== "");}

  useEffect(() => {
    let searchProjects = filteredProjects.filter((project) => {
      const code = project.code?? "";
      return project.is_deleted === false &&  (project.project_name.toLowerCase().includes(search.toLowerCase()) ||
      code.toLowerCase().includes(search.toLowerCase()));
    });
    setSearchProjects(searchProjects);
    setAmount(searchProjects.length);
  }, [filteredProjects,filteredProjects.length,deleteData,deleteData.show]);
  
  const onChangeSearch = (e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    let searchProjects = filteredProjects.filter((project) => {
      const code = project.code?? "";
      return project.project_name.toLowerCase().includes(search.toLowerCase()) ||
      code.toLowerCase().includes(search.toLowerCase());
    });
    setSearchProjects(searchProjects);
    setAmount(searchProjects.length);
  };

  const updateActualProjectData = (index) =>{
    setDeleteData({...deleteData, 
      "body":
        <>
        <h4>Información adicional</h4>
        <table>
        {isValuePresent(searchProjects[index].code) && (<tr><th>Código </th><td>{searchProjects[index].code}</td></tr>)}
        {isValuePresent(searchProjects[index].project_name) && (<tr><th>Nombre Propuesta Comercial </th><td>{searchProjects[index].project_name}</td></tr>)}
        {isValuePresent(searchProjects[index].project_description) && (<tr><th>Descripción </th><td>{searchProjects[index].project_description}</td></tr>)}
        </table>
        </>, 
      "show":true,
      "projectUUID": searchProjects[index].UUid,
      "index": index,
    }
    )
  };

  const closeData = () =>{
    setDeleteData({...deleteData, "show":false})
  };

  const deleteProject = () =>{
    queryDeleteProject(deleteData.projectUUID).then(
      (response)=>{
        searchProjects[deleteData.index].is_deleted = true;
        setDeleteData({...deleteData, "show":false})
        raiseAlert(response);
      }
    )
  };
  
  return (
    <div className='user-table'>
      <GenericalModal
      show={deleteData.show}
      title={deleteData.title}
      body={deleteData.body}
      footer={
        <>
        <RejectButton onClick={deleteProject}>Eliminar Propuesta</RejectButton>
        <CloseButton onClick={closeData}>Cerrar</CloseButton>
        </>
      }
      >
      </GenericalModal>
      <Grid container>
        <Grid item  xs={10} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        </Grid>
        <Grid item  xs={8} md={4}>
            Página
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>
        <Grid item  xs={12} md={3}>
          <label>Buscar en la tabla:</label>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
      </Grid>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Código</th>
            <th>{actionHeader}</th>
          </tr>
        </thead>
        <tbody>
          {searchProjects.map((project, index) => {
            const goToProject = ()=>{navigate(baseLink + `${project.UUid}`);};
            
            return pageIndex * pageSize <= index && index < (pageIndex + 1) * pageSize && (
              <tr key={project.id}>
                <th>{index + 1}</th>
                <th>{project.project_name}</th>
                <th>{project.project_description}</th>
                <th>{project.code}</th>
                <th> 
                <Tooltip
                title={<Typography fontSize={20}>Editar</Typography>}
                placement="left"
                >
                  <Button onClick={goToProject} style={{"width":"42%"}}>
                    <Edit/>
                  </Button>
                </Tooltip>
                <Tooltip
                  title={<Typography fontSize={20}>Eliminar</Typography>}
                  placement="right"
                >
                  <Button onClick={()=>{updateActualProjectData(index)}} style={{"width":"42%", "color": "red"}}>
                    <Delete/>
                  </Button>
                </Tooltip>
                </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Grid container>
        <Grid item  xs={10} md={4}>
            Página
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>
        <Grid item  xs={8} md={4}>
        </Grid>
      </Grid>
      <p>
        Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
      </p>
      {notification}
    </div>
  );
}