import fetchData from "../../utils";


export async function queryHistoricalServiceByUUID(historicalServiceUUID){
    const url = "/project/queryHistoricalServiceByUUID";
    const method = "POST";
    const body = {
      "historicalServiceUUID": historicalServiceUUID,
    };
    return await fetchData(url,method,body);
  }


  export async function queryHistoricalDataByHistoricalService(historicalServiceID){
    const url = "/project/queryHistoricalDataByHistoricalService";
    const method = "POST";
    const body = {
      "historicalServiceID": historicalServiceID,
    };
    return await fetchData(url,method,body);
  }


export async function queryHomologateHistoricalService(historicalServiceID, newServiceUUID){
  const url = "/project/queryHomologateHistoricalService";
  const method = "POST";
  const body = {
    "historicalServiceID": historicalServiceID,
    "newServiceUUID": newServiceUUID
  };
  return await fetchData(url,method,body);
}
