import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../styles/notification.scss";
import React, { useState } from "react";

function Notification (){
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  const raiseAlert = (data) => {
    setSeverity(data.severity ? data.severity :  data.success? "success":"error");
    setMessage(data.message);
    setOpen(true);
  };
  
  const Component = <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className='notifications'>
    <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
      {typeof(message) === typeof("")?
        message: 
        typeof(message) === typeof([])?
          message.map((item,index) => {
            return(<div key={index}> {item}</div>);
          })
          :
          <></>
      }
    </MuiAlert>
  </Snackbar>;
  return [Component,raiseAlert];
}

export default Notification;