import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/userShow.scss";
import { UserData } from "../../components/users/userData";
import { Button } from "@mui/material";
import {React} from "react";
import { BasicStack } from "../../components/users/multiSelect";
import { LoadingView } from "../LoadingView";
import { queryUserByID } from "../users/utils";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Notification from "../../components/Notification";
import { JobTable } from "../../components/lawyer/jobsTable";
import fetchData from "../../utils";


export function ShowUserExperience() {

  const [user, setUser] = useState(null);
  const { userID } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRole, setCurrentRole] = useState(0);
  const [currentRoleName, setCurrentRoleName] = useState(null);
  const [newJobObjects, setNewJobObjects] =useState([]);
  const [lawyer, setLawyer] = useState(null);
  const [kam, setKam] = useState(null);
  const [isEditingState, setIsEditingState] = useState(false);
  const [notification, raiseAlert] = Notification("success", "");
  const [isActiveUser, setIsActiveUser] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    queryUserByID(userID)
      .then((json) => {
        setUser(json["data"]);
        setIsActiveUser(json["data"]["auth"].is_active);
        setLawyer(json["lawyer"] ?? null);
        setCurrentUser(json["current_user"]);
        setCurrentRoleName(json["data"].role);
        setKam(json["kam"] ?? null);
      });
  }, [navigate, userID]);

  useEffect(() => {
    fetchData(`/project/jobs_lawyer/completed/${userID}`, "GET")
    .then((response) => {
      setJobs(response.data);
    })
  }, [userID])
  useEffect(() => {
    fetchData("/country/all/", "GET")
    .then((response) => {
      setCountries(response.data);
    })
  }, []);
  const handleBack = () => {
    navigate(-1);
  };

  if (user && countries) {
    const isAdmin = currentUser.role === "Admin";
    const isKAM = currentUser.role === "KAM";
    const userAdmin = user.role === "Admin";
    const userLawyer = user.role === "Lawyer";
    return (
      <div>
        <div className="user-show">
          <h1>Perfil de {user.auth.first_name}</h1>

          <div>
            <UserData user={user} isEditing={false} isAdmin={isAdmin} userAdmin={userAdmin} currentRole={currentRole}
              currentRoleName={currentRoleName} setCurrentRole={setCurrentRole} setCurrentRoleName={setCurrentRoleName} userId={userID}
              setUser={setUser} setIsEditing={setIsEditing} raiseAlert={raiseAlert} kam={kam} setKam={setKam}
              lawyer={lawyer} setLawyer={setLawyer} isKAM={isKAM} userLawyer={userLawyer} isEditingState={isEditingState} isActiveUser={isActiveUser}
              countries={countries}/>
          </div>
          {userLawyer && (
              <div className="user-show-info">
                <b>
                  <label>Aceptado:</label>
                </b>
                <p>
                  {lawyer.is_accepted === "Aceptado" ? 
                  <AiFillCheckCircle style={{"color": "green"}}/>
                  : 
                  <AiFillCloseCircle style={{"color": "red"}}/>}

                </p>
              </div>
            )}
          <div className="user-show-buttons">
            <Button variant="contained"   onClick={handleBack}>
              Volver
            </Button>
          </div>
        </div>
        
        
        <div className="user-show">
          <BasicStack
            newJobObjects={newJobObjects}
            setNewJobObjects={setNewJobObjects}
            category="Servicios Legales"
            user = {user}
            showEditOptions={false}
          ></BasicStack>
        </div>
        {notification}
        <div className="user-show">
          <h1>Trabajos completados por el abogado en Impacto Legal</h1>
          <JobTable jobs={jobs} isKam={true}/>
        </div>
      </div>
    );
  }
  return (
    <LoadingView/>
  );
}
