import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import "../../styles/userShow.scss";
import Notification from "../../components/Notification";
import { Button, FormHelperText } from "@mui/material";
import {ComboBox} from "../../components/users/multiSelect";
import React from "react";
import { LoadingView } from "../LoadingView";
import { SuccessButton } from "../../components/Buttons";
import { queryAllClients, queryCreateProject } from "./utils";
import { queryUserByID } from "../users/utils";
import fetchData from "../../utils";
import { ProjectsTable } from "../../components/kam/ProjectsTable";

const MAX_PROPOSAL_TITLE_LENGTH = 100;

export function Proposals(props) {
  const location = useLocation();
  const [notification, raiseAlert] = Notification("success", "");
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [current_client_selected, setCurrentClientSelected] = useState(location.state ? location.state.client.user.auth.username : null);
  const [proposalTitle, setProposalTitle] = useState("");
  const [proposalDescription, setProposalDescription] = useState("");

  useEffect(() => {
    fetchData("/user/current_user/", "GET", null)
      .then(async(json) => {
        setUser(json["current_user"]);
        await onCreateProject(json["current_user"].id);
      });
  }, []);

  useEffect(() => {
    queryAllClients()
      .then(async(json) => {
        setClients(json["data"]);
      });
    }, []);
      
  const onCreateProject = async (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryProjectsByKAMID`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        "id" : id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          
          throw new Error(response.status);
        } else {
          return response.json();
        }
      })
      .then((json) => {
        setProjects(json["data"]);
      })
      .then(() => {})
      .catch((error) => {
        if (error.message === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        } else if (error.message === "403") {
          navigate(-1);
        }
      });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const { projectClient, projectName, projectDescription } = e.target.elements;

    if (projectName.value ==="" || projectDescription.value  === ""){
      let alert = {"severity": "error", "message": ["Ingrese nombre y descripción de la propuesta comercial."] };            
      raiseAlert(alert);
      return;
    }
    queryCreateProject(projectClient.value, projectName.value,projectDescription.value, user.id, user)
      .then(async(response) => {
        setCurrentClientSelected("");
        setProposalTitle("");
        setProposalDescription("");
        raiseAlert(response);
        await onCreateProject(user.id);
      });
  };

  if (user && clients.length) {
    return (
      <div>
        <div className="user-show">
          <h1>
            Crear una nueva propuesta comercial
          </h1>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group>
              <ComboBox
                id="projectClient"
                setCurrentOption={setCurrentClientSelected}
                options={clients.map((client) => client.user.auth.username)}
                category="Clientes disponibles"
                value = {current_client_selected}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className = "form-label">Título de la propuesta comercial: </Form.Label>
              <Form.Control type="text"
                value={proposalTitle}
                onChange={(e)=>{setProposalTitle(e.target.value);}}
                id="projectName"
                sx={{ width: "80%" }}
                label="Título de la propuesta comercial"
                placeholder="Por favor indique el título de la propuesta comercial"
              />
            </Form.Group>
            <FormHelperText  error={proposalTitle.length === MAX_PROPOSAL_TITLE_LENGTH}>{proposalTitle.length}/{MAX_PROPOSAL_TITLE_LENGTH}</FormHelperText>

            <Form.Group>
              <Form.Label className = "form-label">Descripción de la propuesta comercial: </Form.Label>
              <Form.Control as="textarea"
                value={proposalDescription}
                onChange={(e)=>{setProposalDescription(e.target.value);}}
                id="projectDescription"
                sx={{ width: "80%" }}
                label="Descripción de la propuesta comercial"
                rows={2}
                placeholder="Por favor complementar con información adicional relevante"
              />
            </Form.Group>
            <SuccessButton type="submit"> Crear la propuesta comercial </SuccessButton>

          </Form>
        </div>
          <div className="user-show">
            <h1>Propuestas comerciales para {user.auth.first_name} </h1>
            <ProjectsTable
              filteredProjects = {projects.filter((project)=> {return !project.is_accepted} )}
              actionHeader = "Acciones"
              baseLink = {"/proposals/commercial_contracts/"}
            ></ProjectsTable>
          </div>
          {notification}
      </div>
    );
  }
  return (
    <div>
      <LoadingView />
    </div>
  );
}