import * as React from "react";
import { CircularProgress } from "@mui/material";

/* eslint-disable no-restricted-globals */
export function LoadingView(){
  const [dimensions, setDimensions] = React.useState({
    width: parent.innerWidth, 
    height: parent.innerHeight
  });

  async function handleResize() {
    setDimensions({
      width: parent.innerWidth,
      height: parent.innerHeight
    });
  }

  window.addEventListener("resize", handleResize);
  
  return (
    <div style={{"display": "grid", "placeItems":"center"}}>
      <CircularProgress color="success" size={dimensions.width/10}>
      </CircularProgress>
    </div>);
}