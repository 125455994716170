import "../styles/navbar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import logo from "../img/impacto_legal.png";
import { Avatar, Badge, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { AiOutlineBell } from "react-icons/ai";
import Breadcrumb from "./Breadcrumb";
import fetchData, { deltaTime } from "../utils"



export function NavBar(props) {
  const { isLogged, sidebar, setIsLogged } = props;
  const isAuthenticated = localStorage.getItem("token") !== null?? false;
  const [user, setUser] = useState(null);
  const { notificationAmount, setNotificationAmount, notificationList, setNotificationList } = props;
  const navigate = useNavigate();
  const showSidebar = () => props.setSidebar(true);
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/user/current_user/`, {
        headers: {
          "Content-Type":"application/json",
          "Authorization": `Token ${token}`,
        }})
        .then((response) => response.json())
        .then((json) => {
          setUser(json["current_user"])
          setIsLogged(json["current_user"] !== null);
        });
    }
  }, [isAuthenticated, isLogged]);

  const handleLogout = () => {
    const token = localStorage.getItem("token");
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/logout/`, {
      method: "DELETE",
      headers: {
        "Content-Type":"application/json",
        "Authorization": `Token ${token}`,
      }
    })
      .then(() => {
        localStorage.removeItem("token");
        window.location.reload();
      });
  };
  useEffect(() => {
      if (user){
        fetchData("/notification/get-number-notifications/")
        .then((data) => {
          setNotificationAmount(data["data"]["notifications"]);
        })
      }
  },[user]);

  useEffect(() => {
      if (user){
        fetchData("/notification/notification-no-seen/")
        .then((data) => {
          setNotificationList(data["data"]["notifications"]);
        })
      }
  },[user]);


  const goToMyProfile = () =>{ 
    let path = `/users/${user.UUid}`; 
    navigate(path);
  };  
  
  const goToHome = () =>{ 
    let path = "/home"; 
    navigate(path);
  };  
  const goToLogin = () =>{ 
    let path = "/login"; 
    navigate(path);
  };  

  const handleSelectNotification = (eventKey) => {

    if (eventKey === "-1") {
      navigate("/notifications");
    } else if (eventKey) {
      const newNotificationList = notificationList.filter((notification) => notification.UUid !== eventKey);
      setNotificationList(newNotificationList);
      setNotificationAmount(notificationAmount - 1);
      let path = `/notifications/${eventKey}`;
      navigate(path);
    }
  };

  return (
    <Navbar expand="lg" className={sidebar && isLogged ? "nav-class active" : "nav-class" }>
      <Container>
        { isLogged && (
        <div className="sidebar">
          <Navbar.Brand className='menu-bars' onClick={showSidebar}>
            <FaIcons.FaBars onClick={showSidebar}/>
          </Navbar.Brand>
        </div>)
        }
        <Navbar.Brand onClick={goToHome}>
          <img src={logo} alt="Impacto Legal" className="logo-impacto-legal"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {user ? (<>
            <Nav className="me-auto">
              <label>{user.role}</label>
              <Breadcrumb  className="nav-breadcrumb"/>
            </Nav>
            <Nav className="justify-content-end" >
              <Grid container>
                <Grid item md={3} marginY="auto" marginX="5px">
                  <NavDropdown className="notification-dropdown"
                  drop="down-centered"
                  onSelect={handleSelectNotification}
                  title={
                  <Badge badgeContent={notificationAmount} color="primary" invisible={notificationAmount === 0}>
                    <AiOutlineBell className="notification-bell"/>
                  </Badge>}>
                  <NavDropdown.ItemText style={{"textAlign": "center"}}><b style={{"fontSize": "25px"}}>Notificaciones</b></NavDropdown.ItemText>
                    <NavDropdown.Divider />
                    {notificationList.slice(0, 3).map((notification) => {
                      return (
                        <NavDropdown.Item key={notification.id} eventKey={notification.UUid}> {notification.title} | <b>Hace {deltaTime.getNotificationTime(notification.created_at)}</b></NavDropdown.Item>
                      )
                    })}
                    {notificationList.length === 0 && (
                      <NavDropdown.Item> No hay notificaciones nuevas</NavDropdown.Item>
                    )}
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey={-1}> Ver todas las notificaciones </NavDropdown.Item>
                  </NavDropdown  >
                </Grid>
                <Grid item md={2} marginY="auto" marginX="3px">
                  <Nav.Link onClick={goToMyProfile}> 
                    <Avatar sx={{ width: "28px", height: "28px", bgcolor: "#6ca437", fontSize: "0.75rem" }}>{user.auth.first_name.substr(0,1)+user.auth.last_name.substr(0,1)}</Avatar>
                  </Nav.Link>
                </Grid>
                <Grid item md={3}>
                  <NavDropdown 
                    title={user.auth.username}
                    id="basic-nav-dropdown"> 
                    <NavDropdown.Item onClick={goToHome}>Inicio</NavDropdown.Item>
                    <NavDropdown.Item onClick={goToMyProfile}>
                        Ver perfil
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogout}>Cerrar sesion</NavDropdown.Item>
                  </NavDropdown>
                </Grid>
              </Grid>

            </Nav>
          </>): 
            isAuthenticated !== true && (
              <Nav className="me-auto">
                <Nav.Link onClick={goToLogin}>Iniciar sesión</Nav.Link>
              </Nav>
            ) 
          }
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
  
}

function NavLink(props){
  const {route, label} = props;
  const activeStyle = { fontWeight: "bold", color: "#6ca437" };
  const navigate = useNavigate();
  const goToRoute = () =>{ 
    navigate(route);
  };   
  return (
    <Nav.Link 
      style ={window.location.pathname.includes(route) ? activeStyle: {}}
      active={window.location.pathname.includes(route) } onClick={goToRoute}>{label}
    </Nav.Link>
  );
}