import React from "react";
import "../../styles/inicio.scss";
import { CardComponent } from "../../components/auth/card";
import Apply from "../../img/Apply.jpg";
import LogIn from "../../img/Log_in.jpg";
import { Navigate } from "react-router-dom";


const carts = [
  {
    title: "Postula como abogado",
    description: "¿Eres abogado/a?\n\n¿Tienes experiencia profesional?\n\n¿Buscas trabajar en proyectos de tu interés en forma flexible y remota?\n\n¿Buscas trabajar bajo un modelo de valor compartido?\n\nIMPACTO LEGAL es para tí",
    link: "/register/lawyer",
    linkText: "Postula aquí",
    img: Apply,
    imgAlt: "No hay"
  },
  {
    title: "Iniciar sesión",
    description: "Ya tienes una cuenta",
    link: "/login",
    linkText: "Iniciar sesión",
    img: LogIn,
    imgAlt: "No hay"
  }
]
export function Inicio(props) {
  const { setIsLogged }  = props;
  if (window.localStorage.getItem("token")) {
    setIsLogged(true);
    return <Navigate to="/home" />; 
  }
  //Entry
  return (
    <div>
      <h1>Bienvenido a Impacto Legal</h1>
      <div className="card-content">
        {carts.map((cart, index) => {
          return (
            <CardComponent key={index} title={cart.title} description={cart.description} link={cart.link} linkText={cart.linkText} img={cart.img} imgAlt={cart.imgAlt}/>
          )
        })}
      </div>
    </div>
  )

}