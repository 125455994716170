import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import fetchData from "../../utils";
import { LoadingView } from "../LoadingView";
import Meetings from "../../components/kam/Meetings"
import { MeetingCreate } from "../../components/kam/MeetingCreate";
import Notification from "../../components/Notification";
import Stack from "@mui/material/Stack";
import { Button } from "react-bootstrap";
import "../../styles/diagnosticMeeting.scss"
import { GenericalModal } from "./utilsComponent";
import { CloseButton, RejectButton, SuccessButton } from "../../components/Buttons";


export function DiagnosticMeeting(props) {
  const { UUid } = useParams();
  const [client, setClient] = useState(null);
  const [currentKam, setCurrentKam] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [diagnostic, setDiagnostic] = useState(null);
  const [lawyers, setLawyers] = useState([]);
  const [allKams, setAllKams] = useState([]);
  const [notification, raiseAlert] = Notification("success", "");
  const [showModalProposal, setShowModalProposal] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([fetchData(`/diagnostic/show/${UUid}`, "GET"),
      fetchData('/project/queryAllLawyers/', 'GET'),
      fetchData("/project/queryAllKAM/", "GET")])
    .then(([diagnostic, lawyers, kams]) => {
      setClient(diagnostic["data"]["diagnostic"]["client"]);
      setCurrentKam(diagnostic["data"]["diagnostic"]["kam"]);
      setMeetings(diagnostic["data"]["meetings"]);
      setDiagnostic(diagnostic["data"]["diagnostic"]);
      let filterLawyers = lawyers["data"].filter((lawyer) => lawyer.is_accepted === "Aceptado");
      let fixLawyers = filterLawyers.map((lawyer) => {
        return { 
          name: `${lawyer.user.auth.first_name} ${lawyer.user.auth.last_name}`,
          id: lawyer.id
        }
      });
      setLawyers(fixLawyers);

      let fixKams = kams["data"].map((kam) => {
        return {
          name: `${kam.user.auth.first_name} ${kam.user.auth.last_name}`,
          id: kam.id
        }
      });
      setAllKams(fixKams);
    })
    .catch((error) => {});
  }, [UUid]);


  const handleAcceptProposal = (event) => {
    event.preventDefault();
    fetchData(`/diagnostic/accept_diagnostic/${diagnostic.id}/`, "PATCH")
    .then((response) => {
      raiseAlert(response);
      setDiagnostic(response.data);
      setShowModalProposal(false);
    })
  };

  const handleAcceptProposalAndRedirect = (event) => {
    event.preventDefault();
    fetchData(`/diagnostic/accept_diagnostic/${diagnostic.id}/`, "PATCH")
    .then((response) => {
      raiseAlert(response);
      setDiagnostic(response.data);
      setShowModalProposal(false);
      navigate(`/proposals/commercial_contracts`,
      { 
        state: {
          diagnostic: diagnostic, client:client
        }
      })
    })
  };

  const handleReject = (e) => {
    e.preventDefault();
    fetchData(`/diagnostic/reject/${diagnostic.id}/`, "PATCH")
    .then((response) => {
      raiseAlert(response);
      setDiagnostic(response.data);
      setShowModalReject(false);
    })
  }

  if (!client || !currentKam || !diagnostic) {
    return <LoadingView/>
  }
  return (
    <div>
      <h1>Reuniones/comunicaciones con el cliente: {client.user.auth.first_name}</h1>
      { diagnostic.status === "ACTIVE" &&
      <MeetingCreate diagnostic={diagnostic} lawyers={lawyers}
       kams={allKams} setMeetings={setMeetings}
       raiseAlert={raiseAlert}/>
      }
      {meetings.length === 0 ?
      <h1>No hay reuniones con el cliente aun</h1>
       : 
       <div className="user-show">
        <Stack spacing={2}>
          <h1>Reuniones</h1> 
          {meetings.map((meeting, index) => {
            return(<Meetings meeting={meeting} index={index} diagnostic={diagnostic} currentKam={currentKam}/>)
            })
          }
          
        </Stack>
      </div>
       }
      <div className="buttons-meeting">
        <Button variant="primary" onClick={() => navigate(-1)}>Volver</Button>
        {diagnostic.status === "ACTIVE" &&
          <>
            <SuccessButton variant="success" onClick={() => setShowModalProposal(true)}>Pasar a propuesta comercial</SuccessButton>
            <Button variant="danger" onClick={()=> setShowModalReject(true)}>No concretado</Button>
          </>
        }
      </div>
      <GenericalModal
        show={showModalProposal}
        footer={(
          <>
            <Button variant="primary" onClick={handleAcceptProposal}>Aceptar</Button>
            <SuccessButton onClick={handleAcceptProposalAndRedirect}>Crear propuesta comercial</SuccessButton>
            <CloseButton variant="danger" onClick={() => setShowModalProposal(false)}>Cerrar</CloseButton>
          </>
        )}
        title={"Pasar a propuesta comercial"}
        body={"Si ya creó la propuesta comercial, solo marque el botón aceptar. De lo contrario, aprete el botón crear propuesta comercial, para crear la propuesta comercial."}
      ></GenericalModal>
      <GenericalModal
        show={showModalReject}
        footer={(
          <>
            <RejectButton onClick={handleReject}>Marcar como no concretado</RejectButton>
            <CloseButton onClick={() => setShowModalReject(false)}>Cerrar</CloseButton>
          </>
        )}
        title={"Terminar intercambios"}
        body={"¿Está seguro que los intercambio no se concretaron en una propuesta comercial finalmente?"}
      ></GenericalModal> 
      {notification}
    </div>
  )
}