import fetchData from "../../utils";

export async function queryIndustryByID(industryID){
  const url = `/user/queryIndustryByID/${industryID}`;
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryCreateIndustry(industryName,industryDescription){
  const url = "/project/queryCreateIndustry";
  const method= "POST";
  const body = {
    "industryName": industryName?? "",
    "industryDescription": industryDescription?? "",
  };
  return await fetchData(url,method,body);
}

export async function queryDeleteIndustry(UUid){
  const url = "/project/queryDeleteIndustry";
  const method ="POST";
  const body = {"industryID" : UUid};
  return await fetchData(url,method,body);
}

export async function queryRestoreIndustry(UUid){
  const url = "/project/queryRestoreIndustry";
  const method ="POST";
  const body = {"industryID" : UUid};
  return await fetchData(url,method,body);
}

export async function queryUpdateIndustry(UUid,industryName,industryDescription){
  const url = "/project/queryUpdateIndustry";
  const method ="POST";
  const body = {
    "industryID" : UUid,
    "industryName": industryName,
    "industryDescription": industryDescription,
  };
  return await fetchData(url,method,body);
}

export async function queryAllErasedIndustries() {
  const url = "/project/queryAllErasedIndustries";
  const method= "GET";
  const body = null;
  return await fetchData(url,method,body);
}