import React from 'react'
import Table from 'react-bootstrap/Table'
import { Button } from 'react-bootstrap'
import fetchData from "../../utils";


export function IndexCountry({countries, setCountries, setAllCountries, raiseAlert}) {
  const deleteCountry = (UUid) => {
    fetchData(`/country/delete/${UUid}`, "DELETE")
    .then((response) => {
      const country = countries.find((country) => {
        return country.UUid === UUid;
      });
      const data = { value: country.value, label: country.name };
      setAllCountries((prev) => [...prev, data]);
      setCountries((prev) => {
        const newCountries = prev.filter((country) => {
          return country.UUid !== UUid;
        });
        return newCountries;
      });
      const alert = {
        success: "success",
        message: response.message,
      }
      raiseAlert(alert);
    })
  }
  if (countries.length === 0) {
    return (
      <div className='user-show'>
        <h1>No hay países en la base de datos</h1>
      </div>
    )
  }
  return (
    <div className='user-show'>
      {countries.length === 0 ?
        <h1>No hay países en la base de datos</h1> : 
        <>
          <h1>Países en la base de datos</h1>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Código</th>
                <th>Código numérico</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {countries.map((country) => {
                return (
                  <tr key={country.id}>
                    <td>{country.name}</td>
                    <td>{country.value}</td>
                    <td>+{country.code_number}</td>
                    <td>
                      <Button variant="danger" onClick={() => {deleteCountry(country.UUid)}}>Eliminar</Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </>
        }
      
    </div>
  )
}