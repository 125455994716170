import React, { useState } from "react";
import "../../styles/login.scss";
import { Navigate, useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import { SuccessButton, CloseButton } from "../../components/Buttons";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";



export function Login(props) {
  const { setIsLogged }  = props;
  const [userData, setUserData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const [notification, raiseAlert] = Notification("success", "");

  if (window.localStorage.getItem("token")) {
    setIsLogged(true);
    return <Navigate to="/home" />; 
  }


  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();
    var { uname, pass } = document.forms[0];
  
    // Find user login info
    // hacer fetch a la api
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/login/`, {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        "username": uname.value,
        "password": pass.value
        })
      })
      .then((response) => response.json())
      .then((json) => { 
        setUserData(json);
      // Compare user info
      if (json.token) {
        window.localStorage.setItem("token", json.token);
        setIsSubmitted(true);
        setIsLogged(true);
      } else {
        json["message"] = json.non_field_errors;
        raiseAlert(json);
      }
    })
  };

  const clickHandlerPassword = () => {
    setShowPass(!showPass);
  };

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Nombre de usuario</label>
          <input type="text" name="uname" required className="login-group"/>
        </div>
        <div className="input-container">
          <label>Contraseña </label>
          <div>
            <input type={showPass ? "text" : "password"} name="pass" required className="login-group"/>
            <i onClick={clickHandlerPassword} className="input-icon">{showPass ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</i>
          </div>
        </div>
        <div className="recovery-password">
            <label>¿Se te olvidó la contraseña? </label>
            <div>
              <a href="/forgot_password">Recuperar aquí</a>
            </div>
          </div>
        <div>
          <SuccessButton variant="contained" type="submit"> Ingresar</SuccessButton>
          <CloseButton variant="contained" onClick={()=> navigate("/")}>Volver</CloseButton>
        </div>
      </form>
    </div>
  );

return (
  <div>
    <div className="app">
      <div className="login-form">
        <div className="title">Iniciar Sesión</div>
        {isSubmitted ? <Navigate replace to="/users" />: renderForm}
      </div>
    </div>
    {notification}
  </div>
  );
}