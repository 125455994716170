import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { GoBackButton, SuccessButton } from "../../components/Buttons";
import { Grid } from "@mui/material";
import { queryAllKAM } from "./utils";
import { KAMTable } from "./KAMTable";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import { Form } from "react-bootstrap";

export function KAMList() {
  
  const [KAM, setKAM] = useState([]);
  const [KAMFillter, setKAMFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);

  useEffect(() => {
    queryAllKAM()
      .then((response) => {
        setKAM(response["data"]);
        setKAMFillter(response["data"]);
        setIsLoading(false);
        setAmount(response.data.length);
      });
  }, [navigate]);

  const goToCreateKAM = () =>{ 
    let path = "/kams/create"; 
    navigate(path);
  };
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    var KAMFillter = KAM.filter(({ user }) => {
      return user.auth.username.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.first_name.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.last_name.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.email.toLowerCase().includes(search.toLowerCase()); 
    });
    setKAMFillter(KAMFillter);
    setAmount(KAMFillter.length);
  };    

  return (
    <div className="user-show-extended">

      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>
        <Grid item xs={12} md={3}>
          <SuccessButton fullWidth onClick={goToCreateKAM} > Crear KAM</SuccessButton>
        </Grid>
      </Grid>
      <h1>KAMs</h1>
      {isLoading? (<LoadingView/>): 
        <>
          <KAMTable kams={KAMFillter} page_size={pageSize} page_index={pageIndex}/>
          Mostrando {amount > 0 ? pageIndex * pageSize+ 1: 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
  );
}
