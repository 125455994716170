import { useState, useEffect  } from "react";
import LawyerTable from "../../components/lawyer/lawyerTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import React from "react";
import { LoadingView } from "../LoadingView";
import { queryFilterLawyersByServices, queryAllServices } from "./utils";
import Multiselect from "multiselect-react-dropdown";
import { Grid } from "@mui/material";
import { Form } from "react-bootstrap";
import { queryAllLawyers } from "../kam/utils";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import fetchData, { Validate } from "../../utils";



export function LawyerList(props) {
  const [userType, setUserType] = useState("");
  const [users, setUsers] = useState([]);
  const [usersFillter, setUsersFillter] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  const handleMultiSelectChange = (e) =>{
    if(e.length){
      queryFilterLawyersByServices(e.map((service)=>service.id)).then((response)=>{
        setUsersFillter(response.data);
        setAmount(response.data.length);
      });
    }
    else{
      setUsersFillter(users);
      setAmount(users.length);
    }
    forceUpdate();
  };

  useEffect(() => {
    queryAllServices().then((response)=>{
      setServices(response.data);
    });
    queryAllLawyers()
      .then((json) => {
        if (json["current_user"].role === "KAM") {
          const lawyersFiltered = json["data"].filter((lawyer) => lawyer.is_accepted === "Aceptado");
          setUsers(lawyersFiltered);
          setUsersFillter(lawyersFiltered);
          setAmount(lawyersFiltered.length);
        } else {
          setUsers(json["data"]);
          setUsersFillter(json["data"]);
          setAmount(json["data"].length);
        }
        
        setIsLoading(false);
      });
    fetchData("/user/current_user", "GET")
      .then((response) => {
        setUserType(response.current_user.role);
      })
  }, [navigate]);

  const onChangeSearch = async(e) => {
    const search = Validate.accent_fold(e.target.value);
    setSearch(search);
    var usersFiltter = users.filter(({ user, score, id, is_accepted }) => {
      const fixUsername = Validate.accent_fold(user.auth.username);
      const fixFistName = Validate.accent_fold(user.auth.first_name);
      const fixLastName = Validate.accent_fold(user.auth.last_name);
      return fixUsername.toLowerCase().includes(search.toLowerCase()) ||
      fixFistName.toLowerCase().includes(search.toLowerCase()) ||
      fixLastName.toLowerCase().includes(search.toLowerCase()) ||
      user.auth.email.toLowerCase().includes(search.toLowerCase()) ||
      score.toString().includes(search.toLowerCase())||
      id.toString().includes(search.toLowerCase()) ||
      is_accepted.toLowerCase().includes(search.toLowerCase());
    
    });
    setUsersFillter(usersFiltter);
    setAmount(usersFiltter.length);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);

  return (
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>

        <Grid item xs={12} md={3}>
          <p>Buscar por experiencias previas:</p>
          <Multiselect showArrow options={services} displayValue="service_name" onRemove={handleMultiSelectChange} onSelect={handleMultiSelectChange} avoidHighlightFirstOption />
        </Grid>



      </Grid>
      
      <h1>Abogados</h1>
      {isLoading? (<LoadingView/>):
        <>
          <LawyerTable userType={userType} lawyers={usersFillter} page_size={pageSize} page_index={pageIndex} />
          Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>}
    
        </div>
  );
}

// LawyerList.propTypes = {
//   userType : PropTypes.string.isRequired,
// };
