import { useState, useEffect  } from "react";
import "../../styles/userIndex.scss";
import "../../styles/projectShow.scss";
import {useNavigate, useParams } from "react-router-dom";
import { queryCreateJob, queryAllLawyers, getClientByID, getClientJobsByProjectID, getProjectByID, querySetProyectAcceptanceStatus, queryUpdateProjectStatus, queryUpdateProject, getPdfProject, queryAllStatus, queryLoadJob } from "./utils";
import { ComboBox } from "../../components/users/multiSelect";
import {Form } from "react-bootstrap";
import {Button,Tooltip, Grid, Typography,FormHelperText, Stepper, Step, StepLabel, ThemeProvider } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GenericalModal, ServiceStack } from "./utilsComponent";
import {LoadingView} from "../LoadingView";
import React from "react";
import PropousalDocument from "../../components/service/pdf";
import { BlobProvider } from "@react-pdf/renderer";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import { PdfField } from "../../components/kam/PdfField";
import { queryAllServices } from "../lawyer/utils";
import { createTheme } from "@mui/system";
import Notification from "../../components/Notification";

const MAX_JOB_DESCRIPTION_LENGTH = 2000;
const MAX_PROPOSAL_TITLE_LENGTH = 100;

export function ProjectShow() {

  const {projectUUid} = useParams();
  const [project, setProject] = useState([]);
  const [auxProject, setAuxProject] = useState([]);
  const [editProject, setEditProject] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [workload, setWorkload] =useState("");
  const [kam, setKam] = useState({});

  const [services, setServices] = useState([]);
  const [currentServiceSelected, setCurrentServiceSelected] = useState("");

  const [proposalObjects, setProposalObjects] = useState([]);

  const [pdf, setPdf] = useState([]);
  const [basePdf, setBasePdf] = useState([]);

  const [firstLoad, setFirstLoad] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [notification, raiseAlert] = Notification("success", "");

  const updateAcceptanceStatus = (status) =>{
    querySetProyectAcceptanceStatus(projectUUid,status).then((response)=>{
      raiseAlert(response);
    });
    project.is_accepted = status;
    auxProject.is_accepted = status;
  };

  const isProposalAbleToUpdate = ()=>{
    if (project.is_accepted !== null)
    {
      let alert = {
        "severity": "error",
        "message" : ["El proyecto no se puede actualizar ya que ha sido aceptado o rechazado."],
      };
      raiseAlert(alert);
    }
    return project.is_accepted === null;
  };

  useEffect(()=>{
    setPrice(currentServiceSelected["suggested_price"]);
  },[currentServiceSelected]);

  const [show,setShow] = useState(false);
  const [showProjectModal,setShowProjectModal] = useState(false);
  const handleOpenConfirmationProposalModal = () => setShow(true);

  const handleOpenConfirmationProjectModal = () => setShowProjectModal(true);

  const handleCompleteProject = () => {
    queryUpdateProjectStatus(projectUUid).then(
      (response)=>{
        raiseAlert(response);
        if (response.success){
          project.new_status = response.new_status;
          project.state = project.new_status.name;
        }
      }
    );
    forceUpdate();
    setShowProjectModal(false);
  };

  const handleAcceptProposal = () => {
    if (isProposalAbleToUpdate()){
      updateAcceptanceStatus(true);
    }
    setShow(false);
    navigate(-1);
  };
  
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const {jobDescription, jobPrice, jobWorkload } = e.target.elements;

    queryCreateJob(projectUUid, jobDescription.value, currentServiceSelected, jobPrice.value, jobWorkload.value)
      .then((response)=>{
        raiseAlert(response);
        if(response.success){
          setProposalObjects([
            ...proposalObjects,
            {
              jobID: response.job_id,
              service: currentServiceSelected.name,
              description: description,
              cost: "",
              price: price,
              startDate: "",
              endDate: "",
              waiting_status: "Mandar propuesta",
              collapsed: false,
              allowToEdit:true,
              disabledButtons: false,
              rating: null,
              new_status: response.new_status,
              workload: workload,
              evaluation: response.job.evaluation,
              lawyerList:[],        
              selectedLawyer: {},
              elegibleLawyers: lawyers,
            }
          ]);
          setCurrentServiceSelected("");
          setDescription("");
          setPrice("");
          setWorkload("");
        }
      });
  };
  const [lawyers, setLawyers] = useState([]);
  
  useEffect(() => {
    getPdfProject(projectUUid, "Commercial proposal")
      .then((response) => {
        setPdf(response["data"]["description"]);
        setBasePdf(response["data"]["base_description"]);
      });

    getProjectByID(projectUUid)
      .then((json) => {
        setProject(json.data);
        setAuxProject(JSON.parse(JSON.stringify(json.data)));
        setKam(json.data.kam);
        return json.data.client;
      })
      .then((clientID) => {
        return getClientByID(clientID);
      })
      .then((clientData) => {
        const clientUser = clientData.data.user.auth;
        setClientEmail(clientUser.email);
        setClientFirstName(clientUser.first_name);
        setClientLastName(clientUser.last_name);
      });
    queryAllLawyers()
      .then((json)=>{
        const lawyersFiltered = json["data"].filter((lawyer) => lawyer.is_accepted === "Aceptado" && lawyer.user.is_email_verified && lawyer.user.auth.is_active);
        setLawyers(lawyersFiltered);
        getClientJobsByProjectID(projectUUid,lawyersFiltered)
          .then((json)=>{
            if(proposalObjects.length === 0 && firstLoad){
              let aux_proposalObjects = [];
              json.data.forEach((element,index) => {
                aux_proposalObjects[index] = {
                  jobID: element.id,
                  service: element.service.service_name,
                  proposalID: element.id,
                  description: element.description,
                  cost: element.cost,
                  price: element.price,
                  startDate: element.start_date,
                  endDate: element.end_date,
                  status: element.status,
                  waiting_status: element.waiting_message,
                  collapsed: false,
                  allowToEdit:false,
                  disabled: element.isDisabled,
                  rating: null,
                  new_status: element.new_status,
                  workload: element.workload,
                  evaluation: element.evaluation,
                  lawyerList: element.lawyerList,     
                  selectedLawyer: element.selectedLawyer,           
                  elegibleLawyers: element.lawyers,
                };
                queryLoadJob(aux_proposalObjects[index]).then((response)=>{
                  aux_proposalObjects[index].elegibleLawyers = response.elegibleLawyers;
                });
              });
              setProposalObjects(aux_proposalObjects);
              forceUpdate();
            }
            if(json.data.length === 0)
            {forceUpdate(); setFirstLoad(false);}
          });
      });
    queryAllServices()
      .then((json) => {
        setServices(json["data"]);
      });
  }, [proposalObjects]);
  const handleDescriptionChange = (e) =>{
    setFirstLoad(false);
    e.preventDefault();
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) =>{
    setFirstLoad(false);
    e.preventDefault();
    setPrice(e.target.value);
  };

  const handleWorkloadChange = (e) =>{
    setFirstLoad(false);
    e.preventDefault();
    setWorkload(e.target.value);
  };
  if (!clientFirstName || !clientLastName || !project.project_name || !project.project_description){
    return (<LoadingView/>);
  }
  console.log(kam);
  return (
    <div>
      <GenericalModal
        show={show}
        footer={
          <>
            <CloseButton onClick={()=>{setShow(false);}} >Cerrar</CloseButton>
            <SuccessButton onClick={handleAcceptProposal} >Enviar</SuccessButton>
          </>}
        title={"¿Está seguro de que el cliente ha aceptado la propuesta comercial? Está acción es irreversible y dará inicio al proyecto asociado."}
        body={
          <>
            <h5>Resumen de la propuesta comercial</h5>
            <Grid container>
              <ol >{proposalObjects.map((proposalObject, index) =>
                <li key={index}>
                  {proposalObject.service}
                  <ul>
                    <b>Abogado a cargo: </b>
                    {proposalObject.selectedLawyer?.fullname ?? "No asignado"}
                  </ul>
                  <ul>
                    <b>Pago al abogado (UF): </b>
                    {proposalObject.selectedLawyer?.cost ?? "No asignado"}
                  </ul>
                  <ul>
                    <b>Cobro al cliente: </b>
                    {proposalObject.price && proposalObject.price !== ""? proposalObject.price : "No asignado"}
                  </ul>
                  <ul>
                    <b>Fecha de Inicio del servicio legal: </b>
                    {proposalObject.startDate && proposalObject.startDate !== ""? proposalObject.startDate : "No asignado"}
                  </ul>
                  <ul>
                    <b>Fecha de Término del servicio legal: </b>
                    {proposalObject.endDate && proposalObject.endDate !== ""? proposalObject.endDate : "No asignado"}
                  </ul>
                </li>
              )}
              </ol>
            </Grid>
          </>
        }
      ></GenericalModal>

      <GenericalModal
        show={showProjectModal}
        footer={
          <>
            <CloseButton onClick={()=>{setShowProjectModal(false);}} >Cerrar</CloseButton>
            <SuccessButton onClick={handleCompleteProject}  > Proceder a la siguiente etapa</SuccessButton>
          </>}
        title={"¿Está seguro de avanzar a la siguiente etapa del proyecto? Esta acción es irreversible."}
        body={
          !(project.new_status.name === "Asignado" && proposalObjects.filter((proposalObject)=>{return proposalObject.new_status.order > project.new_status.order;}).length >= 1) && proposalObjects.filter((proposalObject)=>{return proposalObject.new_status.order <= project.new_status.order;}).length !== 0 ?(<>
          Antes de avanzar en la siguiente etapa del proyecto debe marcar cada servicio en ese nivel de avance.
            <h5>Servicios necesarios para avanzar:</h5>
            <Grid container>
              <ol >{proposalObjects.filter((proposalObject)=>{return proposalObject.new_status.order <= project.new_status.order;}).map((proposalObject, index) =>
                <li key={index}>
                  {proposalObject.service}
                  <ul>
                    <b>Estado del trabajo: </b>
                    {proposalObject.new_status.name}
                  </ul>
                  <ul>
                    <b>Abogado a cargo: </b>
                    {proposalObject.selectedLawyer?.fullname ?? "No asignado"}
                  </ul>
                  <ul>
                    <b>Fecha de Inicio del servicio legal: </b>
                    {proposalObject.startDate && proposalObject.startDate !== ""? proposalObject.startDate : "No asignado"}
                  </ul>
                  <ul>
                    <b>Fecha de Término del servicio legal: </b>
                    {proposalObject.endDate && proposalObject.endDate !== ""? proposalObject.endDate : "No asignado"}
                  </ul>
                </li>
              )}
              </ol>
            </Grid>
          </>):<>Se puede avanzar a la siguiente etapa. </>
        }
      ></GenericalModal>
      <div className="user-show">
        <h1>
          Datos del cliente
        </h1>

        <Form onSubmit={onSubmitHandler}>
          <div className="user-show-info">
            <b>
              <Form.Label>Nombre: </Form.Label>
            </b>
            {clientFirstName} 
          </div>
          <Form.Group>
            <div className="user-show-info">
              <b>
                <Form.Label>Apellido: </Form.Label>
              </b>
              {clientLastName}
            </div>
          </Form.Group>
          <Form.Group>
            <div className="user-show-info">
              <b>
                <Form.Label>Email: </Form.Label>
              </b>
              {clientEmail}
            </div>
          </Form.Group>
        </Form>
      </div>
      
      <div className="user-show">
        <h1>
          {project.is_accepted? "Proyecto": "Propuesta Comercial"}
        </h1> 
        {editProject ? (
          <Form>
            <Form.Label className = "form-label"> {project.is_accepted? "Título del proyecto:": "Título de la propuesta comercial:"} </Form.Label>
            <Form.Control type="text"
              maxLength={MAX_PROPOSAL_TITLE_LENGTH}
              defaultValue={auxProject.project_name??""}
              onChange={(e)=>{
                auxProject.project_name = e.target.value;
                forceUpdate();}
              }
            />
            <FormHelperText error={auxProject.project_name.length === MAX_PROPOSAL_TITLE_LENGTH}>{auxProject.project_name.length}/{MAX_PROPOSAL_TITLE_LENGTH}</FormHelperText>
            <Form.Label className = "form-label"> {project.is_accepted? "Descripción del proyecto:": "Descripción de la propuesta comercial:"}</Form.Label>
            <Form.Control 
              as="textarea"
              sx={{ width: "80%" }}
              rows={8}
              value={auxProject.project_description??""}
              onChange={(e)=>{
                auxProject.project_description = e.target.value;
                forceUpdate();}}
            />
          </Form>
        ) : (
          <div>
            <h1>
              {project.project_name}
            </h1>
            {project.project_description}
          </div>
        )}

        {(project.state === "Sin Concretar") && (<Grid container>
          <Grid item xs={12} md={6} marginY={"20px"}>
            <SuccessButton
              disabled={project.project_description.replaceAll(" ", "") === "" || project.project_name.replaceAll(" ", "") === ""}
              onClick={()=> {
                setEditProject(!editProject);
                if(editProject){
                  queryUpdateProject(project.id,auxProject.project_name,auxProject.project_description)
                    .then(
                      (response)=>{
                        raiseAlert(response);
                      }
                    );
                  setProject(JSON.parse(JSON.stringify(auxProject)));
                }
              }}>
              {editProject?"Guardar":"Editar"}
            </SuccessButton>
            { editProject && (<>
              <Button
                variant="contained" 
                onClick={()=>{
                  setAuxProject(JSON.parse(JSON.stringify(project)));
                  setEditProject(!editProject);
                }}
              >
          Volver sin guardar
              </Button>
            </>)}
          </Grid>
        </Grid>)}
        <div className="stepper">
          <ProjectState projectStatus={project.new_status}></ProjectState>
        </div>
        {!project.is_accepted?  (
          <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProposalModal}> Marcar como aceptada</SuccessButton>
        ):
          project.state === "Pendiente Asignación" && <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProjectModal}> Marcar como Asignado</SuccessButton> ||
          project.state === "Asignado" && <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProjectModal}> Marcar como En Progreso</SuccessButton> ||
          project.state === "En Progreso" && <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProjectModal}> Marcar como Entregado</SuccessButton>||
          project.state === "Entregado" && <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProjectModal}> Marcar como Completo / Pendiente Pago</SuccessButton>||
          project.state === "Completo / Pendiente Pago" && <SuccessButton disabled={editProject} onClick={handleOpenConfirmationProjectModal}> Terminar Proyecto</SuccessButton>
        }

      </div>

      {project.is_accepted === null && (
        <div className="user-show"> 
          <h1>
            Añadir Servicio Legal 
          </h1>
          <ComboBox
            setCurrentOption={setCurrentServiceSelected}
            value = {currentServiceSelected??""}
            id = "combo-box-demo"
            options={services.map((service) => {return {"name": service.service_name, "UUid": service.UUid, "ID": service.id, "suggested_price": service.price};})}
            category="Servicios Legales"
          ></ComboBox>
          &nbsp; 
          <Form onSubmit={onSubmitHandler}>

            <Form.Control type="hidden"
              id="jobService"
              value={currentServiceSelected??""}
            />
            <Form.Control type="hidden"
              id="projectID"
              value={project.id??""}
            />
            
            <Form.Label className = "form-label">Descripción detallada del servicio legal: </Form.Label>
            <Form.Control as="textarea"
              id="jobDescription"
              maxLength={MAX_JOB_DESCRIPTION_LENGTH}                    
              sx={{ width: "80%" }}
              label="Descripción del servicio legal"
              rows={2}
              placeholder="Por favor complementar con información adicional relevante"
              onChange = {handleDescriptionChange}
              value={description??""}
            />
            <FormHelperText error={description.length===MAX_JOB_DESCRIPTION_LENGTH}>{description.length}/{MAX_JOB_DESCRIPTION_LENGTH}</FormHelperText>

            <Grid container>
              <Grid item xs={12} md={6} marginBottom={"20px"}>
                <Form.Label className = "form-label"> Precio por trabajo (UF): </Form.Label>
                <Form.Control type="number"
                  id="jobPrice"
                  label="Valor del trabajo para el cliente"
                  placeholder="Indique el precio asociado al trabajo"
                  onChange={handlePriceChange}
                  value={price??""}
                />
              </Grid>
              <Grid item xs={12} md={6} marginBottom={"20px"}>
                <Form.Label className = "form-label"> Carga laboral estimada en horas: </Form.Label>
                <Form.Control type="number"
                  id="jobWorkload"
                  label="Carga de trabajo aproximada para el abogado"
                  placeholder="Indique la carga laboral asociada"
                  onChange={handleWorkloadChange}
                  value={workload??""}
                />
              </Grid>
            </Grid>
            <Tooltip
              title={
                <Typography fontSize={20}>
                  {currentServiceSelected === null
                    ? "Seleccione el servicio legal que desea agregar a la propuesta"
                    : "Agregar servicio legal a la propuesta"}{" "}
                </Typography>
              }
              placement="right"
            >
              <span>
                <Button
                  variant="contained"
                  disabled={currentServiceSelected === "" || description === "" ||  price === "" || workload == ""}
                  onClick={() => {}}
                  style={{color:"secondary"}}
                  type="submit" 
                >
                Agregar servicio legal <AddCircleIcon></AddCircleIcon>
                </Button>
              </span>
            </Tooltip>
          </Form>
        </div>
      )}
      

      { proposalObjects.length>=1 && (
        <div className="user-show">
          <ServiceStack
            project={project}
            proposal_objects={proposalObjects}
            set_proposal_objects={setProposalObjects}
            category="Servicios Legales"
            raise_alert = {raiseAlert}
            force_update={forceUpdate}
          ></ServiceStack>
        </div>
      )}
      { !project.is_accepted && (pdf.map((pdfField, index) => {
        return (<div key={index}>
          <PdfField title={pdfField.field} description={pdfField.field_description}
            allFields={pdf} setAllFields={setPdf} index={index} pdfField={pdfField}
            baseDescription={basePdf[index].field_description} order={pdfField.order}
            raiseAlert={raiseAlert}/>
        </div>
        );}))
      }

      <div className="button-pdf">
        <BlobProvider document={ 
          <PropousalDocument nameProject={project.project_name} 
            descriptionProject={project.project_description} 
            clientFirstName={clientFirstName} 
            clientLastName={clientLastName} 
            clientEmail={clientEmail} 
            proposalObjects={proposalObjects}
            allFields={pdf}
            kam={kam} />
        }>
          {({ url }) => {
            return (
              <SuccessButton onClick={() => {window.open(url, "_blank");}} > Ver PDF </SuccessButton>
            );
          }}
        </BlobProvider>
      </div>
      {notification}
    </div>
            
  );
}

function ProjectState(props) {
  const {projectStatus} = props;
  const [allStatus, setAllStatus] = useState([]);
  useEffect(()=>{
    queryAllStatus().then((response)=>{
      setAllStatus(response.data);
    });
  },[]);
  const theme = createTheme({
    typography: {
      body2: {
        fontSize: 10,
      },
    },
  });
  return (
    <Stepper activeStep={projectStatus.order === 7 ?projectStatus.order:  projectStatus.order - 1} alternativeLabel>
      {allStatus.map((status) => (
        <Step key={status.order}>
          <StepLabel>
            <ThemeProvider theme={theme}>
              <Typography variant="body2">{status.name}</Typography>
            </ThemeProvider>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}