import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/userShow.scss";
import React from "react";
import { LoadingView } from "../LoadingView";
import { ProjectsTable } from "../../components/kam/ProjectsTable";
import fetchData from "../../utils";


export function CompletedProjects(props) {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData("/user/current_user/", "GET", null)
      .then(async(json) => {
        await onSetProject(json["current_user"].id);
      });
  }, []);
      
  const onSetProject = async (id) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryProjectsByKAMID`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        "id" : id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          
          throw new Error(response.status);
        } else {
          return response.json();
        }
      })
      .then((json) => {
        setProjects(json["data"]);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.message === "401") {
          window.localStorage.removeItem("token");
          navigate("/");
        } else if (error.message === "403") {
          navigate(-1);
        }
      });
  };

  if (!loading) {
    return (
      <div>
        <div className="user-show">
          <h1>Proyectos completados</h1>
          <ProjectsTable
            filteredProjects = {projects.filter((project)=> {return project.is_accepted && project.new_status.order === 7;} )}
            actionHeader = "Ver Proyectos"
            actionName = "Ver Proyecto"
            baseLink = {"/proposals/commercial_contracts/"}
          ></ProjectsTable>
        </div>
      </div>
    );
  }
  return (
    <div>
      <LoadingView />
    </div>
  );
}