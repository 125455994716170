import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { SuccessButton } from "../../components/Buttons";
import Notification from "../../components/Notification";
import { Button } from "react-bootstrap";
import "../../styles/login.scss"
import fetchData from "../../utils";
import { CircularProgress } from "@mui/material";


export function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [notification, raiseAlert] = Notification("success", "");
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  if (window.localStorage.getItem("token")) {
    return <Navigate to="/home" />; 
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingSendEmail(true);
    fetchData("/mailer/send_recovery/", "POST", {email: email})
    .then((response) => {
      response["severity"] = "success";
      raiseAlert(response);
      setLoadingSendEmail(false);
    })
    .catch((error) => {
      raiseAlert(error);
      setLoadingSendEmail(false);
    });
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const search = e.target.value;
    setEmail(search);
  };
  return (
  <div>
    <div className="app">
      <div className="login-form">
        <div className="title">Recupera tu contraseña</div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>Ingrese su correo electrónico</label>
              <input type="text" name="uname" required className="login-group" value={email}
              onChange={handleOnChange}/>
            </div>
            <div className="down-buttons">
              <SuccessButton variant="contained" type="submit" disabled={loadingSendEmail}> 
                Enviar
                {loadingSendEmail &&
                  <CircularProgress size={20} color="inherit" />
                }
              </SuccessButton>
              <Button variant="primary" href="/login">Volver</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
    {notification}
  </div>
  )
}