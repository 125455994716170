import { useState, useEffect, useCallback  } from "react";
import "../../styles/userIndex.scss";
import { getClientByID, getProjectByID } from "../kam/utils";
import { getJobsByID, queryProposalsByUser, queryWorkLoad } from "./utils";
import React from "react";
import { LoadingView } from "../LoadingView";
import { queryAcceptProposal, queryAllProposalRejectReasons, queryCreateRejectReasons, queryRejectProposal, getRejectReasonsByProposalID } from "./utils";
import Notification from "../../components/Notification";
import { Multiselect } from "multiselect-react-dropdown";
import "../../styles/userShow.scss";
import { JobInfoModal, LawyerTable } from "./lawyerTable";
import { CloseButton, RejectButton, SuccessButton } from "../../components/Buttons";
import { GenericalModal, RenderOverWorkWeeks } from "../kam/utilsComponent";

export function LawyerProposalJobs() {

  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [notification, raiseAlert] = Notification("success", "");

  const [show, setShow] = useState(false);  
  const handleCloseJobModal = () => setShow(false);
  const handleShowJobModal = () => setShow(true);

  const [showRejectModal, setShowRejectModal] = useState(false);  
  const handleCloseRejectModal = () => setShowRejectModal(false);
  const handleShowRejectModal = () => setShowRejectModal(true);

  const [jobInfo, setJobInfo] = useState([]);  
  const [actualIndex, setActualIndex] = useState(-1);  
  const [rejectInfo,setRejectInfo] = useState([]);

  const [rejectSelectedReasons, setRejectSelectedReasons] = useState([]);
  const [rejectReasons, setRejectReasons] = useState([]);

  const [showExceedWorkLoadModal, setShowExceedWorkLoadModal] = useState(false);  
  const handleCloseExceedWorkLoadModal = () => setShowExceedWorkLoadModal(false);
  const handleShowExceedWorkLoadModal= () => setShowExceedWorkLoadModal(true);

  const [weeklyWorkload, setWeeklyWorkload] = useState({
    "exceed_weeks": [],
    "service_workload": null,
    "lawyer_max_workload": null,
  });


  const handleColor = (accepted, selected, expired) =>{
    if (expired === true) return "#fcb7af";
    if (accepted === false) return "#fcb7af"; // 3
    if (accepted === null && selected === null) return ""; // 1
    if (accepted && selected === null) return ""; //  1 accepted = true || accepted = null
    if (selected === false) return "#fcb7af"; //  2 accepted = true || accepted = null
    if (selected === true && accepted === true) return "#b0f2c2"; //  1 accepted = true and accepted = true

  };
  useEffect(() => {

    const handleStatus = (accepted, selected, expired) =>  {
      if (expired === true) return "Propuesta Cerrada";
      if (accepted === false) return "Rechazada"; // 3
      if (accepted === null && selected === null) return "Esperando tu respuesta"; // 1
      if (accepted && selected === null) return "Esperando respuesta de KAM"; //  1 accepted = true || accepted = null
      if (selected === false) return "Propuesta Cerrada"; //  2 accepted = true || accepted = null
      if (selected === true && accepted === true) return "Seleccionado"; //  1 accepted = true and accepted = true
    };

    queryProposalsByUser().then(
      (response)=>{
        const data = response.data;
        if (data.length > 0) {
          data.forEach((proposal,index) => {
            getJobsByID(proposal.job_id).then(
              (job) =>{
                let aux_jobs = jobs;
                if (job.data){
                  aux_jobs[index] = job.data;
                  aux_jobs[index].originalIndex = index;
                  aux_jobs[index].color = handleColor(proposal.accepted, job.data.isThisLawyerSelected, job.data.isThisOfferExpired);
                  aux_jobs[index].acceptance_status = handleStatus(proposal.accepted, job.data.isThisLawyerSelected, job.data.isThisOfferExpired);
                  aux_jobs[index].service = job.data.service?.service_name;
                  aux_jobs[index].proposal_id = proposal.id;
                  aux_jobs[index].accepted = proposal.accepted;
                  aux_jobs[index].cost = proposal.cost;
                  aux_jobs[index].isThisLawyerSelected = job.data.isThisLawyerSelected;
                  aux_jobs[index].isThisOfferExpired = job.data.isThisOfferExpired;
                  const projectID = job.data.project_id;
                  getProjectByID(projectID).then((response) => {
                    aux_jobs[index].project_name = response.data.project_name;
                    aux_jobs[index].project_description = response.data.project_description;
                    getClientByID(response.data.client).then(
                      (response) =>{
                        aux_jobs[index].client_name = response.data.user.auth.first_name + " " + response.data.user.auth.last_name;
                      }
                    );
                    setJobs(aux_jobs); 
                  }).then(()=>{
                    setIsLoading(false);
                    forceUpdate();
                  });
                }
              }
            );
          });
        } else {
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(()=>{
    queryAllProposalRejectReasons().then((response)=>{
      setRejectReasons(response.data);
    });}
  ,[]);

  const handleMultiSelectChange = (e) =>{
    e.forEach((element,index) => {
      let aux_rejectReasons = rejectSelectedReasons;
      aux_rejectReasons[index] = element;
      setRejectSelectedReasons(aux_rejectReasons);
    });
  };

  const onShowMoreInfo = (e)=>{
    setJobInfo(jobs[e.target.value]);
    setActualIndex(e.target.value);
    getRejectReasonsByProposalID(jobInfo.proposal_id??jobs[e.target.value].proposal_id).then(
      (response) =>{
        setRejectInfo(response.data);
      }
    ).then(()=>{
      handleShowJobModal();
    });
  };

  const handleAcceptProposal = () => {
    queryWorkLoad(jobs[actualIndex].id).then(
      (response)=>{
        handleCloseJobModal();
        if(!response.exceed_workload){
          queryAcceptProposal(jobInfo.proposal_id);
          if (jobs[actualIndex].accepted   !== false)
          {
            jobs[actualIndex].accepted = true;
            jobs[actualIndex].color = handleColor(true);
          }
          handleNotification();
        }
        else{

          setWeeklyWorkload({
            "exceed_weeks": response.exceed_weeks,
            "service_workload": response.service_workload,
            "lawyer_max_workload": response.lawyer_max_workload,
        
          });
          handleShowExceedWorkLoadModal();
        }
      }
    );
  };

  const forceAcceptProposal = ()=>{
    queryAcceptProposal(jobInfo.proposal_id);
    if (jobs[actualIndex].accepted   !== false)
    {
      jobs[actualIndex].accepted = true;
      jobs[actualIndex].color = handleColor(true);
    }
    handleNotification();
    handleCloseExceedWorkLoadModal();
  };

  const handleConfirmRejection = () => {
    handleShowRejectModal(true);
    setShow(false);
  };

  const handleRejectProposal = (e) => {
    e.preventDefault();
    queryRejectProposal(jobInfo.proposal_id);
    queryCreateRejectReasons(rejectSelectedReasons, jobInfo.proposal_id);
    if (jobs[actualIndex].accepted !== true)
    {
      jobs[actualIndex].accepted = false;
      jobs[actualIndex].color = handleColor(false);
    }      
    handleCloseJobModal();
    handleNotification();
    handleCloseRejectModal();
    setRejectSelectedReasons([]);
  };

  const handleNotification = () =>{
    let alert = {"severity": "success", "message": ["El estado se ha actualizado correctamente"] };            
    raiseAlert(alert);
  };

  if(isLoading){
    return (
      <LoadingView/>
    );
  }

  return (
    <div>
      <div className='user-table'>
        <GenericalModal
          show={showRejectModal}
          title={jobInfo.project_name}
          body={
            <>
              Selecciones el motivo por el cual rechaza la propuesta
              <Multiselect showArrow options={rejectReasons} displayValue="reason" onSelect={handleMultiSelectChange} avoidHighlightFirstOption/>
            </>}
          footer={(
            <>
              <RejectButton onClick={handleRejectProposal} value={jobInfo.id}>Rechazar propuesta</RejectButton>
              <CloseButton onClick={handleCloseRejectModal}>Cerrar</CloseButton>
            </>
          )}
        /> 
        {showExceedWorkLoadModal && (        
          <GenericalModal
            show={showExceedWorkLoadModal}
            title="Alerta de Exceso de carga laboral"
            body={
              <>
                <p>Creemos que tendrás un exceso de carga laboral si aceptas este trabajo ({jobInfo.service}) según tu disponibilidad ({weeklyWorkload.lawyer_max_workload} horas semanales). La información en detalle es la siguiente:</p>
                <b>Carga laboral de {jobInfo.service}: </b>
                <ul>{weeklyWorkload.service_workload} horas</ul>
                <RenderOverWorkWeeks data={weeklyWorkload.exceed_weeks}/>
              </>
            }
            footer={(
              <>
                <SuccessButton onClick={forceAcceptProposal} value={jobInfo.id}>Aceptar propuesta</SuccessButton>
                <CloseButton onClick={handleCloseExceedWorkLoadModal}>Cerrar</CloseButton>
              </>
            )}
          /> )
        }

        <JobInfoModal
          show={show}
          jobInfo={jobInfo}
          rejectInfo={rejectInfo}
          handleAcceptProposal={handleAcceptProposal}
          handleConfirmRejection={handleConfirmRejection}
          handleCloseJobModal={handleCloseJobModal}
        />
        <div className='user-show-extended'>
          <h1>Propuestas de trabajo por revisar</h1>
          <LawyerTable
            filteredJobs = {jobs.filter((job)=> job !== null && job.accepted ===null &&  job.isThisLawyerSelected !== false && job.isThisOfferExpired === false)}
            onShowMoreInfo = {onShowMoreInfo}
          />
        </div>

        <div className='user-show-extended'>
          <h1>Propuestas de trabajo (histórico)</h1>
          <LawyerTable
            filteredJobs = {jobs.filter((job)=> job !== null && (job.accepted !==null || job.isThisLawyerSelected === false || job.isThisOfferExpired === true))}
            onShowMoreInfo = {onShowMoreInfo}
          />
        </div>

      </div>
      {notification}
    </div>
  );
}
