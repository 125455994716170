import React, { useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import { IconContext } from 'react-icons'
import { SidebarDataLawyer, SidebarDataAdmin, SidebarDataKAM } from './SidebarData'
import { Badge } from "@mui/material";
import "../styles/sideBar.scss"
import { queryGetNotificationNumber } from "./utils";
import fetchData from "../utils";


export default function SideBar(props) {
  const { sidebar, updateSiderbar } = props;
  const [notificationAmount, setNotificationAmount] = useState(0);
  const [user, setUser] = useState(null);

  const showSidebar = () => props.setSidebar(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchData(`/user/current_user/`, "GET")
        .then((json) => {
          setUser(json["current_user"]);
        });
    }
  }, [updateSiderbar]);
  useEffect(() => {
    if ((user && user.role === "Lawyer") || (user && user.role === "KAM" && user.kam.is_working_as_lawyer)){
      queryGetNotificationNumber(user.id).then(
        (response) =>{
          setNotificationAmount(response.count);
        }
      );
    }
  },[user, updateSiderbar]);

  return (
    <>
      <IconContext.Provider value={{color: 'black'}}>
        <nav className={sidebar ? 'nav-menu active': 'nav-menu'}>
          <div className="sidebar-sticky">
            <ul className='nav-menu-items'>
              <li className='navbar-toggle'>
                <Link to="#" className='menu-bars'>
                  <AiIcons.AiOutlineClose onClick={showSidebar}/>
                </Link>
              </li>
            
              {user && user.role === "Admin" && (
                SidebarDataAdmin.map((item, index) => {
                  return(<HoverLink user={user} item={item} index={index} key={index}/>);
                })
              )}
              {user && user.role === "KAM" && (
                SidebarDataKAM.map((item, index) => {
                  return(<HoverLink user={user} item={item} index={index} key={index}/>);
                })
              )}
              {((user && user.role === "Lawyer") || (user && user.role === "KAM" && user.kam.is_working_as_lawyer)) && (
                <>
                <li className='nav-text' key={0}>
                  <NavLink to={`/propuestas/`}>
                    <IoIcons.IoIosPaper />
                    
                    <Badge badgeContent={notificationAmount} color="primary" invisible={notificationAmount === 0}>
                      <span style={{marginLeft:0}}> Propuestas </span>
                    </Badge>
                  </NavLink>
                </li>
                {
                  SidebarDataLawyer.map((item, index) => {
                    return(
                    <HoverLink user={user} item={item} index={index}/>
                    )
                  })
                }
                </>
                )}
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  )
}

function HoverLink(props) {
  const { item, user, index } = props;
  const [subNav, setSubNav] = useState(false);
  const showSubNav = () => setSubNav(!subNav);
  const location = useLocation();
  const [isSubNavActive, setIsSubNavActive] = useState(false);
  const path = item.params === ":id" ? `${item.path}/${user.id}` : item.path;

  useEffect(() => {
    if (item.subNav) {
      
      setIsSubNavActive(
        item.subNav.some((subItem) => {
          return location.pathname.includes(subItem.path);
        })
      );
    }
  }, [location, item.subNav]);
  return (
    <>
      {item.subNav ? (
        <>
          <li className={item.cName} key={index}>
            <Link to={item.path} className={"dropdown-btn" + (!isSubNavActive ? " unselected" : " active")}
            onClick={item.subNav && showSubNav}>
              <div>
                {item.icon}
                <span className="dropdown-span">{item.title}</span>
              </div>
              <div>
                {item.subNav && subNav
                  ? item.iconOpened
                  : item.subNav
                  ? item.iconClosed
                  : null}
              </div>
            </Link>
          </li>
        
          {subNav && item.subNav.map((subItem, indexSub) => {
            return(
                <NavLink to={subItem.path} className="dropdown-link">
                  {subItem.icon}
                  <span>{subItem.title}</span>
                </NavLink>
            )}
          )}
        </>
        
      ) : (
      <li className={item.cName} key={index}>
        <NavLink to={path}>
          {item.icon}
          <span>{item.title}</span>
        </NavLink>
      </li>)}
    </>
  )
}
