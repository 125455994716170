import { useState } from "react";
import "../../styles/userIndex.scss";
import React from "react";
import {Form, Button } from "react-bootstrap";
import { SuccessButton } from "../../components/Buttons";
import { Grid } from "@mui/material";
import { queryAllLawyers } from "./utils";
import { useEffect } from "react";
import { ComboBox } from "../../components/users/multiSelect";
import { useNavigate } from "react-router-dom";

export function CreateKAM() {
  const [currentLawyer, setCurrentLawyer] = useState();
  const [lawyers, setLawyers] = useState([]);
  const navigate = useNavigate();
  const onSubmit = (e)=>{
    e.preventDefault();
    const user = lawyers.filter((lawyer) => lawyer.id === currentLawyer.ID)[0].user;
    user.role = "KAM";
    const body =  JSON.stringify({"user":user});
    fetch(`${process.env.REACT_APP_BACKEND_URL}/user/queryUserByID/${user.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: body,
    }).then(navigate(-1));
  };

  useEffect(()=>{
    queryAllLawyers()
    .then((response) => {
        const lawyersFiltered = response.data.filter((lawyer) => lawyer.is_accepted === "Aceptado" && lawyer.user.is_email_verified);
        setLawyers(lawyersFiltered);
      });
  }, []);

  return (
    <div className="user-show" >
      <Form onSubmit={onSubmit}>
        <h1>KAM</h1>  
        <div style={{  "text-align": "center"}}>Seleccione el abogado que desea transformar a KAM (solo aparecen abogados que han sido aceptados y tienen su mail verificado).</div>
        <Grid container marginY={"10px"}>
          <Grid item md={4}></Grid>
          <Grid item xs={12} md={4} marginY={"10px"}>
            <ComboBox
              setCurrentOption={setCurrentLawyer}
              value = {currentLawyer??""}
              options={lawyers.map((lawyer) => {return {"name": lawyer.user.auth.first_name + " " + lawyer.user.auth.last_name, "UUid": lawyer.UUid, "ID": lawyer.id};})}
              category="Abogados"
            ></ComboBox>
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Button variant="primary" onClick={() => navigate(-1)}>Volver atrás</Button>
          </Grid>
          <Grid item md={4}>
            <SuccessButton type="submit" > Transformar a KAM</SuccessButton>
          </Grid>
        </Grid> 
      </Form>
    </div>
  );
}