import { Pagination } from "react-bootstrap";
import React from "react";
import { MenuItem, Select } from "@mui/material";

export function CustomPagination(props) {
  const { pageIndex, maxPageIndex, setPageIndex } = props;
  return (
    <>
      <Pagination>
        <Pagination.First onClick={()=>{setPageIndex(0);}} disabled={pageIndex===0}/>
        <Pagination.Prev  onClick={()=>{setPageIndex(pageIndex-1);}} disabled={pageIndex===0}/>

        {pageIndex >= 2 && (<Pagination.Ellipsis/>)}
        {pageIndex >= 1 && (<Pagination.Item onClick={()=>{setPageIndex(pageIndex-1);}}>{pageIndex}</Pagination.Item>)}
        <Pagination.Item active>{pageIndex + 1}</Pagination.Item>
        {pageIndex <= maxPageIndex - 1 && (<Pagination.Item onClick={()=>{setPageIndex(pageIndex+1);}} >{pageIndex + 2}</Pagination.Item>)}
        {pageIndex <= maxPageIndex - 2 && (<Pagination.Ellipsis/>)}

        <Pagination.Next onClick={()=>{setPageIndex(pageIndex+1);}} disabled={pageIndex===maxPageIndex}/>
        <Pagination.Last  onClick={()=>{setPageIndex(maxPageIndex);}} disabled={pageIndex===maxPageIndex}/>
      </Pagination>
    </>
  );
}

export function RowsPerPage(props){
  const {pageSize,setPageSize} = props;

  return(
    <div >
    Cantidad de filas por página 
      <Select
        sx={{"marginLeft":"10px"}}
        labelId="RowsPerPage"
        id="RowsPerPage"
        value={pageSize}
        label="RowsPerPage"
        onChange={(e)=>{setPageSize(e.target.value);}}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </div>
  );
}