import { useEffect, useState } from "react";
import "../../styles/userIndex.scss";
import React from "react";
import {Form } from "react-bootstrap";
import { SuccessButton } from "../../components/Buttons";
import { Autocomplete, Grid,TextField,Typography } from "@mui/material";
import Notification from "../../components/Notification";
import { queryCreateUser, queryCreateEnterprise, queryCreateNaturalPerson,queryAllClientType } from "./utils.js";
import {Validate} from "../../utils";


export function CreateClient() {
  const[currentEnterpriseName, setCurrentEnterpriseName] = useState("");
  const[currentEnterpriseSocialReason, setCurrentEnterpriseSocialReason] = useState("");
  const[currentGiro, setCurrentGiro] = useState("");
  const[enterpriseRut, setEnterpriseRut] = useState("");
  const [notification, raiseAlert] = Notification("success", "");

  const [user, setUser] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "Impacto.Legal1!",
    confirmPassword: "Impacto.Legal1!",
    phone: "",
    type: "Client",
  });

  const [didFirstSummit, setDidFirstSummit] = useState(false);
  const [personFirstName, setPersonFirstName] = useState("");
  const [personLastName, setPersonLastName] = useState("");
  const [personRut, setPersonRut] = useState("");
  const [representativeType, setRepresentativeType] = useState("");
  const [allClientType, setAllClientType] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (e)=>{
    e.preventDefault();
    setDidFirstSummit(true);
    if(
      (representativeType.name === "Persona Natural" &&  !Validate.text(personFirstName))||
      (representativeType.name === "Persona Jurídica" && !Validate.text(currentEnterpriseName))
    ){
      let alert  = {"severity": "error", "message": [] };
      alert["message"].push("Corrija los campos incorrectos"); 
      raiseAlert(alert);
      return;
    }
    queryCreateUser(user)
    .then((response)=>{
      if(response.success) {
        setUser({
          username: "",
          email: "",
          firstName: "",
          lastName: "",
          password: "Impacto.Legal1!",
          confirmPassword: "Impacto.Legal1!",
          phone: "",
          type: "Client",
        })
        setCurrentEnterpriseName("");
        setCurrentGiro("");
        setEnterpriseRut("");  
        setCurrentEnterpriseSocialReason("");
        setPersonFirstName("");       
        setPersonLastName("");
        setPersonRut("");       
        setDidFirstSummit(false);
      }
      if(representativeType.name === "Persona Natural"){
        queryCreateNaturalPerson(personFirstName, personLastName, personRut, response.clientID);
      }
      if(representativeType.name === "Persona Jurídica"){
        queryCreateEnterprise(currentEnterpriseName, currentGiro, enterpriseRut, response.clientID, currentEnterpriseSocialReason);
      }
      raiseAlert(response);
    })
    .catch((error)=>{
      let alert  = {"severity": "error", "message": [] };
      alert["message"].push(error.message);
      raiseAlert(alert);
    });
  };
  useEffect(()=>{
    queryAllClientType().then((response)=>{setAllClientType(response.data);});
  },[]);
  return (
    <div className="user-show-extended">
      <Form onSubmit={onSubmit}>
        <h1>Contacto</h1>  
        <Grid container marginY={"10px"}>
          <Grid item xs={12} md={6} marginY={"10px"}>
            <Form.Group>
              <Typography align="center">
              Nombre
              </Typography>
              <Form.Control as="input"
                value={user.firstName}
                onChange={handleChange}
                name="firstName"
                sx={{ width: "80%" }}
                placeholder="Por favor complementar el nombre del cliente"
                isValid = {Validate.text(user.firstName)}
                isInvalid = {didFirstSummit && !Validate.text(user.firstName)}
              />
              <Form.Control.Feedback type="valid">
                Bien!
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor ingrese un nombre válido
              </Form.Control.Feedback>
            </Form.Group>

          </Grid>
          <Grid item xs={12} md={6} marginY={"10px"}>
            <Form.Group>
              <Typography align="center">
            Apellido
              </Typography>
              <Form.Control as="input"
                value={user.lastName}
                onChange={handleChange}
                sx={{ width: "80%" }}
                name="lastName"
                isValid = {Validate.text(user.lastName)}
                isInvalid = {didFirstSummit && !Validate.text(user.lastName)}
                placeholder="Por favor complementar el apellido del cliente"
              />
              <Form.Control.Feedback type="valid">
                Bien!
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor ingrese un apellido válido
              </Form.Control.Feedback>
            </Form.Group>
          </Grid>
          <Grid item xs={12} md={6} marginY={"10px"}>
            <Form.Group>
              <Typography align="center">
                Nombre de usuario
              </Typography>
              <Form.Control as="input"
                value={user.username}
                onChange={handleChange}
                sx={{ width: "80%" }}
                name="username"
                placeholder="Por favor complementar el usuario del cliente"
                isValid = {Validate.text(user.username)}
                isInvalid = {didFirstSummit && !Validate.text(user.username)}
              />
              <Form.Control.Feedback type="valid">
                Bien!
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor ingrese un nombre de usuario válido
              </Form.Control.Feedback>
            </Form.Group>
          </Grid>
          <Grid item xs={12} md={6} marginY={"10px"}>
          
            <Form.Group>
              <Typography align="center">
                Correo
              </Typography>
              <Form.Control type="email"
                value={user.email}
                onChange={handleChange}
                name="email"
                sx={{ width: "80%" }}
                placeholder="name@example.com"
                isValid={Validate.email(user.email)}
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12} md={6} marginY={"10px"}>
            <Form.Group>
              <Typography align="center">
                Telefono
              </Typography>
              <Form.Control as="input"
                value={user.phone}
                onChange={handleChange}
                sx={{ width: "80%" }}
                name="phone"
                placeholder="Ingrese en el siguiente formato: 912345678"
              />
            </Form.Group>
          </Grid>
          <Grid item xs={12} md={12} marginY={"10px"}>
            <Autocomplete
              onChange={(event, value) => {setRepresentativeType(value??"");}}
              id={1}
              options={allClientType}
              getOptionLabel={(option) => option.name ?? option }
              value = {representativeType?.name ?? ""}
              renderInput={(params) => <TextField {...params} label = {"Tipo de cliente"} />}
            />
          </Grid> 
        </Grid> 


        {representativeType?.name === "Persona Jurídica" && ( <>
          <h1>Empresa (Puede guardar los siguientes datos más adelante)</h1>
          <Grid container marginY={"10px"}>
            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
            Nombre
                </Typography>
                <Form.Control 
                  type="text"
                  value={currentEnterpriseName}
                  onChange={(e)=>setCurrentEnterpriseName(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Por favor complementar el nombre de la empresa"
                  isValid={currentEnterpriseName.trim().length>0}
                  isInvalid={didFirstSummit && currentEnterpriseName.trim().length===0}
                />
                <Form.Control.Feedback type="valid">
              Bien!
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                Por favor ingrese un nombre válido
                </Form.Control.Feedback>
              </Form.Group>
            </Grid>
            <Grid item xs={12} md={6} marginY={"10px"}>


              <Typography align="center">
            Razón Social
              </Typography>
              <Form.Group>
                <Form.Control as="input"
                  value={currentEnterpriseSocialReason}
                  onChange={(e)=>setCurrentEnterpriseSocialReason(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Por favor complementar la razón social de la empresa"
                />
              </Form.Group>


            </Grid>  
            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
            Giro
                </Typography>
                <Form.Control as="input"
                  value={currentGiro}
                  onChange={(e)=>setCurrentGiro(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Por favor complementar giro de la empresa"
                />
              </Form.Group>
            </Grid>

            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
            Rut
                </Typography>
                <Form.Control as="input"
                  value={enterpriseRut}
                  onChange={(e)=>setEnterpriseRut(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Rut de la empresa en el formato 12345678-9"
                />
              </Form.Group>
            </Grid>
          </Grid>
        
          <SuccessButton type="submit" > Crear empresa y contacto</SuccessButton> 
        </>)}


        {representativeType?.name === "Persona Natural" && ( <>
          <h1>Datos de la persona (Puede guardar los siguientes datos más adelante)</h1>
          <Grid container marginY={"10px"}>
            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
                Nombre
                </Typography>
                <Form.Control 
                  type="text"
                  value={personFirstName}
                  onChange={(e)=>setPersonFirstName(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Por favor complementar el nombre de la persona"
                  isValid={personFirstName?.trim().length>0}
                  isInvalid={didFirstSummit && personFirstName?.trim().length===0}
                />
                <Form.Control.Feedback type="valid">
                  Bien!
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor ingrese un nombre
                </Form.Control.Feedback>
              </Form.Group>
            </Grid>
            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
                Apellido
                </Typography>
                <Form.Control 
                  type="text"
                  value={personLastName}
                  onChange={(e)=>setPersonLastName(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Por favor complementar el apellido de la persona"
                />
              </Form.Group>
            </Grid>

            <Grid item xs={12} md={6} marginY={"10px"}>
              <Form.Group>
                <Typography align="center">
                Rut
                </Typography>
                <Form.Control as="input"
                  value={personRut}
                  onChange={(e)=>setPersonRut(e.target.value)}
                  sx={{ width: "80%" }}
                  placeholder="Rut en el formato 12345678-9"
                />
              </Form.Group>
            </Grid>
          </Grid>
          <SuccessButton type="submit" > Crear Contacto y Cliente</SuccessButton> 
        </>)}
      </Form>
      {notification}

    </div>
  );
}