import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";
import PropTypes from "prop-types";
import { queryRestoreIndustry } from "../../views/industry/utils";


IndustryTable.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.object).isRequired,
  page_size: PropTypes.number.isRequired,
  page_index: PropTypes.number.isRequired,
  type: PropTypes.string
};


function IndustryTable(props) {
  const navigate = useNavigate();
  const { industries } = props;
  const { page_size,page_index } = props;
  const { type } = props;
  
  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {industries.map(({ id, UUid, name}, index) => {
            const goToIndustry = ()=>{navigate(`/industries/${UUid}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size && (
              <tr key={index+1}>
                <td>{index+1}</td>
                <td>{name}</td>
                <td>
                  {type === "Restore"  ?
                    <Button fullWidth onClick={()=>{queryRestoreIndustry(UUid); navigate(-1);}}>Restaurar Industria</Button> :
                    <Button fullWidth onClick={goToIndustry}>Ver</Button>
                  }
                </td>
              </tr>
            ) ;})}
        </tbody>
      </Table>
    </div>
  );
}

export default IndustryTable;