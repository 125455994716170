import fetchData from "../../utils";

export async function queryCreateUser(user){
  const  url = "/user/queryCreateUser/";
  const  method = "POST";
  const  body = { 
    "data": {
      "user": {
        "username": user.username,
        "password": user.password,
        "email": user.email,
        "first_name": user.firstName,
        "last_name": user.lastName,
        "confirm_password": user.confirmPassword,
      },
      "role": user.type,
      "phone": user.phone ?? 0,
      "nacionality": user.residence ?? null,
      "jurisdiction": user.jurisdiction ?? null,
    }
  };

  return await fetchData(url,method,body);
}

export async function queryCreateEnterprise(enterpriseName, enterpriseGiro, enterpriseRut , clientID,enterpriseSocialReason){
  const  url = "/user/queryCreateEnterprise/";
  const  method = "POST";
  const  body = { 
    "enterpriseName": enterpriseName,
    "enterpriseRut": enterpriseRut,
    "enterpriseGiro": enterpriseGiro,
    "clientID": clientID,
    "enterpriseSocialReason" : enterpriseSocialReason,
  };
  return await fetchData(url,method,body);
}

export async function queryCreateNaturalPerson(personFirstName, personLastName, personRut , clientID){
  const  url = "/user/queryCreateNaturalPerson/";
  const  method = "POST";
  const  body = { 
    "personFirstName": personFirstName,
    "personLastName": personLastName,
    "personRut": personRut,
    "clientID": clientID,
  };
  return await fetchData(url,method,body);
}

export async function queryAllClientType(){
  const  url = "/user/queryAllClientType/";
  const  method = "GET";
  const  body = null;
  return await fetchData(url,method,body);
}