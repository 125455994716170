import { useState, useEffect, useCallback  } from "react";
import "../../styles/userIndex.scss";
import { getClientByID, getProjectByID } from "../kam/utils";
import { getJobsByID, queryProposalsByUser, queryStartJob, querySubmitJob } from "./utils";
import React from "react";
import Notification from "../../components/Notification";
import { CloseButton, SuccessButton } from "../../components/Buttons";
import { GenericalModal } from "../kam/utilsComponent";
import { LawyerTable } from "./lawyerTable";
import { LoadingView } from "../LoadingView";


export function LawyerActiveJobs() {

  const [jobs, setJobs] = useState([]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [notification, raiseAlert] = Notification("success", "");

  const [isLoading, setIsLoading] = useState(true);

  const handleCloseJobModal = () => setShowRejectModal(false);
  const handleShowJobModal = () => setShowRejectModal(true);

  const [showRejectModal, setShowRejectModal] = useState(false);  

  const [jobInfo, setJobInfo] = useState([]);  
  const [actualIndex, setActualIndex] = useState(0);  
  const handleColor = (status) =>{
    if (status === "Pendiente Asignación") return"#fcb7af";
    if (status === "En Progreso") return"";
    if (status === "Completo") return"#b0f2c2";
  };

  useEffect(() => {
    queryProposalsByUser().then(
      (response)=>{
        const data = response.data;
        if (data.length > 0) {
          data.forEach((proposal,index) => {
            if(proposal.accepted) {
              getJobsByID(proposal.job_id).then(
                (job) =>{
                  let aux_jobs = jobs;
                  const projectID = job.data.project_id;
                  getProjectByID(projectID).then((project) => {
                    if(project.data.is_accepted ){
                      aux_jobs[index] = job.data;
                      aux_jobs[index].originalIndex = index;
                      aux_jobs[index].project_name = project.data.project_name;
                      aux_jobs[index].project_description = project.data.project_description;
                      aux_jobs[index].color = handleColor(job.data.status);
                      aux_jobs[index].service = job.data.service.service_name;
                      aux_jobs[index].proposal_id = proposal.id;
                      aux_jobs[index].accepted = proposal.accepted;
                      aux_jobs[index].cost = proposal.cost;
                      getClientByID(project.data.client).then(
                        (client) =>{
                          aux_jobs[index].client_name = client.data.user.auth.first_name + " " + client.data.user.auth.last_name;
                        }
                      );
                      setJobs(aux_jobs); 
                    }
                  }).then(()=>{
                    setIsLoading(false);
                    forceUpdate();
                  });
                }
              );
            } else if (index === data.length - 1) setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
  }, []);



  const onShowMoreInfo = (e)=>{
    setJobInfo(jobs[e.target.value]);
    setActualIndex(e.target.value);
    handleShowJobModal();
  };

  const handleStartJob = (e) => {
    e.preventDefault();
    
    if (jobs[actualIndex].new_status.name === "Pendiente Asignación")
    {
      queryStartJob(jobInfo.id).then((response)=>{
        raiseAlert(response);
        jobs[actualIndex].new_status.name = "En Progreso";
        jobs[actualIndex].color = handleColor("En Progreso");
      });
    }      
    handleCloseJobModal();
  };
  const handleSubmitJob= (e) =>{
    e.preventDefault();
    if (jobs[actualIndex].new_status.name === "En Progreso"){
      querySubmitJob(jobInfo.id).then((response)=>{
        raiseAlert(response);
        jobs[actualIndex].new_status.name = "Terminado";
        jobs[actualIndex].color = handleColor("Terminado");
      });
      handleCloseJobModal();
    }
    if (jobs[actualIndex].new_status.name === "Asignado"){
      querySubmitJob(jobInfo.id).then((response)=>{
        raiseAlert(response);
        jobs[actualIndex].new_status.name = "En Progreso";
        jobs[actualIndex].color = handleColor("En Progreso");
      });
      handleCloseJobModal();
    }  };

  if (isLoading) return (
    <LoadingView/>
  );
  return (
    <div>
      <div className='user-table'>
        <GenericalModal
          show={showRejectModal}
          title={jobInfo.project_name}
          body={
            <>
              <div className="modal-spacing">
                <h5>
                    Sobre el proyecto:
                </h5>
                <div><b>Cliente: </b>{jobInfo.client_name}</div>
                <div><b>Descripción del proyecto: </b> {jobInfo.project_description}</div>
              </div>
              <div className="modal-spacing">
                <h5>
                  Sobre el trabajo:
                </h5>
                <div><b>Servicio prestado: </b>{jobInfo.service}</div>
                <div><b>Descripcion del servicio: </b>{jobInfo.description}</div>
                <div><b>Valor del servicio (UF): </b>{jobInfo.cost??0}</div>
                <div><b>Fecha estimada de inicio del trabajo: </b>{jobInfo.start_date?.split("-").reverse().join("-")??"No definido"}</div>
                <div><b>Fecha estimada del entregable: </b>{jobInfo.end_date?.split("-").reverse().join("-")??"No definido"}</div>
              </div>
            </>}
          footer={(
            <>
              {jobInfo.status === "Pendiente Asignación" && (<SuccessButton onClick={handleStartJob} value={jobInfo.id}>Marcar como empezado</SuccessButton>)}
              {jobInfo.status === "Asignado" && (<SuccessButton onClick={handleSubmitJob} value={jobInfo.id}> Marcar como en progreso</SuccessButton>)}
              {jobInfo.status === "En Progreso" && (<SuccessButton onClick={handleSubmitJob} value={jobInfo.id}> Marcar como entregado</SuccessButton>)}
              <CloseButton onClick={handleCloseJobModal}>Cerrar</CloseButton>
            </>
          )}
        /> 

        <div className='user-show-extended'>
          <h1>Trabajos Activos</h1>
          <LawyerTable
            filteredJobs = {jobs.filter((job) => job.status !== "Terminado" && job.status !== "Pendiente Asignación")}
            onShowMoreInfo = {onShowMoreInfo}
          />
        </div>

      </div>
      {notification}
    </div>
  );
}
