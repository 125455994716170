import { useState, useEffect } from "react";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import {Form } from "react-bootstrap";
import { queryAllIndustries, queryAllLawAreas, queryCreateService } from "./utils";
import { SuccessButton } from "../../components/Buttons";
import { Grid,Typography } from "@mui/material";
import Notification from "../../components/Notification";
import Multiselect from "multiselect-react-dropdown";
import { useRef } from "react";

export function CreateService() {
  const [isLoading, setIsLoading] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [lawAreas, setLawAreas] = useState([]);
  const [industries, setIndustries] = useState([]);

  const [currentName, setCurrentName] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentLawAreas, setCurrentLawAreas] = useState([]);
  const [currentIndustries, setCurrentIndustries] = useState([]);
  
  const [notification, raiseAlert] = Notification("success", "");
  const lawAreasRef = useRef();
  const industriesRef = useRef();
  const onSubmit = (e)=>{
    e.preventDefault();
    queryCreateService(currentName,currentDescription, currentLawAreas, currentIndustries).then((response)=>{
      setCurrentName("");
      setCurrentDescription("");
      setCurrentIndustries([]);
      setCurrentLawAreas([]);
      lawAreasRef.current.resetSelectedValues();
      industriesRef.current.resetSelectedValues();
      raiseAlert(response);
    });
    forceUpdate();
  };
  useEffect(()=>{
    queryAllLawAreas().then((response)=>{
      if (response.success){    
        setLawAreas(response.data);
      }
    });
    queryAllIndustries().then((response)=>{
      if (response.success){    
        setIndustries(response.data);
      }
    });
  },[]);

  useEffect(( )=>{
    if( lawAreas.length && industries.length){
      setIsLoading(false);
      forceUpdate();
    }
  },[lawAreas,industries]);
  
  const handleMultiSelectLawArea = (e) =>{
    let aux_currentLawAreas = [];
    e.forEach((element,index) => {
      aux_currentLawAreas[index] = element;
      setCurrentLawAreas(aux_currentLawAreas);
    });
  };

  const handleMultiSelectIndustries = (e) =>{
    let aux_currentIndustries = [];
    e.forEach((element,index) => {
      aux_currentIndustries[index] = element;
    });
    setCurrentIndustries(aux_currentIndustries);
  };

  if(isLoading){
    return <LoadingView></LoadingView>;
  }
  return (
    <div className="user-show-extended">

      <h1>Servicio Legal</h1>
      <Form onSubmit={onSubmit}>

        <Grid container marginY={"10px"}>
          <Grid item xs={12} md={12} marginY={"10px"}>
            <Typography align="center">
            Nombre del servicio legal
            </Typography>
            <Form.Control as="input"
              value={currentName}
              onChange={(e)=>setCurrentName(e.target.value)}
              sx={{ width: "80%" }}
              label="Nombre del servicio legal"
              placeholder="Por favor completar el nombre del servicio legal"
            />
          </Grid>
          <Grid item xs={12} md={12} marginY={"10px"}>
            <Typography align="center">
            Descripción del servicio legal
            </Typography>
            <Form.Control as="textarea"
              value={currentDescription}
              onChange={(e)=>setCurrentDescription(e.target.value)}
              sx={{ width: "80%" }}
              label="Descripción del servicio legal"
              placeholder="Por favor complementar la descripción del servicio legal"
            />
          </Grid>

          <Grid item xs={12} md={6} marginY={"10px"}>
            <Typography align="center">
              Área del derecho
            </Typography>
            <Multiselect 
              ref={lawAreasRef}
              showArrow
              options={lawAreas} 
              displayValue="area_name" 
              onRemove={handleMultiSelectLawArea} 
              onSelect={handleMultiSelectLawArea}
            />          </Grid>
          <Grid item xs={12} md={6} marginY={"10px"}>
            <Typography align="center">
              Industria relacionada
            </Typography>
            <Multiselect 
              ref={industriesRef}
              showArrow 
              options={industries} 
              displayValue="name" 
              onRemove={handleMultiSelectIndustries} 
              onSelect={handleMultiSelectIndustries} />
          </Grid>
        </Grid>
        <SuccessButton type="submit" disabled={!currentName } > Crear Servicio Legal</SuccessButton>
      </Form>
      {notification}

    </div>
  );
}