import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination";
import { deltaTime } from "../../utils"


export function NotificationTable(props) {
  const { notificationList } = props;
  const [amount, setAmount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const { setNotificationAmount, setNotificationNavList } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
    
  }, [amount, pageSize, pageIndex]);
  useEffect(() => {
    setAmount(notificationList.length);
  }, [notificationList.length]);

  return (
    <div className='user-table'>
      <Grid container>
        <Grid item  xs={10} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        </Grid>
        <Grid item  xs={8} md={4}>
          Página
            <CustomPagination
              pageIndex={pageIndex}
              maxPageIndex={maxPageIndex}
              setPageIndex={setPageIndex}
            />  
        </Grid>
      </Grid>
      <div className="diagnostic-legend">
        <div className="diagnostic-legend-item">
          <div className="diagnostic-legend-item-color green"></div>
          <div className="diagnostic-legend-item-text">Sin ver</div>
        </div>
        <div className="diagnostic-legend-item">
          <div className="diagnostic-legend-item-color gray"></div>
          <div className="diagnostic-legend-item-text">Visto</div>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Título</th>
            <th>Ver notificación</th>
          </tr>
        </thead>
        <tbody>
          {notificationList.map((notification, index) => {
            const goToNotification = ()=>{
              if (!notification.is_read) {
                setNotificationAmount((notificationAmount) => notificationAmount - 1);
                setNotificationNavList((notificationNavList) => notificationNavList.filter((notificationNav) => notificationNav.UUid !== notification.UUid));
              }
              navigate(`/notifications/${notification.UUid}`);
            };
            const color = notification.is_read ? "gray" : "green-line";
            return pageIndex * pageSize <= index && index < (pageIndex + 1) * pageSize && (
              <tr key={index} className={color}>
                <td>Hace {deltaTime.getNotificationTime(notification.created_at)}</td>
                <td>{notification.title}</td>
                <td><Button onClick={goToNotification}>Ver</Button></td>
              </tr>
          )}
            )}
        </tbody>
      </Table>
      <Grid container>
          <Grid item  xs={10} md={4}>
            Página
              <CustomPagination
                pageIndex={pageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={setPageIndex}
              />  
          </Grid>
          <Grid item  xs={8} md={4}>
          </Grid>
        </Grid>
      <p>
        Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
      </p>
    </div>
  );
}