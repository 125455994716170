import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";

function UserTable(props) {
  const navigate = useNavigate();

  const { users } = props;
  
  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th className="th-lg-percent">Descripción</th>
            <th>Ver servicio</th>
          </tr>
        </thead>
        <tbody>
          {users.map(({ id, UUid, pending_service_name,pending_service_description }, index) => {
            const goToPendingService = ()=>{navigate(`/requests/lsa/${UUid}`);};
            return (
              <tr key={id}>
                <td>{index + 1}</td>
                <td>{pending_service_name}</td>
                <td>
                  {pending_service_description}
                </td>
                <td><Button fullWidth onClick={goToPendingService}>Ver</Button></td>
              </tr>
            );})}
        </tbody>
      </Table>
    </div>
  );
}

export default UserTable;