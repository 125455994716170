import { useState, useEffect } from "react";
import HistoricalServicesTable from "./historicalTable";
import { useNavigate } from "react-router-dom";
import { LoadingView } from "../LoadingView";
import React from "react";
import { GoBackButton, SuccessButton } from "../../components/Buttons";
import {  Grid } from "@mui/material";
import { CustomPagination, RowsPerPage } from "../../components/Pagination.jsx";
import { Form } from "react-bootstrap";
import { queryAllLegacyServices } from "../service/utils";

export function HistoricalData() {
  const [historicalServices, setHistoricalServices] = useState([]);
  const [historicalServicesFillter, setHistoricalServicesFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    queryAllLegacyServices()
      .then((json) => {
        setHistoricalServices(json["data"]);
        setHistoricalServicesFillter(json["data"]);
        setAmount(json["data"].length);
        setIsLoading(false);
      });
  }, []);


  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearch(search);
    var lawAreasFiltter = historicalServices.filter(({ nombreServicioLegal,servicioLegalFragmentado, service }) => {
      return nombreServicioLegal.toLowerCase().includes(search.toLowerCase()) ||
      servicioLegalFragmentado.toString().includes(search.toLowerCase())  ||
      service.service_name.toLowerCase().includes(search.toLowerCase()) 
    });
    setHistoricalServicesFillter(lawAreasFiltter);
    setAmount(lawAreasFiltter.length);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);
  
  return (
    <div className="user-show-extended">
      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
            Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  <Grid item  xs={12} md={3}>
          <p>Buscar en la tabla:</p>
          <Form.Control 
            as="input" 
            placeholder="Buscar por texto"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
        </Grid>
      </Grid>
      <h1>Servicios Legales Históricos</h1>

      {isLoading? (<LoadingView/>): 
        <>
          <HistoricalServicesTable historicalServices={historicalServicesFillter} page_size={pageSize} page_index={pageIndex}/>
          Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
  );
}
