import React from 'react'
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as Hi2Icons from "react-icons/hi2";
import * as ImIcons from "react-icons/im";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import * as RiIcons from "react-icons/ri";
import * as VscIcons from "react-icons/vsc";


export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Reports',
    path: '/',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Team',
    path: '/',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
]

export const SidebarDataAdmin = [
  {
    title: 'Usuarios',
    path: '/users',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Kams',
    path: '/kams',
    icon: <VscIcons.VscLaw />,
    cName: 'nav-text'
  },
  {
    title: 'Abogados',
    path: '/lawyers',
    icon: <Hi2Icons.HiAcademicCap />,
    cName: 'nav-text'
  },
  {
    title: 'Clientes',
    path: '/clients/list',
    icon: <ImIcons.ImUsers />,
    cName: 'nav-text'
  },
  {
    title: 'Servicios',
    path: '/services',
    icon: <MdIcons.MdHomeRepairService />,
    cName: 'nav-text'
  },
  {
    title: 'Solicitudes',
    path: '/requests/lsa',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Áreas del derecho',
    path: '/law_areas',
    icon: <MdIcons.MdAccountBalance />,
    cName: 'nav-text'
  },
  {
    title: 'Industrias',
    path: '/industries',
    icon: <FaIcons.FaIndustry />,
    cName: 'nav-text'
  },
  {
    title: 'Servicios Históricos',
    path: '/historical_services',
    icon: <FaIcons.FaArchive />,
    cName: 'nav-text'
  },
  {
    title: 'Nacionalidades',
    path: '/countries',
    icon: <FaIcons.FaGlobeAmericas />,
    cName: 'nav-text'
  },
];

export const SidebarDataKAM  = [
  {
    title: 'Propuestas Comerciales',
    path: '/proposals/commercial_contracts',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Proyectos',
    path: '#',
    icon: <AiIcons.AiFillProject />,
    cName: 'nav-text',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Activos',
        path: '/projects/active',
        icon: <MdIcons.MdOutlineIncompleteCircle />,
        cName: 'nav-text'
      },
      {
        title: 'Completados',
        path: '/projects/completed',
        icon: <MdIcons.MdCheckCircle />,
        cName: 'nav-text'
      },
    ]
  },
  {
    title: 'Abogados',
    path: '/lawyers',
    icon: <Hi2Icons.HiAcademicCap />,
    cName: 'nav-text'
  },
  {
    title: 'Clientes',
    path: '#',
    icon: <ImIcons.ImUsers />,
    cName: 'nav-text',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Clientes',
        path: '/clients/list',
        icon: <ImIcons.ImUsers />,
        cName: 'nav-text'
      },
      {
        title: 'Crear Cliente',
        path: '/clients/create',
        icon: <IoIcons.IoMdCreate />,
        cName: 'nav-text'
      },
    ]
  },
  
  {
    title: 'Diagnósticos',
    path: '/diagnostic_meetings',
    icon: <BiIcons.BiConversation />,
    cName: 'nav-text'
  }
];

export const SidebarDataLawyer = [

  {
    title: 'Trabajos',
    path: '#',
    icon: <MdIcons.MdHomeRepairService />,
    cName: 'nav-text',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Activos',
        path: '/jobs/active',
        icon: <MdIcons.MdOutlineIncompleteCircle />,
        cName: 'nav-text'
      },
      {
        title: 'Completados',
        path: '/jobs/completed',
        icon: <MdIcons.MdCheckCircle />,
        cName: 'nav-text'
      },
    ]
  },
  {
    title: 'Mi Experiencia',
    path: '/my_experience',
    icon: <Hi2Icons.HiAcademicCap />,
    cName: 'nav-text',
  }
];
