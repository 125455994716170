import { useState, useEffect } from "react";
import ServiceTable from "../../components/service/serviceTable";
import { useNavigate } from "react-router-dom";
import "../../styles/userIndex.scss";
import { LoadingView } from "../LoadingView";
import React from "react";
import { ButtonStateComponent, GoBackButton, SuccessButton } from "../../components/Buttons";
import {  Box, Grid } from "@mui/material";
import { queryAllIndustries, queryAllLawAreas, queryAllServicesWithAreaAndIndustry, queryFilterServicesByAreaAndIndustry } from "./utils";
import { CustomPagination, RowsPerPage } from "../../components/Pagination.jsx";
import { Form } from "react-bootstrap";
import { Validate } from "../../utils";
import Multiselect from "multiselect-react-dropdown";

export function ServicesList() {
  
  const [services, setServices] = useState([]);
  const [servicesFillter, setServicesFillter] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [amount, setAmount] = useState(0);
  const [maxPageIndex, setMaxPageIndex] = useState(0);
  const [allIndustries, setAllIndustries] = useState([]);
  const [filterIndustries, setFilterIndustries] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [filterAreas, setFilterAreas] = useState([]);
  const navigate = useNavigate();
  const [logicMode, setLogicMode] = useState("AND");

  useEffect(() => {
    queryAllServicesWithAreaAndIndustry()
      .then((json) => {
        setServices(json["data"]);
        setServicesFillter(json["data"]);
        setIsLoading(false);
        setAmount(json["data"].length);
      });
      queryAllIndustries()
      .then((json)=>{
        setAllIndustries(json.data)
      })
      queryAllLawAreas()
      .then((json)=>{
        setAllAreas(json.data)
      })
  }, []);
  const goToCreateService = () =>{ 
    let path = "/services/create"; 
    navigate(path);
  };
  const goToRestoreService = () =>{ 
    let path = "/services/restore"; 
    navigate(path);
  };
  
  const onChangeSearch = async(e) => {
    e.preventDefault();
    const search = Validate.accent_fold(e.target.value);
    setSearch(search);
  };

  useEffect(()=>{
    const newMaxIndex = Math.max(Math.ceil(amount/pageSize)-1, 0);
    setMaxPageIndex(newMaxIndex);
    if (newMaxIndex < pageIndex){
      setPageIndex(newMaxIndex);
    }
  },[amount,pageSize]);
  
  const handleIndustriesChange = (e) =>{
    setFilterIndustries(e.map((value)=> {return value.id}));
  }
  const handleAreasChange = (e) =>{
    setFilterAreas(e.map((value)=> {return value.id}));
  }

  useEffect(()=>{
    if (filterIndustries.length === 0 && filterAreas.length ===0){
      var servicesFiltter = filterServices(services)
      setServicesFillter(servicesFiltter);
      setAmount(servicesFiltter.length);
      return;
    }
    
    queryFilterServicesByAreaAndIndustry(filterAreas, filterIndustries,logicMode)
    .then((json)=>{
      var servicesFiltter = filterServices(json.data)
      setServicesFillter(servicesFiltter);
      setAmount(servicesFiltter.length);
    })
  }
  ,[filterIndustries, filterIndustries.length, filterAreas, filterAreas.length,search, logicMode]);
  const filterServices = (servicesToFilter)=>{
    return servicesToFilter.filter(({ service_name, industry, area, id }) => {
      const fixServiceName = Validate.accent_fold(service_name);
      return fixServiceName.toLowerCase().includes(search.toLowerCase()) ||
      industry.filter(({name})=>{
        const fixIndustryName = Validate.accent_fold(name);
        return (fixIndustryName.toLowerCase().includes(search.toLowerCase()));
      }).length > 0 ||
      area.filter(({area_name})=>{
        const fixAreaName = Validate.accent_fold(area_name);
        return (fixAreaName.toLowerCase().includes(search.toLowerCase()));
      }).length > 0 ||
      id.toString().includes(search.toLowerCase());
    });
  }
  return (
    <>
    <div className="user-show-extended">
    <h1>Filtros</h1>
    <Grid container marginBottom={"1.5vw"}>
    <Grid item xs={12} md={5} >
    <Box display="flex" justifyContent="center">
    <div>Filtrar por Industria</div>
    </Box>
    </Grid>
    <Grid item xs={12} md={2} >
    <Box display="flex" justifyContent="center">
    <div>Lógica para filtrado</div>
    </Box>
      </Grid>
      <Grid item xs={12} md={5} >
      <Box display="flex" justifyContent="center" >
      Filtrar por Área</Box>
      </Grid>

      <Grid item xs={12} md={5} >
      <Box>
      <div><Multiselect showArrow options={allIndustries} displayValue="name" onRemove={handleIndustriesChange} onSelect={handleIndustriesChange} avoidHighlightFirstOption /></div>
    </Box>
      </Grid>
      <Grid item xs={12} md={2} >
      <Box display="flex" justifyContent="center" alignContent="center">
      <div>{ButtonStateComponent(logicMode, setLogicMode, "AND", "OR")}</div>
      </Box>
      </Grid>
      <Grid item xs={12} md={5} >
      <Box> 
      <Multiselect showArrow options={allAreas} displayValue="area_name" onRemove={handleAreasChange} onSelect={handleAreasChange} avoidHighlightFirstOption/>
      </Box>
          </Grid>
    </Grid>

    <Grid container>
    <Grid item xs={12} md={3}>
    Buscar en la tabla:
    </Grid>
    <Grid item xs={12} md={9} >
      <Box display="flex" justifyContent="center" >
          <Form.Control 
            as="input" 
            placeholder="Búsqueda por texto libre"
            name="busqueda"
            value={search}
            onChange={onChangeSearch}>
          </Form.Control>
          </Box>
          </Grid>
    </Grid>
    </div>
    <div className="user-show-extended">

      <Grid container marginX={"1.5vw"}>
        <Grid item  xs={12} md={4}>
          <RowsPerPage pageSize={pageSize} setPageSize={setPageSize}></RowsPerPage>
        Página 
          <CustomPagination
            pageIndex={pageIndex}
            maxPageIndex={maxPageIndex}
            setPageIndex={setPageIndex}
          />  
        </Grid>  
        <Grid item xs={12} md={3}>
        </Grid>        
        <Grid item xs={12} md={4}>
          <SuccessButton fullWidth onClick={goToCreateService}> Crear Servicio Legal</SuccessButton>
          <SuccessButton fullWidth onClick={goToRestoreService}> Restaurar Servicio Legal</SuccessButton>
        </Grid>
      </Grid>
      <h1>Servicios legales</h1>

      {isLoading? (<LoadingView/>): 
        <>
          <ServiceTable services={servicesFillter} page_size={pageSize} page_index={pageIndex} type={"Index"}/>
          Mostrando {amount > 0 ? pageIndex * pageSize+ 1 : 0} - {Math.min((pageIndex + 1) * pageSize, amount)} resultado(s) de un total de {amount}.
        </>
      }
      <GoBackButton/>
    </div>
    </>
  );
}
