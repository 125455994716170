export async function queryGetNotificationNumber(userID) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryGetNotificationNumber/${userID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(response.status);
    }
    else {
      return response.json();
    }
  })
  }