import React, { useState, useEffect } from 'react'
import { CreateCountry } from '../../components/country/newCountry'
import { IndexCountry } from '../../components/country/indexCountry'
import countryList from 'react-select-country-list';
import fetchData from "../../utils";
import Notification from "../../components/Notification";


export function CountryIndex() {
  const [countries, setCountries] = useState([]);
  const [allCountries, setAllCountries] = useState(countryList().getData());
  const [notification, raiseAlert] = Notification("success", "");

  useEffect(() => {
    fetchData("/country/all")
    .then(({ data }) => {
      setCountries(data);
      setAllCountries((prev) => {
        const newCountries = prev.filter((country) => {
          return !data.some((c) => c.name === country.label);
        });
        return newCountries;
      })
    })
  }, []);
  return (
    <div>
      <CreateCountry allCountries={allCountries} setAllCountries={setAllCountries} setCountries={setCountries}
        raiseAlert={raiseAlert}/>
      <IndexCountry countries={countries} setAllCountries={setAllCountries} setCountries={setCountries}
        raiseAlert={raiseAlert}/>
      {notification}
    </div>
  )
}