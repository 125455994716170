import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { traduction } from './traduction';
import "../styles/Breadcrumb.scss"
import { Validate } from "../utils";


function Breadcrumb() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);
  return (
    <Breadcrumbs className="breadcrumbs" maxItems={3} separator={<NavigateNextIcon fontSize="small" />}>
      {paths.length > 0 && paths[0] !== "home" && (
        <Link to="/" className="bread-link">Inicio</Link>
      )}
      {paths.map((path, index) => {
        const routeTo = `/${paths.slice(0, index + 1).join('/')}`;
        let name = Validate.isValidUUID(path) ? paths[index-1].slice(-1) === "s" ? paths[index-1].slice(0, -1) : paths[index-1] : path;
        if (name in traduction) {
          name = traduction[name];
        } else if (name.slice(0, 2) === "ey") {
          name = "";
        }
        const isLast = index === paths.length - 1;
        return (
          isLast ? (
            <span className="bread-final" key={index}>{name}</span>
          ) : (
            <Link to={routeTo} className="bread-link" key={index}>{name}</Link>
          )
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;