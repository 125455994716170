import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import { phoneData } from "./phoneData"
import fetchData from "../../utils";



export function CreateCountry({allCountries, setAllCountries, setCountries, raiseAlert}) {
  const [newCountry, setNewCountry] = useState(null);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    const data = { data: {
        value: newCountry.value,
        name: newCountry.label,
        code_number: phoneData[newCountry.value.toUpperCase()]
      }
    };
    fetchData("/country/create/", "POST", data)
    .then((response) => {
      setAllCountries((prev) => {
        const newCountries = prev.filter((country) => {
          return country.value !== newCountry.value;
        });
        return newCountries;
      })
      setCountries((prev) => {
        const newCountries = [...prev, response.data];
        return newCountries;
      }
      )
      setNewCountry(null);
      const alert = {
        success: "success",
        message: response.message,
      }
      raiseAlert(alert);
    })}

  return (
    <div className="user-show">
      <h1>Agrega nuevos países a la base de datos de Impacto Legal</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Nombre del país</Form.Label>
          <Autocomplete
            id="country-select-demo"
            options={allCountries}
            name="country"
            autoHighlight
            onChange={(event, newValue) => {setNewCountry(newValue)}}
            getOptionLabel={(option) => {
              return `${option.label} (${option.value}) +${phoneData[option.value.toUpperCase()]}`
            }}
            autocomplete="off"
            value={newCountry}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  <img
                  loading="lazy"
                  src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                  alt=""
                />
                  {option.label} ({option.value}) +{phoneData[option.value.toUpperCase()]}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Elige un país"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Agregar
        </Button>
      </Form>
    </div>
  )
}