import fetchData from "../../utils";


export async function getProjectByID(projectID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/getProjectByID/${projectID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      else {
        return response.json();
      }
    });
}


export async function getClientByID(clientID) {
  const url = `/project/getClientByID/${clientID}`;
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}


export async function queryAllLawyers() {
  const url = "/project/queryAllLawyers";
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export function queryCreateJob(projectUUid,jobDescription,jobService,jobPrice, jobWorkload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryCreateJob`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "projectUUid" : projectUUid,
      "jobDescription" : jobDescription,
      "jobService" : jobService,
      "jobPrice": jobPrice,
      "jobWorkload": jobWorkload,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else{
        return response.json();
      }
    });
}

export async function getClientJobsByProjectID(projectID,lawyers) {
  const url = "/project/getClientJobsByProjectID/";
  const method = "POST";
  const body = {
    "projectID": projectID,
    "lawyers": lawyers,
  };
  return await fetchData(url,method,body);

}

export async function queryCreateLawyerProposal(lawyerUUID, jobID, lawyerCost) {
  const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryCreateLawyerProposal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "jobID" : jobID,
      "lawyerUUID": lawyerUUID,
      "lawyerCost": lawyerCost,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
  return data;
}


export async function sendProposalMail(jobID, lawyerUUID){
  const body = {
    "data": {
      "projectId": jobID,
      "lawyerUUID": lawyerUUID,
    }
  };
  return await fetchData("/mailer/send_proposal/", "POST", body);
    
}

export async function queryDeleteJob(jobID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryDeleteJob`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "jobID" : jobID,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}

export async function queryUpdateJob(proposalObject) {
  const url = "/project/queryUpdateJob";
  const method = "POST";
  const body = {
    "jobID":           proposalObject.jobID,
    "jobPrice":        proposalObject.price,
    "jobDescription" : proposalObject.description,
    "startDate":       proposalObject.startDate,
    "endDate":         proposalObject.endDate,
    "jobWorkload":     proposalObject.workload,
    "elegibleLawyers": proposalObject.elegibleLawyers,
  };
  return await fetchData(url,method,body);
}

export async function queryLoadJob(proposalObject) {
  const url = "/project/queryLoadJob";
  const method = "POST";
  const body = {
    "jobID":           proposalObject.jobID,
    "elegibleLawyers": proposalObject.elegibleLawyers,
  };
  return await fetchData(url,method,body);
}

export async function queryCreateConfirmedProposal(lawyerUUID, lawyerCost, jobID) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryCreateConfirmedProposal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    
    body: JSON.stringify({
      "jobID" : jobID,
      "lawyerUUID": lawyerUUID,
      "lawyerCost": lawyerCost,
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}


export async function queryUpdateProject(projectID,projectName,projectDescription) {

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryUpdateProject`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "projectID": projectID,
      "projectName": projectName,
      "projectDescription": projectDescription,
    }),

  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}

export async function querySetProyectAcceptanceStatus(projectUUid,projectNewAcceptanceStatus) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/querySetProyectAcceptanceStatus`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "projectUUid": projectUUid,
      "projectNewAcceptanceStatus": projectNewAcceptanceStatus,
    }),

  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}

export async function queryCompleteJob(jobID, rating, evaluation) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryCompleteJob`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "jobID" : jobID,
      "rating": rating,
      "evaluation": evaluation
    })
  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}

export async function queryTerminateJob(jobID){
  const url = "/project/queryTerminateJob";
  const method = "PUT";
  const body = {"jobID" : jobID};
  return await fetchData(url,method,body);
}
export async function queryNextJobStep(jobID){
  const url = "/project/queryNextJobStep";
  const method = "PUT";
  const body = {"jobID" : jobID};
  return await fetchData(url,method,body);
}

export async function queryUpdateProjectStatus(projectUUid) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/project/queryUpdateProjectStatus`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      "projectUUid": projectUUid,
    }),

  })
    .then(async(response) => {
      if(!response.ok) {
        const errorMessage = await response.json();
        const error = Error(errorMessage["message"]);
        error.status = response.status;
        throw error;
      }
      else {
        return response.json();
      }
    });
}


export async function getPdfProject(projectUUid, documentType) {

  return fetchData(`/project/get_fields/${projectUUid}/${documentType}`, "GET");
}
export async function queryCreateProject(projectClient, projectName, projectDescription, userID, client){

  const url = "/project/queryCreateProject";
  const method = "POST";
  const body = {
    "projectClient": projectClient,
    "projectName": projectName,
    "projectDescription": projectDescription,
    "id" : userID,
    "user": client
  };
  return await fetchData(url,method,body);
}

export async function queryAllClients(){
  const url = "/project/queryAllClients";
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryIndexClients(index){
  const url = "/project/queryIndexClients/";
  const method = "POST";
  const body = {
    "index": index?? 0
  };
  return await fetchData(url,method,body);
}

export async function queryAllKAM(){
  const url = "/project/queryAllKAM";
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function queryAllStatus(){
  const url = "/project/queryAllStatus";
  const method = "GET";
  const body = null;
  return await fetchData(url,method,body);
}

export async function querySelectLawyerForJob(lawyerUUID, jobID){
  const url = "/project/querySelectLawyerForJob";
  const method = "POST";
  const body = {
    "lawyerUUID": lawyerUUID,
    "jobID": jobID,
  };
  return await fetchData(url,method,body);
}