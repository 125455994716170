import { useState } from "react";
import "../../styles/userIndex.scss";
import React from "react";
import {Form } from "react-bootstrap";
import { SuccessButton } from "../../components/Buttons";
import { Grid,Typography, Button } from "@mui/material";
import Notification from "../../components/Notification";
import { queryCreateIndustry } from "./utils";
import { useNavigate } from "react-router-dom";

export function CreateIndustry() {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const navigate = useNavigate();

  const[currentName, setCurrentName] = useState("");
  const[currentDescription, setCurrentDescription] = useState("");
  
  const [notification, raiseAlert] = Notification("success", "");
  const onSubmit = (e)=>{
    e.preventDefault();
    queryCreateIndustry(currentName, currentDescription).then((response)=>{
      setCurrentName("");
      setCurrentDescription("");
      raiseAlert(response);
    });
    forceUpdate();
  };

  return (
    <div className="user-show-extended">

      <h1>Crear industria</h1>
      <Form onSubmit={onSubmit}>
        <Grid container marginY={"10px"}>
          <Grid item xs={12} md={12} marginY={"10px"}>
            <Typography align="center">
            Nombre de la industria
            </Typography>
            <Form.Control as="input"
              value={currentName}
              onChange={(e)=>setCurrentName(e.target.value)}
              sx={{ width: "80%" }}
              label="Nombre de la industria"
              placeholder="Por favor completar el nombre de la industria"
            />
          </Grid>
          <Grid item xs={12} md={12} marginY={"10px"}>
            <Typography align="center">
            Descripción de la industria
            </Typography>
            <Form.Control as="textarea"
              value={currentDescription}
              onChange={(e)=>setCurrentDescription(e.target.value)}
              sx={{ width: "80%" }}
              label="Descripción de la industria"
              placeholder="Por favor completar la descripción de la industria"
            />
          </Grid>
        </Grid>
        <SuccessButton type="submit" disabled={!currentName } > Crear industria</SuccessButton>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Volver
        </Button>
      </Form>
      {notification}
 
    </div>
  );
}