import { Button } from "@mui/material";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../styles/userTable.scss";
import React from "react";

export function KAMTable(props) {
  const navigate = useNavigate();

  const { kams } = props;
  const { page_size, page_index } = props;

  return (
    <div className='user-table'>
      <Table striped bordered hover >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th className="th-lg-percent">Correo Electrónico</th>
            <th>Ver Usuario</th>
          </tr>
        </thead>
        <tbody>
          {kams.map(({ id, user}, index) => {
            const goToUser = ()=>{navigate(`/users/${user.UUid}`);};
            return page_index * page_size <= index && index < (page_index + 1) * page_size &&  (
              <tr key={id}>
                <td>{index+1}</td>
                <td>{user.auth.first_name} {user.auth.last_name}</td>
                <td>{user.auth.email}</td>

                <td><Button fullWidth onClick={goToUser}>Ver</Button></td>
              </tr>
            );})}
        </tbody>
      </Table>
    </div>
  );
}