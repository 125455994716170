import React, { useState, useEffect } from "react"
import fetchData from "../../utils";
import { JobTable } from "../../components/lawyer/jobsTable";
import { LoadingView } from "../LoadingView";


export function LawyerCompletedJobs() {

  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData("/project/jobs_lawyer/completed", "GET")
    .then((response) => {
      setJobs(response.data);
      setIsLoading(false);
    });
  }, []);
  return (
    <div className="user-show">
      <h1>Trabajos completados</h1>
      <p>En esta sección se muestran los trabajos que ya han sido completados.</p>
      {isLoading ? <LoadingView/> : <JobTable jobs={jobs} />}
    </div>
  );
}